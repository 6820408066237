import styled from 'styled-components';
import { variant, typography, space, borders, compose } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

import { $color, $layout } from '@core/theme/system';
import colors from '@core/theme/colors';

const StyledAnchor = styled.a`
  all: unset;
  cursor: pointer;

  ${compose($layout, borders, space, typography, $color)}

  ${variant({
    variants: {
      selected: {
        color: colors.textClay,
      },
      disabled: {
        cursor: 'not-allowed',
        color: colors.dustyGrey,
      },
    },
  })}
`;

StyledAnchor.propTypes = createPropTypes(
  borders.propNames,
  space.propNames,
  typography.propNames,
  $layout.propNames,
  $color.propNames
);

export default StyledAnchor;
