import React, { useState, useCallback } from 'react';

import { string, node, bool, arrayOf, shape, func } from 'prop-types';
import { colord } from 'colord';
import styled from 'styled-components';
import { compose, flexbox, position } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

import colors from '@core/theme/colors';
import { $layout } from '@core/theme/system';

import { ClayButton, ClayText, Column, Flex, Row } from '../../basic/';

import { ReactComponent as X } from './x.svg';

// TODO: $fontWeight="heavy" should be replaced by 700 when we receive the fonts.
const ClayError = ({ message, closable = false, actions = [] }) => {
  const [visible, setVisible] = useState(true);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  const actionable = !!actions.length;

  return (
    visible && (
      <Flex flex={1} justifyContent="center" alignItems="center">
        <StyledColumn
          $gap="small"
          py="smallMedium"
          pl="smallMedium"
          pr="large"
          borderRadius="large"
          bg={colord(colors.charm).alpha(0.1).toHex()}
          position="relative"
        >
          {closable && <CloseIcon onClick={hide} />}
          <StyledColumn $gap="extraSmall">
            <StyledText $fontWeight="heavy">Something went wrong!</StyledText>
            <StyledText $fontWeight="medium">
              {message}
              {actionable && (
                <>
                  <br />
                  Try the following actions:
                </>
              )}
            </StyledText>
          </StyledColumn>
          {actionable && (
            <Row $gap="small">
              {actions.map(({ label, handler }) => (
                <StyledButton
                  key={label}
                  onClick={handler}
                  py="tiny"
                  px="smallMedium"
                  borderRadius="extraLarge"
                  borderWidth="small"
                  borderStyle="solid"
                  borderColor="charm"
                  alignItems="center"
                  justifyContent="center"
                  $width="fit-content"
                >
                  <StyledText $fontWeight="heavy">{label}</StyledText>
                </StyledButton>
              ))}
            </Row>
          )}
        </StyledColumn>
      </Flex>
    )
  );
};

export default ClayError;

ClayError.propTypes = {
  message: string.isRequired,
  closable: bool,
  actions: arrayOf(
    shape({ label: string.isRequired, handler: func.isRequired }).isRequired
  ),
};

const StyledColumn = styled(Column)`
  ${position}
`;

StyledColumn.propTypes = {
  ...Column.propTypes,
  ...createPropTypes(position.propNames),
};

const StyledText = ({ children, ...props }) => (
  <ClayText {...props} $color="charm" $fontSize="caption" $fontFamily="inter">
    {children}
  </ClayText>
);

StyledText.propTypes = {
  children: node.isRequired,
};

const StyledButton = styled(ClayButton)`
  ${compose(position, $layout, flexbox)}
  display: flex;
`;

StyledButton.propTypes = {
  ...ClayButton.propTypes,
  ...createPropTypes(position.propNames, $layout.propNames, flexbox.propNames),
};

const CloseIcon = (props) => (
  <StyledButton
    {...props}
    position="absolute"
    top="smallMedium"
    right="smallMedium"
    $size="smallMedium"
    alignItems="center"
    justifyContent="center"
  >
    <X />
  </StyledButton>
);
