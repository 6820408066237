import React, { useCallback, useMemo } from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';

import { Row } from '@core/components/basic';
import { CloseNavigationIcon } from '@core/components/navigation-icons';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';

import FlowAssetForm, { FIELDS } from '@features/flows/components/form/asset';
import {
  useGetFlowDetailsQuery,
  usePatchAssetInFlowMutation,
} from '@features/flows/service';

const EditAssetDrawer = ({ onClose, flowId, assetId }) => {
  const {
    data: flow = { assets: [] },
    isLoading,
    error,
  } = useGetFlowDetailsQuery({ flowId });

  const asset = useMemo(
    () => flow.assets.find(({ asset: { id } }) => id === assetId),
    [flow.assets, assetId]
  );

  const [patch] = usePatchAssetInFlowMutation();

  const onSubmit = useCallback(
    ({ priority, channel, guard, start, interval, end }) =>
      patch({
        flowId,
        assetId,
        priority,
        channel,
        guardId: guard === '' ? null : guard,
        startRuleId: start,
        interval: interval === '' ? null : Number(interval),
        endRuleId: end === '' ? null : end,
      }),
    [patch, flowId, assetId]
  );

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row justifyContent="end" p="small">
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <FlowAssetForm
          header="Update asset"
          disabledFields={[FIELDS.ASSET]}
          data={{
            assetId: asset?.asset.id,
            priority: asset?.priority,
            channel: asset?.channel,
            guardId: asset?.guard?.id,
            startRuleId: asset?.startRule.id,
            interval: asset?.interval,
            endRuleId: asset?.endRule?.id,
          }}
          buttonLabel="Update"
          onSubmit={onSubmit}
          onSuccess={onClose}
        />
      </AsyncStateWrapper>
    </Drawer>
  );
};

export default EditAssetDrawer;

EditAssetDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired,
};
