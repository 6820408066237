import React from 'react';

const headerImage = '/clay.svg';

// TODO: Had errors while transforming this to a styled component, the errors were related to hooks being used outside of the context provider tree.
const Header = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        paddingBottom: 32,
      }}
    >
      <img
        src={headerImage}
        alt="logo"
        height="96"
        style={{ marginBottom: -32 }}
      />
      <h6 style={{ margin: 0 }}>Experts</h6>
    </div>
  );
};

export default Header;
