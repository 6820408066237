import React from 'react';

import { node, bool, func } from 'prop-types';
import styled from 'styled-components';
import { compose, flexbox } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

import { $flexbox } from '@core/theme/system';

import { ClayButton } from '../../basic';

const SideBarButton = ({ children, active = false, ...props }) => (
  <StyledButton
    {...props}
    justifyContent="center"
    alignItems="center"
    $flexBasis="huge"
    $color={active ? 'textClay' : 'tintBlack'}
  >
    {children}
  </StyledButton>
);

export default SideBarButton;

SideBarButton.propTypes = {
  children: node.isRequired,
  active: bool,
  onClick: func,
};

const StyledButton = styled(ClayButton)`
  ${compose(flexbox, $flexbox)}
  ${({ theme }) => `
    display: flex;
    transition: ${theme.transitions.easeIn};

    &:hover {
      transition: ${theme.transitions.easeIn};
      color: ${theme.colors.textClay};
    }
  `}
`;

StyledButton.propTypes = {
  ...ClayButton.propTypes,
  ...createPropTypes(flexbox.propNames, $flexbox.propNames),
};
