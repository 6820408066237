import { useCallback, useMemo } from 'react';

import { createSlice } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const slice = createSlice({
  name: 'page-filters',
  initialState: {},
  reducers: {
    patch(state, { payload: { path, filters } }) {
      state[path] = state[path] ? { ...state[path], ...filters } : filters;
    },
  },
});

export default slice;

export function usePageFilters({ defaults = {} } = {}) {
  const dispatch = useDispatch();
  const { pathname: path } = useLocation();
  const filters = useSelector((state) => state[slice.name][path]);

  const patch = useCallback(
    (filters) => dispatch(slice.actions.patch({ path, filters })),
    [dispatch, path]
  );

  return useMemo(
    () => ({ patch, filters: { ...defaults, ...filters } }),
    [defaults, patch, filters]
  );
}
