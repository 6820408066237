import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import FlowTypes from 'clay-commons/enum/flow-types';

import AsyncStateWrapper from '@core/components/async-state';
import {
  BackdropRow,
  ClayTable,
  HiddenScrollColumn,
  Row,
  ScrollableColumn,
  SortableHeader,
  TableData,
  TableHeaderItem,
  TableRow,
} from '@core/components/basic';
import Pagination from '@core/components/pagination';
import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import {
  AddNavigationIcon,
  ViewNavigationIcon,
  EditNavigationIcon,
  DeleteNavigationIcon,
} from '@core/components/navigation-icons';
import useDrawer from '@core/hooks/use-drawer';
import Search from '@core/components/search';

import { useListFlowsQuery, DEFAULT_LIST_SORT } from '@features/flows/service';
import {
  CreateFlowDrawer,
  EditFlowDrawer,
  AddGuardDrawer,
  ViewFlowDrawer,
  AddAssetDrawer,
  EditAssetDrawer,
} from '@features/flows/components/drawers';
import {
  RemoveAssetModal,
  RemoveFlowModal,
  RemoveGuardModal,
} from '@features/flows/components/modals';

const FlowListPage = ({ type }) => {
  const {
    isLoading,
    error,
    pagination,
    data = [],
  } = useRequestWithUrlPagination({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    request: (params) => useListFlowsQuery({ type, ...params }),
    initialSort: DEFAULT_LIST_SORT,
  });

  const {
    viewing: creating,
    open: openCreate,
    close: closeCreate,
  } = useDrawer();

  const {
    viewing: flowId,
    open: openDetails,
    close: closeDetails,
  } = useDrawer();

  const { viewing: editingId, open: openEdit, close: closeEdit } = useDrawer();

  const {
    viewing: removingId,
    open: openRemove,
    close: closeRemove,
  } = useDrawer();

  const {
    viewing: addingGuard,
    open: openAddGuard,
    close: closeAddGuard,
  } = useDrawer();

  const {
    viewing: removingGuard,
    open: openRemoveGuard,
    close: closeRemoveGuard,
  } = useDrawer();

  const {
    viewing: addingAsset,
    open: openAddAsset,
    close: closeAddAsset,
  } = useDrawer();

  const {
    viewing: editingAsset,
    open: openEditAsset,
    close: closeEditAsset,
  } = useDrawer();

  const {
    viewing: removingAsset,
    open: openRemoveAsset,
    close: closeRemoveAsset,
  } = useDrawer();

  const onRemoveSuccess = useCallback(() => {
    closeDetails();
    closeRemove();
  }, [closeDetails, closeRemove]);

  return (
    <>
      <Wrapper loading={isLoading} error={error?.data?.message}>
        <TopBar type={type} pagination={pagination} openCreating={openCreate} />
        <ScrollableColumn width="100%" p="smallMedium">
          <ClayTable borderRadius="large" $height="fit-content">
            <Header pagination={pagination} />
            <Body
              data={data}
              openDetails={openDetails}
              onEdit={openEdit}
              onRemove={openRemove}
            />
          </ClayTable>
        </ScrollableColumn>
        <Pagination pagination={pagination} withPageSelector />
      </Wrapper>
      {creating && <CreateFlowDrawer onClose={closeCreate} type={type} />}
      {flowId && !removingId && !removingGuard && !removingAsset && (
        <ViewFlowDrawer
          onClose={closeDetails}
          onEdit={openEdit}
          onRemove={openRemove}
          onAddGuard={openAddGuard}
          onRemoveGuard={openRemoveGuard}
          onAddAsset={openAddAsset}
          onEditAsset={openEditAsset}
          onRemoveAsset={openRemoveAsset}
          type={type}
          flowId={flowId}
        />
      )}
      {editingId && <EditFlowDrawer flowId={editingId} onClose={closeEdit} />}
      {removingId && (
        <RemoveFlowModal
          flowId={removingId}
          onClose={closeRemove}
          onSuccess={onRemoveSuccess}
        />
      )}
      {addingGuard && (
        <AddGuardDrawer flowId={flowId} onClose={closeAddGuard} />
      )}
      {removingGuard && (
        <RemoveGuardModal
          flowId={flowId}
          guardId={removingGuard}
          onClose={closeRemoveGuard}
        />
      )}
      {addingAsset && (
        <AddAssetDrawer flowId={flowId} onClose={closeAddAsset} />
      )}
      {editingAsset && (
        <EditAssetDrawer
          flowId={flowId}
          assetId={editingAsset}
          onClose={closeEditAsset}
        />
      )}
      {removingAsset && (
        <RemoveAssetModal
          flowId={flowId}
          assetId={removingAsset}
          onClose={closeRemoveAsset}
        />
      )}
    </>
  );
};

export default FlowListPage;

FlowListPage.propTypes = {
  type: PropTypes.oneOf(Object.values(FlowTypes)).isRequired,
};

const Wrapper = ({ children, loading = false, error }) => (
  <ScrollableColumn
    flex="1 1 auto"
    width="100%"
    alignItems="center"
    p="smallMedium"
  >
    <AsyncStateWrapper isLoading={loading} error={error}>
      <HiddenScrollColumn
        width="100%"
        alignItems="center"
        flex={1}
        overflowY="scroll"
      >
        {children}
      </HiddenScrollColumn>
    </AsyncStateWrapper>
  </ScrollableColumn>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const TopBar = ({ type, pagination, openCreating }) => (
  <BackdropRow
    alignItems="center"
    justifyContent="space-around"
    px="medium"
    width="100%"
    py="smallMedium"
    $gap="medium"
  >
    <AddNavigationIcon title={`Create "${type}" flow`} onClick={openCreating} />
    <Search
      initialValue={pagination.search}
      onSearch={pagination.changeSearch}
    />
  </BackdropRow>
);

TopBar.propTypes = {
  type: FlowListPage.propTypes.type,
  pagination: PropTypes.shape({
    search: PropTypes.string,
    changeSearch: PropTypes.func,
    changeSize: PropTypes.func,
    size: PropTypes.number,
  }).isRequired,
  openCreating: PropTypes.func.isRequired,
};

const Header = ({ pagination }) => (
  <thead>
    <TableRow variant="header" $width="100%" position="sticky">
      <TableHeaderItem>
        <SortableHeader
          field="name"
          label="Name"
          currentSort={pagination.sort}
          onSort={pagination.changeSort}
        />
      </TableHeaderItem>
      <TableHeaderItem>
        <SortableHeader
          field="priority"
          label="Priority"
          currentSort={pagination.sort}
          onSort={pagination.changeSort}
        />
      </TableHeaderItem>
      <TableHeaderItem>Concurrent Assets</TableHeaderItem>
      <TableHeaderItem>Actions</TableHeaderItem>
    </TableRow>
  </thead>
);

Header.propTypes = {
  pagination: PropTypes.shape({
    sort: PropTypes.string,
    changeSort: PropTypes.func,
  }).isRequired,
};

const Body = ({ data, openDetails, onEdit, onRemove }) => (
  <tbody>
    {data.map(({ id, name, concurrentAssetLimit, priority, type }, index) => (
      <TableRow key={id} variant={index % 2 === 0 ? 'dark' : 'light'}>
        <TableData>{name}</TableData>
        <TableData>{priority}</TableData>
        <TableData>{concurrentAssetLimit}</TableData>
        <TableData>
          <Row alignItems="center" $gap="smallMedium">
            <ViewNavigationIcon
              title={`View "${name}" flow`}
              onClick={() => openDetails(id)}
            />
            <EditNavigationIcon title="Edit" onClick={() => onEdit(id)} />
            <DeleteNavigationIcon onClick={() => onRemove(id)} />
          </Row>
        </TableData>
      </TableRow>
    ))}
  </tbody>
);

Body.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      concurrentAssetLimit: PropTypes.number.isRequired,
      priority: PropTypes.number.isRequired,
      type: PropTypes.oneOf(Object.values(FlowTypes)).isRequired,
    })
  ).isRequired,
  openDetails: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
