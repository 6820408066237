import React, { useCallback, useContext, useMemo } from 'react';

import * as Yup from 'yup';
import providers from 'clay-commons/enum/llm-providers';
import { string, arrayOf, func } from 'prop-types';

import { ClayText, Column, SubmitButton } from '@core/components/basic';
import InputField from '@core/components/form/input-field';
import Form from '@core/components/form';
import useForm from '@core/hooks/use-form';
import Error from '@core/components/async-state/error';
import NavigationDropdown from '@core/components/navigation-dropdown';

import {
  usePatchQuestionFollowUpConfigurationMutation,
  usePatchAnswerConfigurationMutation,
  usePatchLessonConfigurationMutation,
  usePatchDaysConfigurationMutation,
  usePatchSectionConfigurationMutation,
  usePatchActivityConfigurationMutation,
  usePatchStandardsConfigurationMutation,
  usePatchLessonFileConfigurationMutation,
  usePatchAddActivityConfigurationMutation,
  usePatchTimedThemesConfigurationMutation,
  usePatchObservationConfigurationMutation,
} from '@features/settings/service';

import { SchoolSettingsContext } from '.';

export default function RootConfiguration() {
  return (
    <NavigationDropdown
      routes={Object.values(routes)}
      defaultRoute={routes.questionFollowUps.path}
    />
  );
}

const routes = {
  questionFollowUps: {
    title: 'Question Follow Up',
    path: '/settings/school/configuration/question-follow-up',
    component: (
      <SchoolConfigurationForm
        field="questionFollowUpConfiguration"
        useMutation={usePatchQuestionFollowUpConfigurationMutation}
        variables={['question', 'format_instructions']}
        conditions={[]}
      />
    ),
  },
  answer: {
    title: 'Answer',
    path: '/settings/school/configuration/answer',
    component: (
      <SchoolConfigurationForm
        field="answerConfiguration"
        useMutation={usePatchAnswerConfigurationMutation}
        variables={['question', 'follow_ups']}
        conditions={['with_follow_ups']}
      />
    ),
  },
  lesson: {
    title: 'Standalone Lesson Plan',
    path: '/settings/school/configuration/lesson',
    component: (
      <SchoolConfigurationForm
        field="lessonConfiguration"
        useMutation={usePatchLessonConfigurationMutation}
        variables={[
          'duration',
          'age_range',
          'theme',
          'focus',
          'state',
          'education_method',
        ]}
        conditions={['with_focus', 'with_state', 'with_education_method']}
      />
    ),
  },
  days: {
    title: 'Schedule Day Theme',
    path: '/settings/school/configuration/days',
    component: (
      <SchoolConfigurationForm
        field="daysConfiguration"
        useMutation={usePatchDaysConfigurationMutation}
        variables={[
          'amount',
          'theme',
          'age_range',
          'education_method',
          'format_instructions',
        ]}
        conditions={['with_education_method']}
      />
    ),
  },
  section: {
    title: 'Schedule Section',
    path: '/settings/school/configuration/section',
    component: (
      <SchoolConfigurationForm
        field="sectionConfiguration"
        useMutation={usePatchSectionConfigurationMutation}
        variables={[
          'week_theme',
          'activity_amount',
          'age_range',
          'name',
          'themes',
          'education_method',
          'format_instructions',
        ]}
        conditions={['with_education_method']}
      />
    ),
  },
  activity: {
    title: 'Schedule Activity Lesson Plan',
    path: '/settings/school/configuration/activity',
    component: (
      <SchoolConfigurationForm
        field="activityConfiguration"
        useMutation={usePatchActivityConfigurationMutation}
        variables={[
          'week_theme',
          'day_theme',
          'duration',
          'age_range',
          'section',
          'theme',
          'big_idea',
          'state',
          'education_method',
        ]}
        conditions={[
          'with_state',
          'with_education_method',
          'is_under_one_year',
        ]}
      />
    ),
  },
  standards: {
    title: 'Lesson Standards',
    path: '/settings/school/configuration/standards',
    component: (
      <SchoolConfigurationForm
        field="standardsConfiguration"
        useMutation={usePatchStandardsConfigurationMutation}
        variables={['standards_json', 'plan', 'format_instructions']}
        conditions={[]}
      />
    ),
  },
  addActivity: {
    title: 'Add Activity',
    path: '/settings/school/configuration/add-activity',
    component: (
      <SchoolConfigurationForm
        field="addActivityConfiguration"
        useMutation={usePatchAddActivityConfigurationMutation}
        variables={[
          'format_instructions',
          'age_range',
          'week_theme',
          'day_theme',
          'section_name',
          'day_activity_themes',
          'education_method',
        ]}
        conditions={['with_education_method']}
      />
    ),
  },
  lessonFile: {
    title: 'Lesson File',
    path: '/settings/school/configuration/lesson-file',
    component: (
      <SchoolConfigurationForm
        field="lessonFileConfiguration"
        useMutation={usePatchLessonFileConfigurationMutation}
        variables={['plan', 'format_instructions']}
        conditions={[]}
      />
    ),
  },
  timedThemes: {
    title: 'Timed Themes',
    path: '/settings/school/configuration/timed-themes',
    component: (
      <SchoolConfigurationForm
        field="timedThemesConfiguration"
        useMutation={usePatchTimedThemesConfigurationMutation}
        variables={[
          'format_instructions',
          'number_of_themes',
          'age_range',
          'this_day',
        ]}
        conditions={[]}
      />
    ),
  },
  observation: {
    title: 'Observation',
    path: '/settings/school/configuration/observation',
    component: (
      <SchoolConfigurationForm
        field="observationConfiguration"
        useMutation={usePatchObservationConfigurationMutation}
        variables={['format_instructions']}
        conditions={[]}
      />
    ),
  },
};

function SchoolConfigurationForm({
  field,
  useMutation,
  variables,
  conditions,
}) {
  const { [field]: data } = useContext(SchoolSettingsContext);
  const [patch, configuration] = useMutation();

  const form = useForm({
    fields: useMemo(
      () => ({
        provider: {
          type: 'select',
          initialValue: data?.provider,
          options: Object.values(providers).map((value) => ({
            label: value,
            value,
          })),
        },
        model: {
          type: 'text',
          initialValue: data?.model,
        },
        temperature: {
          type: 'number',
          initialValue: data?.temperature,
        },
        systemTemplate: {
          type: 'textarea',
          initialValue: data?.systemTemplate,
          rows: 10,
        },
        humanTemplate: {
          type: 'textarea',
          initialValue: data?.humanTemplate,
          rows: 5,
        },
      }),
      [data]
    ),
    schema: Yup.object().shape({
      provider: Yup.mixed().oneOf(Object.values(providers)).required(),
      model: Yup.string().required(),
      temperature: Yup.number().required().min(0).max(1),
      systemTemplate: Yup.string().required(),
      humanTemplate: Yup.string().required(),
    }),
    submit: useCallback(
      (body) => patch({ id: data.id, body }),
      [data.id, patch]
    ),
  });

  const disabled = !data || configuration.isLoading;

  return (
    <Column width="100%" $gap="small" px="medium">
      {!!variables.length && (
        <ClayText>{`Variables: ${variables.join(', ')}`}</ClayText>
      )}
      {!!conditions.length && (
        <ClayText>{`Conditions: ${conditions.join(', ')}`}</ClayText>
      )}
      {form.errors.formSubmissionError && (
        <Error message={form.errors.formSubmissionError} />
      )}
      <Form onSubmit={form.handleSubmit} width="100%">
        {Object.keys(form.inputs).map((key) => (
          <InputField
            key={key}
            label={form.labels[key]}
            input={form.inputs[key]}
            error={form.errors[key]}
            p="small"
          />
        ))}
        <SubmitButton
          type="submit"
          disabled={form.submitDisabled || disabled}
          variant={form.submitDisabled || disabled ? 'disabled' : 'enabled'}
        >
          Update
        </SubmitButton>
      </Form>
    </Column>
  );
}

SchoolConfigurationForm.propTypes = {
  field: string.isRequired,
  useMutation: func.isRequired,
  variables: arrayOf(string).isRequired,
  conditions: arrayOf(string).isRequired,
};
