import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'styled-system';

import NavigationIcon from '@core/components/navigation-icons/navigation-icon';
import { ReactComponent as Icon } from '@core/components/navigation-icons/view/icon.svg';
import { $fill } from '@core/theme/system';

const ViewIcon = styled(Icon)`
  ${compose($fill)}
`;

const ViewNavigationIcon = ({ title = 'View', onClick, color, disabled }) => (
  <NavigationIcon
    title={title}
    onClick={onClick}
    color={color}
    disabled={disabled}
  >
    <ViewIcon $fill={color} />
  </NavigationIcon>
);

export default ViewNavigationIcon;

ViewNavigationIcon.propTypes = {
  title: PropTypes.string,
  onClick: NavigationIcon.propTypes.onClick,
  color: NavigationIcon.propTypes.color,
  disabled: NavigationIcon.propTypes.disabled,
};
