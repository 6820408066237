import React from 'react';

import { string, bool } from 'prop-types';
import styled from 'styled-components';
import { compose, border } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

import { $layout } from '@core/theme/system';

import defaultImage from './default-image.svg';

const ClayAvatar = ({ src = defaultImage, loading, ...props }) => (
  <StyledImage
    src={loading ? defaultImage : src}
    $size="large"
    borderRadius="large"
    $fit="cover"
    {...props}
  />
);

export default ClayAvatar;

ClayAvatar.propTypes = {
  src: string,
  loading: bool,
  $size: string,
};

const StyledImage = styled.img`
  ${compose($layout, border)}
`;

StyledImage.propTypes = createPropTypes($layout.propNames, border.propNames);
