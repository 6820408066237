import React, { useCallback } from 'react';

import Sorting from 'clay-commons/enum/sorting';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import { createPropTypes } from '@styled-system/prop-types';
import {
  typography,
  border,
  compose,
  flexbox,
  color,
  variant,
  space,
  layout,
  position,
} from 'styled-system';

import { $layout } from '@core/theme/system';
import colors from '@core/theme/colors';
import radii from '@core/theme/radii';
import spacing from '@core/theme/space';
import borderWidths from '@core/theme/border-widths';
import { Sort } from '@core/components/icons';
import fontWeights from '@core/theme/typography/font-weights';

import { ClayButton, Row } from '.';

export const tableBorderStyle = `${borderWidths.small} solid ${colors.grey}`;

export const ClayTable = styled.table`
  ${compose(border, $layout, typography, space, layout)}

  border-spacing: 0;
`;

ClayTable.propTypes = createPropTypes(
  border.propNames,
  $layout.propNames,
  typography.propNames,
  space.propNames,
  layout.propNames
);

ClayTable.defaultProps = {
  border: tableBorderStyle,
  $width: '100%',
  borderRadius: radii.large,
  backgroundColor: colors.guyabano,
};

export const TableRow = styled.tr`
  ${compose(flexbox, color, $layout, border, space, position)}

  ${variant({
    variants: {
      dark: {
        backgroundColor: colors.darkerBackgroundGrey,
        border: tableBorderStyle,
        boxShadow: `0 0 0 1px ${colors.grey}`,
      },
      light: {
        backgroundColor: colors.white,
        border: tableBorderStyle,
        boxShadow: `0 0 0 1px ${colors.grey}`,
      },
      header: {
        backgroundColor: colors.guyabano,
      },
    },
  })}

  &:last-of-type > td:first-of-type {
    border-bottom-left-radius: ${radii.large};
  }

  &:last-of-type > td:last-of-type {
    border-bottom-right-radius: ${radii.large};
  }
`;

TableRow.propTypes = createPropTypes(
  flexbox.propNames,
  color.propNames,
  $layout.propNames,
  border.propNames,
  space.propNames
);

TableRow.defaultProps = {
  justifyContent: 'space-between',
  variant: 'dark',
  borderRadius: radii.large,
};

export const TableData = styled.td`
  ${compose(border, space, $layout, layout, flexbox, typography)}
`;

TableData.propTypes = createPropTypes(
  border.propNames,
  space.propNames,
  $layout.propNames,
  typography.propNames
);

TableData.defaultProps = {
  px: spacing.medium,
  py: spacing.small,
  $minHeight: spacing.large,
  alignContent: 'center',
};

export const TableHeaderItem = styled.th`
  ${compose(space, typography, border, layout)}

  &:first-of-type {
    border-top-left-radius: ${radii.large};
  }

  &:last-of-type {
    border-top-right-radius: ${radii.large};
  }
`;

TableHeaderItem.propTypes = createPropTypes(
  border.propNames,
  typography.propNames,
  space.propNames,
  layout.propNames
);

TableHeaderItem.defaultProps = {
  textAlign: 'left',
  px: spacing.medium,
  height: spacing.large,
  fontFamily: 'Inter',
  borderTop: 'none',
  fontWeight: fontWeights.light,
};

export const SortableHeader = ({ field, label, currentSort = '', onSort }) => {
  const [currentField, order] = currentSort.split('.') ?? [];

  const isCurrentlySortedField = currentField === field;

  const toggleSort = useCallback(() => {
    onSort(
      field,
      isCurrentlySortedField
        ? order === Sorting.ASCENDENT
          ? Sorting.DESCENDENT
          : Sorting.ASCENDENT
        : Sorting.DESCENDENT
    );
  }, [field, isCurrentlySortedField, onSort, order]);

  return (
    <Row alignItems="center">
      <span>{label}</span>
      <ClayButton
        $color={isCurrentlySortedField ? 'textClay' : 'tintBlack'}
        onClick={toggleSort}
        pl="small"
      >
        <StyledSort
          $inverted={isCurrentlySortedField && order === Sorting.ASCENDENT}
        />
      </ClayButton>
    </Row>
  );
};

const StyledSort = styled(Sort)`
  ${({ $inverted }) => $inverted && 'transform: rotate(180deg);'}
`;

SortableHeader.propTypes = {
  field: string.isRequired,
  label: string.isRequired,
  currentSort: string,
  onSort: func.isRequired,
};
