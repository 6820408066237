import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import AsyncStateWrapper from '@core/components/async-state';
import {
  BackdropRow,
  Box,
  ClayButton,
  ClayTable,
  ClayText,
  Column,
  Flex,
  HiddenScrollColumn,
  ScrollableColumn,
  SortableHeader,
  StyledLink,
  TableData,
  TableHeaderItem,
  TableRow,
  Row,
} from '@core/components/basic';
import Pagination from '@core/components/pagination';
import PaginationSizeSelector, {
  sizeOptions,
} from '@core/components/pagination/pagination-size-selector';
import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import { useDeleteRuleMutation, useListRulesQuery } from '@core/api/admin';
import Modal from '@core/components/modal';
import Search from '@core/components/search';

import { CREATE_RULE_ROUTE, EDIT_RULES_ROUTE } from '@features/admin-rules';

const ListRules = () => {
  const history = useHistory();

  const {
    data: rules,
    pagination,
    ...rulesListAsyncState
  } = useRequestWithUrlPagination({
    request: useListRulesQuery,
  });

  const [deleteRule, { isError: deletionError }] = useDeleteRuleMutation();
  const [ruleToDelete, setRuleToDelete] = useState();

  const onDelete = useCallback((name, ruleId) => {
    setRuleToDelete({
      id: ruleId,
      name,
    });
  }, []);

  const closeConfirmation = useCallback(() => {
    setRuleToDelete(undefined);
  }, []);

  const confirmDeletion = useCallback(async () => {
    await deleteRule({ ruleId: ruleToDelete?.id });
    closeConfirmation();
  }, [closeConfirmation, deleteRule, ruleToDelete?.id]);

  return (
    <ScrollableColumn flex="1 1 auto" $width="100%" alignItems="center">
      <Box $width="100%" p="smallMedium">
        <AsyncStateWrapper
          isLoading={rulesListAsyncState.isLoading}
          error={rulesListAsyncState.error?.data?.message}
        >
          <HiddenScrollColumn
            width="100%"
            alignItems="center"
            flex={1}
            overflowY="scroll"
          >
            <BackdropRow
              justifyContent="space-around"
              px="medium"
              width="100%"
              py="smallMedium"
            >
              <Flex flex={1}>
                <StyledLink to={CREATE_RULE_ROUTE.path}>
                  <ClayButton
                    bg="textClay"
                    p="small"
                    $color="white"
                    borderRadius="extraLarge"
                  >
                    Create
                  </ClayButton>
                </StyledLink>
              </Flex>
              <Search
                initialValue={pagination.search}
                onSearch={pagination.changeSearch}
              />
              <Flex flex={1} justifyContent="flex-end">
                <PaginationSizeSelector
                  onChange={pagination.changeSize}
                  size={pagination.size}
                  options={sizeOptions}
                />
              </Flex>
            </BackdropRow>

            <ScrollableColumn $width="100%" p="smallMedium">
              <ClayTable borderRadius="large" $height="fit-content">
                <thead>
                  <TableRow variant="header" $width="100%" position="sticky">
                    <TableHeaderItem>Id</TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="name"
                        label="Name"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>Actions</TableHeaderItem>
                  </TableRow>
                </thead>

                <tbody>
                  {rules?.map(({ id, name }, index) => (
                    <TableRow
                      key={id}
                      variant={index % 2 === 0 ? 'dark' : 'light'}
                    >
                      <TableData>{id}</TableData>
                      <TableData>{name}</TableData>
                      <TableData py="small">
                        <Row $gap="small">
                          <ClayButton
                            variant="linkButton"
                            type="button"
                            onClick={() => onDelete(name, id)}
                          >
                            <p>Delete</p>
                          </ClayButton>
                          <ClayButton
                            variant="linkButton"
                            type="button"
                            onClick={() =>
                              history.push(
                                EDIT_RULES_ROUTE.path.replace(':id', id)
                              )
                            }
                          >
                            <p>View</p>
                          </ClayButton>
                        </Row>
                      </TableData>
                    </TableRow>
                  ))}
                </tbody>
              </ClayTable>
            </ScrollableColumn>
            <Pagination pagination={pagination} />
            {deletionError && (
              <ClayText
                $fontFamily="inter"
                $fontSize="body"
                $fontWeight="heavy"
                $color="charm"
              >
                Could not delete rule
              </ClayText>
            )}
          </HiddenScrollColumn>
        </AsyncStateWrapper>
      </Box>
      {ruleToDelete && (
        <Modal onConfirm={confirmDeletion} onCancel={closeConfirmation}>
          <Column alignItems="center" $gap="small">
            <ClayText $fontSize="bodyLarge" textAlign="center">
              Are you sure you want to delete the rule{' '}
              <strong>{ruleToDelete?.name}</strong>?
            </ClayText>
          </Column>
        </Modal>
      )}
    </ScrollableColumn>
  );
};

export default ListRules;
