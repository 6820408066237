import React from 'react';

import * as Yup from 'yup';
import { func, string, shape } from 'prop-types';

import { Column, Heading2, SubmitButton } from '@core/components/basic';
import useForm from '@core/hooks/use-form';
import Form from '@core/components/form';
import InputField from '@core/components/form/input-field';
import Error from '@core/components/async-state/error';

const requiredErrorMessage = 'The field is required';

const schema = Yup.object().shape({
  name: Yup.string().required(requiredErrorMessage),
  description: Yup.string().required(requiredErrorMessage),
  internalName: Yup.string().max(255, 'Cannot exceed 255 characters.'),
});

const CategoryForm = ({ onSubmit, onSuccess, title, category }) => {
  const { labels, inputs, errors, submitDisabled, handleSubmit } = useForm({
    fields: {
      name: {
        type: 'text',
        initialValue: category?.name,
      },
      description: {
        type: 'textarea',
        initialValue: category?.description,
      },
      internalName: { type: 'text', initialValue: category?.internalName },
      isDefault: {
        type: 'checkbox',
        initialValue: !!category?.isDefault,
      },
    },
    schema,
    submit: ({ internalName = '', ...fields }) =>
      onSubmit({
        ...fields,
        internalName: internalName.length === 0 ? null : internalName,
      }),
    onSuccess,
  });

  return (
    <Column flex={1}>
      <Heading2>{title}</Heading2>
      <Form flex={1} onSubmit={handleSubmit}>
        {Object.keys(inputs).map((key) => (
          <InputField
            key={key}
            label={labels[key]}
            input={inputs[key]}
            error={errors[key]}
            pr="medium"
          />
        ))}
        <SubmitButton
          type="submit"
          disabled={submitDisabled}
          variant={submitDisabled ? 'disabled' : 'enabled'}
        >
          Submit
        </SubmitButton>
        {errors.formSubmissionError && (
          <Error message={errors.formSubmissionError} />
        )}
      </Form>
    </Column>
  );
};

CategoryForm.propTypes = {
  onSubmit: func.isRequired,
  onSuccess: func,
  title: string.isRequired,
  category: shape({
    id: string.isRequired,
    name: string.isRequired,
    description: string.isRequired,
  }),
};

export default CategoryForm;
