import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import NodeTypes from 'clay-commons/enum/node-definitions/types';
import NodeOperations from 'clay-commons/enum/node-definitions/operations';
import TimeUnit from 'clay-commons/enum/node-definitions/time-unit';

import InputField from '@core/components/form/input-field';
import space from '@core/theme/space';

const NodeFormInputs = ({ type, value, onChange, ancestor, index }) => {
  if (ancestor && ancestor.type !== NodeTypes.OPERATOR) {
    throw Error(`Invalid ancestor of type ${ancestor.type}`);
  }

  const inputs = useMemo(
    () =>
      ancestor
        ? operatorMap[ancestor.value](type.value, index)
        : {
            type: {
              type: 'select',
              options: buildOptions(
                buildOjectSubset(NodeTypes, NodeTypes.OPERATOR)
              ),
            },
            value: {
              type: 'select',
              options: buildOptions(
                buildOjectSubset(
                  NodeOperations,
                  NodeOperations.NOT,
                  NodeOperations.OR,
                  NodeOperations.AND,
                  NodeOperations.EQUALS,
                  NodeOperations.GREATER_THAN,
                  NodeOperations.LESSER_THAN,
                  NodeOperations.GREATER_THAN,
                  NodeOperations.LESSER_THAN,
                  NodeOperations.GREATER_OR_EQUALS,
                  NodeOperations.LESSER_OR_EQUALS,
                  NodeOperations.ADD_TIME,
                  NodeOperations.SUBTRACT_TIME
                )
              ),
            },
          },
    [ancestor, type.value, index]
  );

  const onTypeChange = useCallback(
    (event) => {
      onChange(event);
      // clear value when type changes
      onChange({
        target: {
          name: value.id,
          value: '',
        },
      });
    },
    [onChange, value.id]
  );

  const onValueChange = useCallback(
    (event) => {
      // set all values as string
      onChange({
        target: {
          name: value.id,
          value: event.target.value.toString(),
        },
      });
    },
    [onChange, value.id]
  );

  return (
    <>
      <InputField
        flexGrow={1}
        p="extraTiny"
        input={{
          id: type.id,
          name: type.id,
          value: type.value ?? '',
          onChange: onTypeChange,
          ...inputs.type,
        }}
      />
      {type.value ? (
        <InputField
          flexGrow={1}
          p="extraTiny"
          input={{
            id: value.id,
            name: value.id,
            value: value.value ?? '',
            onChange: onValueChange,
            ...inputs.value,
          }}
        />
      ) : (
        <StyledSkeleton variant="rounded" />
      )}
    </>
  );
};

export default NodeFormInputs;

NodeFormInputs.propTypes = {
  type: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  ancestor: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(NodeTypes)).isRequired,
    value: PropTypes.oneOf(Object.values(NodeOperations)).isRequired,
  }),
  index: PropTypes.number.isRequired,
};

const StyledSkeleton = styled(Skeleton)`
  padding: ${space.extraSmall};
  flex-grow: 1;
`;

function buildOptions(entries = []) {
  return [
    { label: '', value: '' },
    ...Object.entries(entries).map(([key, value]) => ({
      label: key.toUpperCase(),
      value,
    })),
  ];
}

function buildOjectSubset(object = {}, ...subset) {
  return Object.entries(object).reduce(
    (operations, [key, value]) =>
      subset.includes(value) ? { ...operations, [key]: value } : operations,
    {}
  );
}

/**
 * @type {{ [string]: (nodeType?: string, nodeIndex: number) => { type: object, value?: object  }}}
 */
const operatorMap = {
  [NodeOperations.NOT](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.BOOLEAN,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.OR,
            NodeOperations.AND,
            NodeOperations.EQUALS
          ),
        }
      : { type };
  },
  [NodeOperations.OR](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.BOOLEAN,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.NOT,
            NodeOperations.OR,
            NodeOperations.AND,
            NodeOperations.EQUALS,
            NodeOperations.GREATER_THAN,
            NodeOperations.LESSER_THAN,
            NodeOperations.GREATER_OR_EQUALS,
            NodeOperations.LESSER_OR_EQUALS
          ),
        }
      : { type };
  },
  [NodeOperations.AND](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.BOOLEAN,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.NOT,
            NodeOperations.OR,
            NodeOperations.AND,
            NodeOperations.EQUALS,
            NodeOperations.GREATER_THAN,
            NodeOperations.LESSER_THAN,
            NodeOperations.GREATER_OR_EQUALS,
            NodeOperations.LESSER_OR_EQUALS
          ),
        }
      : { type };
  },
  [NodeOperations.EQUALS](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.STRING,
          NodeTypes.NUMBER,
          NodeTypes.DATE,
          NodeTypes.BOOLEAN,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.NOT,
            NodeOperations.OR,
            NodeOperations.AND,
            NodeOperations.EQUALS,
            NodeOperations.GREATER_THAN,
            NodeOperations.LESSER_THAN,
            NodeOperations.GREATER_OR_EQUALS,
            NodeOperations.LESSER_OR_EQUALS,
            NodeOperations.ADD_TIME,
            NodeOperations.SUBTRACT_TIME
          ),
        }
      : { type };
  },
  [NodeOperations.GREATER_THAN](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.NUMBER,
          NodeTypes.DATE,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.ADD_TIME,
            NodeOperations.SUBTRACT_TIME
          ),
        }
      : { type };
  },
  [NodeOperations.LESSER_THAN](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.NUMBER,
          NodeTypes.DATE,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.ADD_TIME,
            NodeOperations.SUBTRACT_TIME
          ),
        }
      : { type };
  },
  [NodeOperations.GREATER_OR_EQUALS](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.NUMBER,
          NodeTypes.DATE,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.ADD_TIME,
            NodeOperations.SUBTRACT_TIME
          ),
        }
      : { type };
  },
  [NodeOperations.LESSER_OR_EQUALS](nodeType) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          NodeTypes.NUMBER,
          NodeTypes.DATE,
          NodeTypes.REFERENCE,
          NodeTypes.OPERATOR
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            NodeOperations.ADD_TIME,
            NodeOperations.SUBTRACT_TIME
          ),
        }
      : { type };
  },
  [NodeOperations.ADD_TIME](nodeType, nodeIndex) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          ...(nodeIndex === 0
            ? [NodeTypes.DATE, NodeTypes.REFERENCE, NodeTypes.OPERATOR]
            : [NodeTypes.OPERATOR])
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            ...(nodeIndex === 0
              ? [NodeOperations.ADD_TIME, NodeOperations.SUBTRACT_TIME]
              : [NodeOperations.TIME])
          ),
        }
      : { type };
  },
  [NodeOperations.SUBTRACT_TIME](nodeType, nodeIndex) {
    const type = {
      type: 'select',
      options: buildOptions(
        buildOjectSubset(
          NodeTypes,
          ...(nodeIndex === 0
            ? [NodeTypes.DATE, NodeTypes.REFERENCE, NodeTypes.OPERATOR]
            : [NodeTypes.OPERATOR])
        )
      ),
    };

    return nodeType
      ? {
          type,
          value: typeMap[nodeType](
            ...(nodeIndex === 0
              ? [NodeOperations.ADD_TIME, NodeOperations.SUBTRACT_TIME]
              : [NodeOperations.TIME])
          ),
        }
      : { type };
  },
  [NodeOperations.TIME](nodeType, nodeIndex) {
    const type = {
      type: 'select',
      options: buildOptions(
        nodeIndex === 0
          ? buildOjectSubset(NodeTypes, NodeTypes.NUMBER, NodeTypes.REFERENCE)
          : buildOjectSubset(NodeTypes, NodeTypes.TIME_UNIT)
      ),
    };

    return nodeType ? { type, value: typeMap[nodeType]() } : { type };
  },
};

const typeMap = {
  [NodeTypes.BOOLEAN]() {
    return {
      type: 'select',
      options: buildOptions({
        true: true,
        false: false,
      }),
    };
  },
  [NodeTypes.REFERENCE]() {
    return {
      type: 'text',
    };
  },
  [NodeTypes.OPERATOR](...operations) {
    return {
      type: 'select',
      options: buildOptions(buildOjectSubset(NodeOperations, ...operations)),
    };
  },
  [NodeTypes.STRING]() {
    return {
      type: 'text',
    };
  },
  [NodeTypes.NUMBER]() {
    return {
      type: 'number',
    };
  },
  [NodeTypes.DATE]() {
    return {
      type: 'date',
    };
  },
  [NodeTypes.TIME_UNIT]() {
    return {
      type: 'select',
      options: buildOptions(TimeUnit),
    };
  },
};
