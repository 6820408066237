import { useMemo, useCallback, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringifyUrl } from 'query-string';

import { DEFAULT_PAGINATION_SIZE } from '@core/api-service';

import usePaginatedRequest from './use-paginated-request';

const useRequestWithUrlPagination = ({ request, initialSort }) => {
  const location = useLocation();
  const history = useHistory();

  const [knownFields, filters, query] = useMemo(() => {
    const query = parse(location.search, {
      parseNumbers: true,
    });

    const { filter, sort, page, size, ...filters } = query;

    return [
      {
        size,
        sort,
        page,
        search: filter,
      },
      filters,
      query,
    ];
  }, [location.search]);

  const { pagination, ...response } = usePaginatedRequest({
    page: knownFields.page ?? 1,
    size: knownFields.size ?? DEFAULT_PAGINATION_SIZE,
    sort: knownFields.sort ?? initialSort,
    search: knownFields.search,
    filters,
    request,
  });

  useEffect(() => {
    if (location.search) {
      return;
    }

    history.replace(
      stringifyUrl(
        {
          url: location.pathname,
          query: {
            page: pagination.page,
            size: pagination.size,
            sort: pagination.sort,
            filter: pagination.search,
          },
        },
        { skipEmptyString: true }
      )
    );
  }, [
    history,
    location.pathname,
    location.search,
    pagination.page,
    pagination.size,
    pagination.sort,
    pagination.search,
  ]);

  const changePage = useCallback(
    (page) =>
      history.push(
        stringifyUrl({
          url: location.pathname,
          query: {
            ...query,
            page,
          },
        })
      ),
    [history, query, location.pathname]
  );

  const changeSize = useCallback(
    (size) => {
      const maxPageWithNewSize = Math.ceil(pagination.totalItems / size);
      history.push(
        stringifyUrl({
          url: location.pathname,
          query: {
            ...query,
            size,
            page:
              query.page > maxPageWithNewSize ? maxPageWithNewSize : query.page,
          },
        })
      );
    },
    [history, location.pathname, query, pagination?.totalItems]
  );

  const changeSort = useCallback(
    (field, order) =>
      history.push(
        stringifyUrl({
          url: location.pathname,
          query: { ...query, page: 1, sort: `${field}.${order}` },
        })
      ),
    [history, location.pathname, query]
  );

  const changeSearch = useCallback(
    (filter) => {
      const { filter: oldFilter, ...newQuery } = query;

      history.push(
        stringifyUrl({
          url: location.pathname,
          query: filter
            ? { ...newQuery, filter, page: 1 }
            : { ...newQuery, page: 1 },
        })
      );
    },
    [history, location.pathname, query]
  );

  const changeFilters = useCallback(
    (category, filter) =>
      history.push(
        stringifyUrl({
          url: location.pathname,
          query: {
            ...query,
            [category]: filter,
          },
        })
      ),
    [history, location.pathname, query]
  );

  useEffect(() => {
    if (response.data?.length === 0 && pagination.currentPage > 1) {
      changePage(pagination.currentPage - 1);
    }
  }, [response.data?.length, pagination.currentPage, changePage]);

  return {
    ...response,
    pagination: {
      ...pagination,
      changePage,
      changeSize,
      changeSort,
      changeSearch,
      changeFilters,
    },
  };
};

export default useRequestWithUrlPagination;
