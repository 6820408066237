import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  BackdropRow,
  ClayButton,
  HiddenScrollColumn,
  Row,
} from '@core/components/basic';
import { useCreateCategoryMutation } from '@core/api/admin';

import { EDIT_CATEGORY_ROUTE } from '@features/admin-rules';
import CategoryForm from '@features/admin-rules/components/category-form';

const CreateCategoryPage = () => {
  const history = useHistory();
  const [createCategory] = useCreateCategoryMutation();

  return (
    <HiddenScrollColumn
      width="100%"
      alignItems="center"
      flex={1}
      overflowY="scroll"
    >
      <BackdropRow
        justifyContent="flex-start"
        px="medium"
        position="sticky"
        top={0}
        $width="100%"
        py="smallMedium"
      >
        <ClayButton
          bg="textClay"
          p="small"
          $color="white"
          borderRadius="extraLarge"
          onClick={history.goBack}
        >
          Back
        </ClayButton>
      </BackdropRow>
      <Row $width="100%" py="large" px="smallMedium" alignContent="center">
        <CategoryForm
          title="Create Category"
          onSubmit={createCategory}
          onSuccess={({ data: { id } }) =>
            history.push(EDIT_CATEGORY_ROUTE.path.replace(':id', id))
          }
        />
      </Row>
    </HiddenScrollColumn>
  );
};

export default CreateCategoryPage;
