import React, { useCallback, useMemo } from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Column, Heading2, Row, SubmitButton } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import { CloseNavigationIcon } from '@core/components/navigation-icons';
import useForm from '@core/hooks/use-form';
import InputField from '@core/components/form/input-field';
import Error from '@core/components/async-state/error';
import Form from '@core/components/form/form';
import { useListRulesQuery } from '@core/api/admin';
import usePaginatedRequest from '@core/hooks/pagination/use-paginated-request';

import {
  useGetFlowDetailsQuery,
  useAddGuardToFlowMutation,
} from '@features/flows/service';

const AddGuardDrawer = ({ onClose, flowId }) => {
  const { data: flow = { guards: [] }, ...flowDetailsAsync } =
    useGetFlowDetailsQuery({ flowId });

  const { data: rules = [], ...rulesListAsync } = usePaginatedRequest({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    request: () => useListRulesQuery({ pagination: false }),
  });

  const [addGuard] = useAddGuardToFlowMutation();

  const onSubmit = useCallback(
    ({ rule }) => addGuard({ flowId, ruleId: rule }),
    [addGuard, flowId]
  );

  const options = useMemo(
    () =>
      rules
        .filter((rule) => !flow.guards.some((guard) => guard.id === rule.id))
        .map(({ id, name }) => ({ label: name, value: id })),
    [flow.guards, rules]
  );

  const form = useForm({
    schema: Yup.object().shape({
      rule: Yup.string().required(),
    }),
    fields: {
      rule: {
        type: 'select',
        options,
        disabled: options.length === 0,
      },
    },
    submit: onSubmit,
    onSuccess: onClose,
  });

  const loading = flowDetailsAsync.isLoading || rulesListAsync.isLoading;

  const error = flowDetailsAsync.error ?? rulesListAsync.error;

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row
        justifyContent="end"
        alignItems="center"
        p="small"
        $gap="smallMedium"
      >
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <AsyncStateWrapper isLoading={loading} error={error?.data?.message}>
        <Column px="smallMedium" $gap="medium">
          <Heading2>Add guard</Heading2>
          <Column $gap="small">
            <Form onSubmit={form.handleSubmit}>
              {Object.keys(form.inputs).map((key) => (
                <InputField
                  key={key}
                  label={form.labels[key]}
                  input={form.inputs[key]}
                  error={form.errors[key]}
                  p="extraSmall"
                />
              ))}
              <SubmitButton
                type="submit"
                disabled={form.submitDisabled}
                variant={form.submitDisabled ? 'disabled' : 'enabled'}
              >
                Add
              </SubmitButton>
            </Form>
            {form.errors.formSubmissionError && (
              <Error message={form.errors.formSubmissionError} />
            )}
          </Column>
        </Column>
      </AsyncStateWrapper>
    </Drawer>
  );
};

export default AddGuardDrawer;

AddGuardDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
};
