import React from 'react';

import { shape, func, bool, string } from 'prop-types';
import { sha1 } from 'object-hash';
import styled from 'styled-components';

import space from '@core/theme/space';
import Search from '@core/components/search';

import AsyncStateWrapper from '../async-state/async-state-wrapper';
import {
  BackdropRow,
  HiddenScrollColumn,
  Flex,
  ClayTable,
  TableRow,
  TableHeaderItem,
  SortableHeader,
  TableData,
  ClayText,
} from '../basic';
import Pagination from '../pagination';

const ComposableList = ({
  columns,
  actionsBuilder,
  withSearch = false,
  useRequest,
}) => {
  const { data = [], pagination, error, isLoading } = useRequest();

  const hasActions = !!actionsBuilder;

  return (
    <AsyncStateWrapper isLoading={isLoading} error={error?.message}>
      <HiddenScrollColumn
        width="100%"
        alignItems="center"
        flex={1}
        overflowY="scroll"
      >
        {withSearch && (
          <BackdropRow
            justifyContent="space-around"
            px="medium"
            position="sticky"
            top={0}
            width="100%"
            py="smallMedium"
          >
            <Search
              initialValue={pagination.search}
              onSearch={pagination.changeSearch}
            />
          </BackdropRow>
        )}
        <Flex width="100%" p="smallMedium">
          <ClayTable>
            <thead>
              <TableRow
                variant="header"
                $width="100%"
                position="sticky"
                height="fit-content"
                top={withSearch ? 70 : 0}
              >
                {Object.entries(columns).map(([key, { header, sortable }]) => (
                  <TableHeaderItem key={header}>
                    {sortable ? (
                      <SortableHeader
                        field={key}
                        label={header}
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    ) : (
                      <ClayText>{header}</ClayText>
                    )}
                  </TableHeaderItem>
                ))}
                {hasActions && <TableHeaderItem>Actions</TableHeaderItem>}
              </TableRow>
            </thead>
            <tbody>
              {data.map((entry) => {
                const rowKey = sha1(entry);
                return (
                  <TableRow key={rowKey} variant="light">
                    {Object.entries(columns).map(
                      ([field, { valueBuilder }]) => {
                        return (
                          <TableData key={`${rowKey}-${field}`}>
                            {valueBuilder?.(entry) ?? entry[field]}
                          </TableData>
                        );
                      }
                    )}
                    {hasActions && (
                      <TableData>
                        <ActionContainer
                          py="extraSmall"
                          pr="extraSmall"
                          flexWrap="wrap"
                          $gap="tiny"
                        >
                          {actionsBuilder(entry)}
                        </ActionContainer>
                      </TableData>
                    )}
                  </TableRow>
                );
              })}
            </tbody>
          </ClayTable>
        </Flex>
      </HiddenScrollColumn>
      <Pagination pagination={pagination} withPageSelector />
    </AsyncStateWrapper>
  );
};

ComposableList.propTypes = {
  columns: shape({
    [string]: shape({
      header: string.isRequired,
      sortable: bool,
      valueBuilder: func,
    }),
  }),
  actionsBuilder: func,
  useRequest: func.isRequired,
  withSearch: bool,
  initialSort: string,
  internalStorage: bool,
};

const ActionContainer = styled(Flex)`
  & > * {
    margin-right: ${space.tiny};
    margin-bottom: ${space.tiny};
  }
`;

export default ComposableList;
