import React from 'react';

import { string } from 'prop-types';

import { Column, Row } from '@core/components/basic';
import { formatShortDate } from '@core/format-date';

import { CapitalizedText } from './text';

const CaseSummary = ({ enrollmentDate }) => {
  return (
    <Column bg="backgroundGrey" p="medium" borderRadius="medium">
      <Row>
        <CapitalizedText
          $fontSize="caption"
          $color="tintBlack"
          $minHeight="smallMedium"
          width={97}
          pb="smallMedium"
        >
          Case Status
        </CapitalizedText>
        <CapitalizedText
          $fontSize="body"
          $color="textBlack"
          $minHeight="smallMedium"
          pl="smallMedium"
          pb="smallMedium"
          $fontWeight="heavy"
        >
          Pending Report
        </CapitalizedText>
      </Row>
      {enrollmentDate && (
        <Row>
          <CapitalizedText
            $fontSize="caption"
            $color="tintBlack"
            $minHeight="smallMedium"
            width={97}
          >
            Enrollment Date
          </CapitalizedText>
          <CapitalizedText
            $fontSize="body"
            $color="textBlack"
            $minHeight="smallMedium"
            pl="smallMedium"
            pb="smallMedium"
          >
            {formatShortDate(enrollmentDate)}
          </CapitalizedText>
        </Row>
      )}
    </Column>
  );
};

CaseSummary.propTypes = {
  enrollmentDate: string,
  lastActive: string,
};

export default CaseSummary;
