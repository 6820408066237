import { stringifyUrl } from 'query-string';

import { paginateResponse } from '@core/api-service';
import { PROFILE_BASE_PATH } from '@core/api/profile';
import { apiService } from '@core/store';
import ResourceTags from '@core/enums/resource-tags';

const AUTH_BASE_PATH = 'auth/v1';

const service = apiService.injectEndpoints({
  endpoints: (builder) => ({
    createExpert: builder.mutation({
      query: (expert) => ({
        url: `${AUTH_BASE_PATH}/register/expert`,
        method: 'POST',
        body: expert,
      }),
      onQueryStarted: async (value, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        try {
          dispatch(
            service.util.updateQueryData('listCareNavigators', {}, (draft) => {
              draft.content?.unshift({
                name: data.name,
                email: data.email,
                userId: data.id,
              });
            })
          );
        } catch {
          // Pessimistic update above, no need to refetch the experts
          // need to do this since we post to auth but fetch from profile, there is a delay
        }
      },
    }),
    listParentDeletions: builder.query({
      query: (query) => ({
        url: stringifyUrl(
          {
            url: `/${PROFILE_BASE_PATH}/parent/deletion`,
            query,
          },
          { skipEmptyString: true }
        ),
      }),
      providesTags: [ResourceTags.PARENT],
      transformResponse: (response, meta, arg) =>
        arg ? paginateResponse(response, meta, arg) : response,
    }),

    deleteParent: builder.mutation({
      query: ({ userId }) => ({
        url: `${PROFILE_BASE_PATH}/parent/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ResourceTags.PARENT],
    }),

    listTeachers: builder.query({
      providesTags: [ResourceTags.TEACHER],
      query(params) {
        return {
          url: stringifyUrl(
            {
              url: '/school/v1/teacher',
              query: params,
            },
            {
              skipEmptyString: true,
            }
          ),
        };
      },
      transformResponse(response, meta, argument) {
        return argument ? paginateResponse(response, meta, argument) : response;
      },
    }),

    patchTeacher: builder.mutation({
      query({ id, premium }) {
        return {
          method: 'PATCH',
          url: `/school/v1/admin/teacher/${id}`,
          body: {
            premium,
          },
        };
      },
      invalidatesTags: [ResourceTags.TEACHER],
    }),
  }),
});

export const {
  useCreateExpertMutation,
  useListCareNavigatorsQuery,
  useDeleteNavigatorMutation,
  usePatchNavigatorMutation,
  useListParentDeletionsQuery,
  useDeleteParentMutation,
  useListTeachersQuery,
  usePatchTeacherMutation,
} = service;
