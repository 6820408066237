import React, { useCallback } from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';

import { Row } from '@core/components/basic';
import { CloseNavigationIcon } from '@core/components/navigation-icons';

import { useRegisterAssetOfTypeMutation } from '@features/assets/service';
import AssetForm, { FIELDS } from '@features/assets/components/form';

const RegisterAssetDrawer = ({ onClose, type }) => {
  const [register] = useRegisterAssetOfTypeMutation();

  const onSubmit = useCallback(
    ({ asset, name }) =>
      register({
        type,
        externalId: asset,
        name: name.length === 0 ? undefined : name,
      }),
    [register, type]
  );

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row justifyContent="end" p="small">
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <AssetForm
        type={type}
        header={`Register "${type}" asset`}
        requiredFields={[FIELDS.ASSET]}
        buttonLabel="Register"
        onSubmit={onSubmit}
        onSuccess={onClose}
      />
    </Drawer>
  );
};

export default RegisterAssetDrawer;

RegisterAssetDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: AssetForm.propTypes.type,
};
