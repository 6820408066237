import React, { useCallback, useMemo } from 'react';

import Drawer from 'react-modern-drawer';
import { number, bool, func, string } from 'prop-types';
import { duration, utc } from 'moment';

import { useListScheduledChildTasksQuery } from '@core/api/schedule';
import ComposableList from '@core/components/composable-list/composable-list';
import { ClayText, Column } from '@core/components/basic';
import useRequestWithStatePagination from '@core/hooks/pagination/use-request-with-state-pagination';

const DEFAULT_LIST_SORT = 'createdAt.desc';

const TasksTableDrawer = ({ childId, open, onClose, childName }) => {
  const useRequest = useCallback(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useRequestWithStatePagination({
        request: (query) =>
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useListScheduledChildTasksQuery({ ...query, childId }),
      }),
    [childId]
  );

  const columns = useMemo(
    () => ({
      taskId: {
        header: 'Id',
        sortable: true,
      },
      createdAt: {
        header: 'Created At',
        sortable: true,
        valueBuilder: (entry) => utc(entry.createdAt).format('YYYY-MM-DD'),
      },
      lastExecutedAt: {
        header: 'Last Executed At',
        sortable: true,
      },
      startAt: {
        header: 'First Execution Date',
        sortable: true,
      },
      stopAt: {
        header: 'Stop Date',
        sortable: true,
      },
      assetId: {
        header: 'Asset to Assign',
        sortable: true,
      },
      assetType: {
        header: 'Type of asset',
        sortable: true,
      },
      interval: {
        header: 'Period (D)',
        valueBuilder: (entry) =>
          entry.interval
            ? duration(entry.interval, 'milliseconds').asDays()
            : null,
      },
    }),
    []
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      enableOverlay
      direction="right"
      size="75vw"
    >
      <Column px="small" overflowX="scroll" pt="large">
        <ClayText>
          Tasks for: <strong>{childName}</strong>
        </ClayText>
        <ComposableList
          initialSort={DEFAULT_LIST_SORT}
          useRequest={useRequest}
          columns={columns}
        />
      </Column>
    </Drawer>
  );
};

TasksTableDrawer.propTypes = {
  childId: number.isRequired,
  childName: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
};

export default TasksTableDrawer;
