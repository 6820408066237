import { useMemo } from 'react';

import { useAuthUser } from '@frontegg/react';

/**
 * Verify whether the authenticated user has one of the provided roles.
 *
 * @param {Array<String>} roles - the role names.
 * @returns {boolean} the verification result.
 */
export default function useAuthorization(roles = []) {
  const user = useAuthUser();

  return useMemo(
    () => user.roles.some(({ name }) => roles.some((role) => role === name)),
    [user, roles]
  );
}
