import React, { useCallback } from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';
import FlowTypes from 'clay-commons/enum/flow-types';

import { Row } from '@core/components/basic';
import { CloseNavigationIcon } from '@core/components/navigation-icons';

import FlowForm from '@features/flows/components/form/flow';
import { useCreateFlowMutation } from '@features/flows/service';

const CreateFlowDrawer = ({ onClose, type }) => {
  const [create] = useCreateFlowMutation();

  const onSubmit = useCallback(
    ({ priority, concurrentAssetLimit, name, description }) =>
      create({ type, priority, concurrentAssetLimit, name, description }),
    [create, type]
  );

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row justifyContent="end" p="small">
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <FlowForm
        header={`Create "${type}" flow`}
        buttonLabel="Create"
        onSubmit={onSubmit}
        onSuccess={onClose}
      />
    </Drawer>
  );
};

export default CreateFlowDrawer;

CreateFlowDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(FlowTypes)).isRequired,
};
