import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Modal from '@core/components/modal/modal';
import { ClayText, Column } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import { useGetRuleQuery } from '@core/api/admin';

import {
  useGetFlowDetailsQuery,
  useRemoveGuardFromFlowMutation,
} from '@features/flows/service';

const RemoveGuardModal = ({ onClose, flowId, guardId }) => {
  const { data: flow = {}, ...flowDetailsAsync } = useGetFlowDetailsQuery({
    flowId,
  });

  const { data: rule = {}, ...ruleDetailsAsync } = useGetRuleQuery({
    id: guardId,
  });

  const [remove, removeFlowAsync] = useRemoveGuardFromFlowMutation();

  const onConfirm = useCallback(async () => {
    await remove({ flowId, ruleId: guardId });
    await onClose();
  }, [remove, flowId, guardId, onClose]);

  const isLoading =
    flowDetailsAsync.isLoading ||
    ruleDetailsAsync.isLoading ||
    removeFlowAsync.isLoading;

  const error =
    flowDetailsAsync.error ?? ruleDetailsAsync.error ?? removeFlowAsync.error;

  return (
    <Modal onConfirm={onConfirm} onCancel={onClose} loading={isLoading}>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <Column alignItems="center" $gap="small">
          <ClayText $fontSize="bodyLarge" textAlign="center">
            Are you <strong>sure</strong> you want to remove &quot;
            <strong>{rule.name}</strong>&quot; guard from &quot;
            <strong>{flow.name ?? flowId}</strong>&quot; flow?
            <br />
          </ClayText>
        </Column>
      </AsyncStateWrapper>
    </Modal>
  );
};

export default RemoveGuardModal;

RemoveGuardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
  guardId: PropTypes.string.isRequired,
};
