import React from 'react';

import PropTypes from 'prop-types';
import AssetTypes from 'clay-commons/enum/asset-types';
import * as Yup from 'yup';

import {
  Heading2,
  HiddenScrollColumn,
  SubmitButton,
} from '@core/components/basic';
import InputField from '@core/components/form/input-field';
import Form from '@core/components/form';
import useForm from '@core/hooks/use-form';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import Error from '@core/components/async-state/error';

import { useListTitlesOfTypeQuery } from '@features/assets/service';

export const FIELDS = {
  NAME: 'name',
  ASSET: 'asset',
};

const AssetForm = ({
  type,
  header,
  data = {},
  buttonLabel,
  onSubmit,
  onSuccess,
  disabledFields = [],
  requiredFields = [],
}) => {
  const {
    data: assets = [],
    isLoading,
    error,
  } = useListTitlesOfTypeQuery({ type });

  const form = useForm({
    schema: Yup.object().shape({
      [FIELDS.NAME]: requiredFields.includes(FIELDS.NAME)
        ? Yup.string().required()
        : Yup.string(),
      [FIELDS.ASSET]: requiredFields.includes(FIELDS.ASSET)
        ? Yup.number().required()
        : Yup.number(),
    }),
    fields: {
      [FIELDS.NAME]: {
        type: 'text',
        initialValue: data.name,
        disabled: disabledFields.includes(FIELDS.NAME),
      },
      [FIELDS.ASSET]: {
        type: 'select',
        options: assets.map(({ id, title }) => ({ label: title, value: id })),
        initialValue: data.externalId,
        disabled: assets.length === 0 || disabledFields.includes(FIELDS.ASSET),
      },
    },
    submit: onSubmit,
    onSuccess,
  });

  return (
    <HiddenScrollColumn
      flex={1}
      justifyContent="center"
      p="smallMedium"
      $gap="smallMedium"
    >
      <Heading2>{header}</Heading2>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <Form onSubmit={form.handleSubmit}>
          {Object.keys(form.inputs).map((key) => (
            <InputField
              key={key}
              label={form.labels[key]}
              input={form.inputs[key]}
              error={form.errors[key]}
              p="extraSmall"
            />
          ))}
          {onSubmit && (
            <SubmitButton
              type="submit"
              disabled={form.submitDisabled}
              variant={form.submitDisabled ? 'disabled' : 'enabled'}
            >
              {buttonLabel}
            </SubmitButton>
          )}
        </Form>
        {form.errors.formSubmissionError && (
          <Error message={form.errors.formSubmissionError} />
        )}
      </AsyncStateWrapper>
    </HiddenScrollColumn>
  );
};

export default AssetForm;

AssetForm.propTypes = {
  type: PropTypes.oneOf(Object.values(AssetTypes)).isRequired,
  header: PropTypes.string.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    externalId: PropTypes.number,
  }),
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  disabledFields: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FIELDS))),
  requiredFields: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FIELDS))),
};
