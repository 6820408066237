import { stringifyUrl } from 'query-string';
import AssetTypes from 'clay-commons/enum/asset-types';

import { paginateResponse } from '@core/api-service';
import { apiService, cmsService } from '@core/store';
import ResourceTags from '@core/enums/resource-tags';

const { ASSET, FLOW } = ResourceTags;

export const DEFAULT_LIST_SORT = 'name.asc';

const ASSET_LIST_TAG = { type: ASSET, id: 'LIST' };

const apiRequests = apiService.injectEndpoints({
  endpoints(builder) {
    return {
      listAssetsOfType: builder.query({
        query({ type, ...query } = {}) {
          return {
            url: stringifyUrl(
              {
                url: `/admin/v1/asset/${type}`,
                query,
              },
              { skipEmptyString: true }
            ),
          };
        },
        providesTags({ content = [] } = {}) {
          return [
            ...content.map(({ externalId }) => ({ type: ASSET, externalId })),
            ASSET_LIST_TAG,
          ];
        },
        transformResponse: paginateResponse,
      }),
      registerAssetOfType: builder.mutation({
        query({ type, externalId, name }) {
          return {
            method: 'POST',
            url: `/admin/v1/asset/${type}`,
            body: {
              externalId: Number(externalId),
              name,
            },
          };
        },
        invalidatesTags: [ASSET_LIST_TAG],
      }),
      getAssetDetailsOfType: builder.query({
        query({ type, externalId }) {
          return {
            url: `/admin/v1/asset/${type}/${externalId}`,
          };
        },
        providesTags({ flows = [] } = {}, error, { externalId }) {
          return [
            { type: ASSET, externalId },
            ...flows.map(({ id }) => ({ type: FLOW, id })),
          ];
        },
      }),
      patchAssetOfType: builder.mutation({
        query({ type, externalId, name }) {
          return {
            method: 'PATCH',
            url: `/admin/v1/asset/${type}/${externalId}`,
            body: { name },
          };
        },
        invalidatesTags(result, error, { externalId }) {
          return [{ type: ASSET, externalId }];
        },
      }),
      removeAssetOfType: builder.mutation({
        query({ type, externalId }) {
          return {
            method: 'DELETE',
            url: `/admin/v1/asset/${type}/${externalId}`,
          };
        },
        invalidatesTags(result, error, { externalId }) {
          return [{ type: ASSET, externalId }, ASSET_LIST_TAG];
        },
      }),
      listAssets: builder.query({
        query(query = {}) {
          return {
            url: stringifyUrl(
              {
                url: `/admin/v1/asset`,
                query,
              },
              { skipEmptyString: true }
            ),
          };
        },
        providesTags({ content = [] } = {}) {
          return [
            ...content.map(({ externalId }) => ({ type: ASSET, externalId })),
            ASSET_LIST_TAG,
          ];
        },
        transformResponse: paginateResponse,
      }),
    };
  },
});

export const {
  useListAssetsOfTypeQuery,
  useRegisterAssetOfTypeMutation,
  useGetAssetDetailsOfTypeQuery,
  usePatchAssetOfTypeMutation,
  useRemoveAssetOfTypeMutation,
  useListAssetsQuery,
} = apiRequests;

const cmsRequests = cmsService.injectEndpoints({
  endpoints(builder) {
    return {
      listTitlesOfType: builder.query({
        query({ type }) {
          return type !== AssetTypes.RESOURCE
            ? {
                url: `/api/form/title/${type}`,
              }
            : {
                url: '/api/resources?publicationState=live&filters[alwaysAvailable][$eq]=false',
              };
        },
        transformResponse(response, meta, { type }) {
          return type !== AssetTypes.RESOURCE
            ? response
            : response.data.map(({ id, attributes: { name } }) => ({
                id,
                title: name,
              }));
        },
      }),
    };
  },
});

export const { useListTitlesOfTypeQuery } = cmsRequests;
