import React, { useCallback } from 'react';

import AssetTypes from 'clay-commons/enum/asset-types';
import PropTypes from 'prop-types';

import AsyncStateWrapper from '@core/components/async-state';
import {
  BackdropRow,
  ClayTable,
  HiddenScrollColumn,
  Row,
  ScrollableColumn,
  SortableHeader,
  TableData,
  TableHeaderItem,
  TableRow,
} from '@core/components/basic';
import Pagination from '@core/components/pagination';
import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import colors from '@core/theme/colors';
import {
  AddNavigationIcon,
  DeleteNavigationIcon,
  EditNavigationIcon,
  ViewNavigationIcon,
} from '@core/components/navigation-icons';
import useDrawer from '@core/hooks/use-drawer';
import Search from '@core/components/search';

import { OpenIcon } from '@features/assets/components/icons';
import {
  EditAssetDrawer,
  RegisterAssetDrawer,
  ViewAssetDrawer,
} from '@features/assets/components/drawers';
import {
  useListAssetsOfTypeQuery,
  DEFAULT_LIST_SORT,
} from '@features/assets/service';
import { RemoveAssetModal } from '@features/assets/components/modals';

const ListAssetPage = ({ type }) => {
  const {
    isLoading,
    error,
    pagination,
    data = [],
  } = useRequestWithUrlPagination({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    request: (params) => useListAssetsOfTypeQuery({ type, ...params }),
    initialSort: DEFAULT_LIST_SORT,
  });

  const {
    viewing: creating,
    open: openCreate,
    close: closeCreate,
  } = useDrawer();

  const {
    viewing: externalId,
    open: openDetails,
    close: closeDetails,
  } = useDrawer();

  const { viewing: editingId, open: openEdit, close: closeEdit } = useDrawer();

  const {
    viewing: removingId,
    open: openRemove,
    close: closeRemove,
  } = useDrawer();

  const onRemoveSuccess = useCallback(() => {
    closeDetails();
    closeRemove();
  }, [closeDetails, closeRemove]);

  return (
    <>
      <Wrapper loading={isLoading} error={error?.data?.message}>
        <Controls type={type} pagination={pagination} onCreate={openCreate} />
        <ScrollableColumn width="100%" p="smallMedium">
          <ClayTable borderRadius="large" $height="fit-content">
            <Header pagination={pagination} />
            <Body
              type={type}
              data={data}
              onView={openDetails}
              onEdit={openEdit}
              onRemove={openRemove}
            />
          </ClayTable>
        </ScrollableColumn>
        <Pagination pagination={pagination} withPageSelector />
      </Wrapper>
      {creating && <RegisterAssetDrawer onClose={closeCreate} type={type} />}
      {externalId && !removingId && (
        <ViewAssetDrawer
          externalId={externalId}
          onClose={closeDetails}
          type={type}
          onEdit={openEdit}
          onRemove={openRemove}
        />
      )}
      {editingId && (
        <EditAssetDrawer
          onClose={closeEdit}
          type={type}
          externalId={editingId}
        />
      )}
      {removingId && (
        <RemoveAssetModal
          externalId={removingId}
          onClose={closeRemove}
          onSuccess={onRemoveSuccess}
          type={type}
        />
      )}
    </>
  );
};

export default ListAssetPage;

ListAssetPage.propTypes = {
  type: PropTypes.oneOf(Object.values(AssetTypes)).isRequired,
};

const Wrapper = ({ children, loading = false, error }) => (
  <ScrollableColumn
    flex="1 1 auto"
    width="100%"
    alignItems="center"
    p="smallMedium"
  >
    <AsyncStateWrapper isLoading={loading} error={error}>
      <HiddenScrollColumn
        width="100%"
        alignItems="center"
        flex={1}
        overflowY="scroll"
      >
        {children}
      </HiddenScrollColumn>
    </AsyncStateWrapper>
  </ScrollableColumn>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const Controls = ({ type, pagination, onCreate }) => (
  <BackdropRow
    alignItems="center"
    justifyContent="space-around"
    px="medium"
    width="100%"
    py="smallMedium"
    $gap="medium"
  >
    <AddNavigationIcon title={`Create "${type}" asset`} onClick={onCreate} />
    <Search
      initialValue={pagination.search}
      onSearch={pagination.changeSearch}
    />
  </BackdropRow>
);

Controls.propTypes = {
  type: ListAssetPage.propTypes.type,
  pagination: PropTypes.shape({
    search: PropTypes.string,
    changeSearch: PropTypes.func,
    changeSize: PropTypes.func,
    size: PropTypes.number,
  }).isRequired,
  onCreate: PropTypes.func.isRequired,
};

const Header = ({ pagination }) => (
  <thead>
    <TableRow variant="header" $width="100%" position="sticky">
      <TableHeaderItem>
        <SortableHeader
          field="name"
          label="Name"
          currentSort={pagination.sort}
          onSort={pagination.changeSort}
        />
      </TableHeaderItem>
      <TableHeaderItem>
        <SortableHeader
          field="externalId"
          label="External ID"
          currentSort={pagination.sort}
          onSort={pagination.changeSort}
        />
      </TableHeaderItem>
      <TableHeaderItem>Actions</TableHeaderItem>
    </TableRow>
  </thead>
);

Header.propTypes = {
  pagination: PropTypes.shape({
    sort: PropTypes.string,
    changeSort: PropTypes.func,
  }).isRequired,
};

const Body = ({ type, data, onView, onEdit, onRemove }) => {
  const buildCmsUrl = useCallback(
    (id) =>
      `${process.env.REACT_APP_CMS_HOST}/admin/content-manager/collectionType/api::${assetTypeCmsMap[type]}/${id}`,
    [type]
  );

  return (
    <tbody>
      {data.map(({ id, name, externalId, flowCount }, index) => (
        <TableRow key={id} variant={index % 2 === 0 ? 'dark' : 'light'}>
          <TableData>{name}</TableData>
          <TableData>{externalId}</TableData>
          <TableData>
            <Row alignItems="center" $gap="smallMedium">
              <a
                title="Open in new tab"
                href={buildCmsUrl(externalId)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenIcon fill={colors.textClay} />
              </a>
              <ViewNavigationIcon onClick={() => onView(externalId)} />
              <EditNavigationIcon
                title="Rename"
                onClick={() => onEdit(externalId)}
              />
              <DeleteNavigationIcon
                title="Unregister"
                disabled={flowCount !== 0}
                onClick={() => onRemove(externalId)}
              />
            </Row>
          </TableData>
        </TableRow>
      ))}
    </tbody>
  );
};

Body.propTypes = {
  type: ListAssetPage.propTypes.type,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      externalId: PropTypes.number.isRequired,
    })
  ).isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const assetTypeCmsMap = {
  [AssetTypes.SCREENER]: 'form.form',
  [AssetTypes.CHILD_RESCREENING]: 'form.form',
  [AssetTypes.RESOURCE]: 'resource.resource',
  [AssetTypes.SURVEY]: 'form.form',
};
