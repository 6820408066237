import React from 'react';

import { string, bool } from 'prop-types';
import { Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import AsyncStateWrapper from '@core/components/async-state';
import {
  BackdropRow,
  Box,
  ClayTable,
  Flex,
  HiddenScrollColumn,
  ScrollableColumn,
  SortableHeader,
  TableData,
  TableHeaderItem,
  TableRow,
} from '@core/components/basic';
import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import Pagination from '@core/components/pagination';
import ExportCsvButton from '@core/components/csv/export-button';
import PaginationSizeSelector, {
  sizeOptions,
} from '@core/components/pagination/pagination-size-selector';
import { formatFullDate } from '@core/format-date';
import Search from '@core/components/search';

import {
  useListTeachersQuery,
  usePatchTeacherMutation,
} from '@features/admin-users/service';

const csvHeaders = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Role',
    key: 'role',
  },
  {
    label: 'School',
    key: 'school',
  },
  {
    label: 'First Use',
    key: 'firstUse',
  },
];

export default function TeacherListPage() {
  const {
    data = [],
    pagination,
    isLoading,
    error,
  } = useRequestWithUrlPagination({
    request: useListTeachersQuery,
  });

  return (
    <ScrollableColumn flex="1 1 auto" $width="100%" alignItems="center">
      <Box $width="100%" p="smallMedium">
        <h2>Teachers</h2>
        <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
          <HiddenScrollColumn
            width="100%"
            alignItems="center"
            flex={1}
            overflowY="scroll"
          >
            <BackdropRow
              justifyContent="space-around"
              px="medium"
              width="100%"
              py="smallMedium"
            >
              <Search
                initialValue={pagination.search}
                onSearch={pagination.changeSearch}
              />
              <Flex flex={1} justifyContent="flex-end" $gap="small">
                {!isLoading && !error && (
                  <ExportCsvButton
                    file="teachers"
                    data={data}
                    headers={csvHeaders}
                  />
                )}
                <PaginationSizeSelector
                  onChange={pagination.changeSize}
                  size={pagination.size}
                  options={sizeOptions}
                />
              </Flex>
            </BackdropRow>
            <ScrollableColumn $width="100%" p="smallMedium">
              <ClayTable borderRadius="large" $height="fit-content">
                <thead>
                  <TableRow variant="header" $width="100%" position="sticky">
                    <TableHeaderItem>
                      <SortableHeader
                        field="name"
                        label="Name"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="email"
                        label="Email"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="role"
                        label="Role"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="school"
                        label="School"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>First Use</TableHeaderItem>
                    <TableHeaderItem>Premium</TableHeaderItem>
                  </TableRow>
                </thead>
                <tbody>
                  {data.map((teacher, index) => (
                    <TableRow
                      key={teacher.email}
                      variant={index % 2 === 0 ? 'dark' : 'light'}
                    >
                      <TableData>{teacher.name}</TableData>
                      <TableData>{teacher.email}</TableData>
                      <TableData>{teacher.role}</TableData>
                      <TableData>{teacher.school}</TableData>
                      <TableData>{formatFullDate(teacher.firstUse)}</TableData>
                      <TableData py="small">
                        <PremiumToggle
                          id={teacher.id}
                          premium={teacher.premium}
                        />
                      </TableData>
                    </TableRow>
                  ))}
                </tbody>
              </ClayTable>
            </ScrollableColumn>
            <Pagination pagination={pagination} />
          </HiddenScrollColumn>
        </AsyncStateWrapper>
      </Box>
    </ScrollableColumn>
  );
}

function PremiumToggle({ id, premium }) {
  const [patch, { isError, isLoading, reset }] = usePatchTeacherMutation();

  if (isLoading || isError) {
    return (
      <LoadingButton
        loading={isLoading}
        color={isError ? 'error' : 'info'}
        onClick={isError ? reset : null}
      >
        {isError && 'Error! Click to reset'}
      </LoadingButton>
    );
  }

  return (
    <Switch
      onClick={() => patch({ id, premium: !premium })}
      checked={premium}
      label={premium ? 'Yes' : 'No'}
    />
  );
}

PremiumToggle.propTypes = {
  id: string.isRequired,
  premium: bool.isRequired,
};
