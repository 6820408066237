import moment from 'moment';

function formatDate(date) {
  const dateMoment = moment(date);

  return dateMoment.format(
    `${dateMoment.isSame(new Date(), 'week') ? 'dddd,' : 'MMM DD,'} HH:mm A`
  );
}

export function formatShortDate(date) {
  return moment(date).format(`MMMM Do, YYYY`);
}

export function formatFullDate(date) {
  return moment(date).format(`MMMM Do, YYYY - HH:mm A`);
}

export function messageFormatDate(date) {
  const dateMoment = moment(date);

  return dateMoment.format(`dddd - MMMM Do, YYYY - h:mm a`);
}

export function age(date) {
  const dateMoment = moment(date);

  const totalMonths = moment(Date.now()).diff(dateMoment, 'M');

  return {
    years: Math.floor(totalMonths / 12),
    months: Math.floor(totalMonths % 12),
  };
}

export default formatDate;
