import { css } from 'styled-components';

export const hideScrollbar = css`
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
`;

export const ellipsisTextOverflow = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
