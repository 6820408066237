const radii = {
  small: '2px',
  mediumSmall: '8px',
  medium: '10px',
  large: '16px',
  extraLarge: '20px',
  huge: '30px',
  circular: '50%',
};

export default radii;
