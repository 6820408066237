import React from 'react';

import UserRoles from 'clay-commons/enum/user-roles';

import NavigationTabs from '@core/components/navigation-tabs';

import AdminPageFrame from '@features/admin/components/page-frame';

import ListAnswers from './pages/answer/list';
import ListAssistantLessons from './pages/lesson/list';

export const LIST_ANSWER_ROUTE = {
  title: 'Answers',
  path: '/assistant/answer',
  exact: true,
  component: <ListAnswers />,
  roles: [UserRoles.ADMIN],
};

const LIST_LESSON_ROUTE = {
  title: 'Lessons',
  path: '/assistant/lesson',
  exact: true,
  component: <ListAssistantLessons />,
  roles: [UserRoles.ADMIN],
};

const routes = [LIST_ANSWER_ROUTE, LIST_LESSON_ROUTE];

const RootPage = () => (
  <AdminPageFrame title="Teacher Assistant">
    <NavigationTabs routes={routes} defaultRoute={LIST_ANSWER_ROUTE.path} />
  </AdminPageFrame>
);

export default RootPage;
