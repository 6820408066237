import styled from 'styled-components';
import { flex, typography, space, borders, compose } from 'styled-system';
import { Link } from 'react-router-dom';
import { createPropTypes } from '@styled-system/prop-types';

import { $color, $layout } from '@core/theme/system';

export const StyledLink = styled(Link)`
  all: unset;
  cursor: pointer;

  ${compose(flex, space, typography, $layout, $color)};
`;

StyledLink.propTypes = createPropTypes(
  borders.propNames,
  space.propNames,
  typography.propNames,
  $layout.propNames,
  $color.propNames
);
