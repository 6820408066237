import { arrayOf, string, node } from 'prop-types';

import useAuthorization from '@features/authentication/use-authorization';

const AuthorizedContent = ({ requiredRoles, children }) =>
  useAuthorization(requiredRoles) ? children : null;

AuthorizedContent.propTypes = {
  requiredRoles: arrayOf(string).isRequired,
  children: node.isRequired,
};

export default AuthorizedContent;
