import { useCallback, useMemo, useState, useRef, useEffect } from 'react';

import { useFormik } from 'formik';

const useForm = ({ fields, onSuccess, submit, schema }) => {
  // TODO: Find infinite loop cause when adding fields

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const [formSubmissionError, setFormSubmissionError] = useState(null);

  const initialValues = useMemo(() => {
    const fieldNames = Object.keys(fields);

    return {
      ...fieldNames.reduce(
        (acc, value) => ({
          ...acc,
          [value]: fields[value]?.initialValue ?? '',
        }),
        {}
      ),
    };
  }, [fields]);

  const {
    values,
    isValid,
    errors,
    handleSubmit,
    isSubmitting,
    touched,
    handleChange,
    setSubmitting,
    setTouched,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const result = await submit(values);

      if (result?.error) {
        setSubmitting(false);
        setFormSubmissionError(result?.error.data.error);
        return;
      }

      if (mounted.current) {
        setSubmitting(false);
        onSuccess?.(result) ?? resetForm();
      }
    },
    validateOnMount: true,
    validationSchema: schema,
  });

  const onChange = useCallback(
    (event) => {
      setTouched({ ...touched, [event.target.name]: true });
      fields[event.target.name]?.onChange?.(event);
      handleChange(event);
    },
    [fields, handleChange, setTouched, touched]
  );

  const inputs = useMemo(
    () =>
      values &&
      Object.keys(values).reduce(
        (acc, valueName) => ({
          ...acc,
          [valueName]: {
            ...fields[valueName],
            name: valueName,
            id: valueName,
            type: fields[valueName]?.type ?? 'text',
            onChange,
            value: values[valueName],
            disabled: fields[valueName].disabled ?? false,
            options: [
              { label: '', value: '' },
              ...(fields[valueName]?.options ?? []),
            ],
          },
        }),
        {}
      ),
    [fields, onChange, values]
  );

  const labels = useMemo(
    () =>
      Object.keys(values).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            htmlFor: key,
            value: key,
          },
        }),
        {}
      ),
    [values]
  );

  const parsedErrors = useMemo(() => {
    return Object.keys(touched).reduce(
      (acc, touchedKey) => {
        if (!touched[touchedKey]) {
          return acc;
        }
        return {
          ...acc,
          [touchedKey]: errors[touchedKey],
        };
      },
      { formSubmissionError }
    );
  }, [errors, touched, formSubmissionError]);

  return {
    inputs,
    labels,
    errors: parsedErrors,
    handleSubmit,
    submitDisabled: !isValid || isSubmitting,
    resetForm,
  };
};

export default useForm;
