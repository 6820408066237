import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as Persist from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { store as authStore } from '../features/authentication/store';

import createApiService from './api-service';
import createCmsService from './cms-service';
import pageFiltersSlice from './state/page-filters';

export const apiService = createApiService({
  reducerPath: 'apiService',
  endpoints: () => ({}),
});

export const cmsService = createCmsService({
  reducerPath: 'cmsService',
  endpoints: () => ({}),
});

export const store = configureStore({
  reducer: Persist.persistReducer(
    {
      key: 'root',
      version: 1,
      storage,
      // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      blacklist: [apiService.reducerPath, cmsService.reducerPath],
    },
    combineReducers({
      [apiService.reducerPath]: apiService.reducer,
      [authStore.name]: authStore.reducer,
      [pageFiltersSlice.name]: pageFiltersSlice.reducer,
      [cmsService.reducerPath]: cmsService.reducer,
    })
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      serializableCheck: {
        ignoredActions: [
          Persist.FLUSH,
          Persist.REHYDRATE,
          Persist.PAUSE,
          Persist.PERSIST,
          Persist.PURGE,
          Persist.REGISTER,
        ],
      },
    }).concat([apiService.middleware, cmsService.middleware]),
});

export const persistor = Persist.persistStore(store);
