import React from 'react';

import styled from 'styled-components';
import {
  variant,
  compose,
  flex,
  space,
  color,
  typography,
  layout,
} from 'styled-system';
import { arrayOf, bool, node, string, shape } from 'prop-types';
import { Route, Switch, Redirect, Link, useLocation } from 'react-router-dom';

import { Row } from '@core/components/basic';
import colors from '@core/theme/colors';
import spacing from '@core/theme/space';
import fontSize from '@core/theme/typography/font-sizes';

const NavigationTabs = ({ routes, defaultRoute }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Row pb={spacing.smallMedium} width="100%" justifyContent="center">
        {routes.map(
          ({ path, title, skipTopNavigation }) =>
            !skipTopNavigation &&
            title && (
              <StyledLink
                to={path}
                variant={pathname.endsWith(path) ? 'selected' : null}
                px={spacing.smallMedium}
                bg={colors.backgroundGrey}
                key={`route-link-${title}-to-${path}`}
                fontSize={fontSize.heading}
                textAlign="center"
                flex={1}
              >
                {title}
              </StyledLink>
            )
        )}
      </Row>
      <Switch>
        {routes.map(({ exact, path, component }) => (
          <Route
            path={path}
            key={`route-${exact ? `exact-${path}` : path}`}
            component={() => component}
            exact={exact}
          />
        ))}
        <Redirect path="*" to={defaultRoute ?? routes[0].path} />
      </Switch>
    </>
  );
};

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop, defaultFn) =>
    !['variant', ...space.propNames, ...typography.propNames].includes(prop) &&
    defaultFn(prop),
})`
  all: unset;
  cursor: pointer;

  ${compose(flex, space, color, typography, layout)};

  ${variant({
    variants: {
      selected: {
        color: colors.textClay,
        backgroundColor: colors.darkerBackgroundGrey,
      },
    },
  })}
`;

NavigationTabs.propTypes = {
  routes: arrayOf(
    shape({
      title: string,
      path: string.isRequired,
      component: node.isRequired,
      exact: bool,
      skipTopNavigation: bool,
    })
  ).isRequired,
  defaultRoute: string,
};

export default NavigationTabs;
