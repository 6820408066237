import React, { useCallback, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
  BackdropRow,
  ClayButton,
  Column,
  Heading2,
  HiddenScrollColumn,
  Row,
} from '@core/components/basic';
import {
  useAddCategoryRuleMutation,
  useDeleteCategoryRuleMutation,
  useEditCategoryMutation,
  useGetCategoryQuery,
  useListRulesQuery,
} from '@core/api/admin';

import { LIST_CATEGORIES_ROUTE } from '@features/admin-rules';
import CategoryForm from '@features/admin-rules/components/category-form';
import CategoryRuleForm from '@features/admin-rules/components/category-rule-form';

const EditCategoryPage = () => {
  const { id } = useParams();
  const history = useHistory();

  const { data } = useListRulesQuery();
  const rules = data?.content ?? [];

  const { data: category, refetch } = useGetCategoryQuery(id);
  const [editCategory] = useEditCategoryMutation();
  const [addCategoryRule] = useAddCategoryRuleMutation();
  const [deleteCategoryRule] = useDeleteCategoryRuleMutation();

  const [ruleSubmissionError, setRuleSubmissionError] = useState();

  const onAddRule = useCallback(
    async (ruleId, type) => {
      const { error } = await addCategoryRule({
        categoryId: category.id,
        ruleId,
        type,
      });

      setRuleSubmissionError(error?.data?.message || undefined);

      if (!error) {
        await refetch();
      }
    },
    [addCategoryRule, category, refetch]
  );

  const onRemoveRule = useCallback(
    async (ruleId, type) => {
      const { error } = await deleteCategoryRule({
        categoryId: category.id,
        ruleId,
        type,
      });
      setRuleSubmissionError(error?.data?.message || undefined);

      if (!error) {
        await refetch();
      }
    },
    [deleteCategoryRule, category, refetch]
  );

  return (
    <HiddenScrollColumn
      width="100%"
      alignItems="center"
      flex={1}
      overflowY="scroll"
    >
      <BackdropRow
        justifyContent="flex-start"
        px="medium"
        position="sticky"
        top={0}
        $width="100%"
        py="smallMedium"
      >
        <ClayButton
          bg="textClay"
          p="small"
          $color="white"
          borderRadius="extraLarge"
          onClick={history.goBack}
        >
          Back
        </ClayButton>
      </BackdropRow>
      <Row $width="100%" py="large" px="smallMedium" alignContent="center">
        <Column flex={1 / 2}>
          {category && (
            <CategoryForm
              title="Edit Category"
              category={category}
              onSubmit={(values) =>
                editCategory({
                  category: { id: category.id, ...values },
                })
              }
              onSuccess={() => history.push(LIST_CATEGORIES_ROUTE.path)}
            />
          )}
        </Column>
        <Column flex={1 / 2}>
          {rules && category && (
            <>
              <Heading2>Add rules</Heading2>

              <CategoryRuleForm
                category={category}
                rules={rules}
                onAddRule={onAddRule}
                onRemoveRule={onRemoveRule}
                error={ruleSubmissionError}
              />
            </>
          )}
        </Column>
      </Row>
    </HiddenScrollColumn>
  );
};

export default EditCategoryPage;
