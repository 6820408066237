import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
  BackdropRow,
  ClayButton,
  HiddenScrollColumn,
  Row,
} from '@core/components/basic';
import { useCreateRuleMutation } from '@core/api/admin';

import { LIST_RULES_ROUTE } from '@features/admin-rules';
import RuleForm from '@features/admin-rules/components/rule-form';

const CreateRulePage = () => {
  const history = useHistory();

  const [createRule] = useCreateRuleMutation();

  const onSuccess = useCallback(async () => {
    history.push(LIST_RULES_ROUTE.path);
  }, [history]);

  return (
    <HiddenScrollColumn
      width="100%"
      alignItems="center"
      flex={1}
      overflowY="scroll"
    >
      <BackdropRow
        justifyContent="flex-start"
        px="medium"
        position="sticky"
        top={0}
        $width="100%"
        py="smallMedium"
      >
        <ClayButton
          bg="textClay"
          p="small"
          $color="white"
          borderRadius="extraLarge"
          onClick={history.goBack}
        >
          Back
        </ClayButton>
      </BackdropRow>
      <Row $width="100%" py="large" px="smallMedium" alignContent="center">
        <RuleForm onSubmit={createRule} onSuccess={onSuccess} />
      </Row>
    </HiddenScrollColumn>
  );
};

export default CreateRulePage;
