import { useCallback, useState } from 'react';

const useDrawer = () => {
  const [value, setValue] = useState(null);

  const open = useCallback((value) => setValue(value ?? true), []);
  const close = useCallback(() => setValue(null), []);

  return { viewing: value, open, close };
};

export default useDrawer;
