/**
 * @typedef {{
 *   ZERO: string,
 *   HALF: string,
 *   ONE: string,
 *   TWO: string,
 *   THREE: string,
 *   FOUR: string,
 *   FIVE: string
 * }} AgeRange
 *
 * @returns {AgeRange}
 */
module.exports = {
    ZERO: '0 to 0.5',
    HALF: '0.5 to 1',
    ONE: '1 to 2',
    TWO: '2 to 3',
    THREE: '3 to 4',
    FOUR: '4 to 5',
    FIVE: '5 to 6'
};
