import React, { useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ClayText, Column, Heading2, Row } from '@core/components/basic';
import { useEditRuleMutation, useGetRuleQuery } from '@core/api/admin';
import Loading from '@core/components/async-state/loading';
import { BackgroundButton } from '@core/components/composite/buttons';

import RuleForm from '@features/admin-rules/components/rule-form';
import { LIST_RULES_ROUTE } from '@features/admin-rules';

const RuleDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const { data, isLoading } = useGetRuleQuery({ id, query: { preview: true } });

  const [updateRule] = useEditRuleMutation();

  const onSubmit = useCallback(
    (rule) => updateRule({ id, rule }),
    [id, updateRule]
  );

  const onSuccess = useCallback(async () => {
    history.push(LIST_RULES_ROUTE.path);
  }, [history]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Column width="100%" px="medium" flex="1">
      <BackButton text="Back" onClick={history.goBack} />
      <Column alignItems="center">
        <Heading2 marginBottom="medium">Computed Expression:</Heading2>
        <ClayText $color="textClay" marginBottom="medium">
          {data?.expression}
        </ClayText>
      </Column>
      <Row flex="1">
        <RuleForm rule={data} onSubmit={onSubmit} onSuccess={onSuccess} />
      </Row>
    </Column>
  );
};

const BackButton = styled(BackgroundButton)`
  width: fit-content;
`;

export default RuleDetailsPage;
