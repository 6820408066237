import UserRoles from 'clay-commons/enum/user-roles';

import RootSettingsPage from './pages/root';

export const root = {
  path: '/settings',
  component: RootSettingsPage,
  roles: [UserRoles.ADMIN],
};

const routes = [root];

export default routes;
