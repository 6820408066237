const ResourceTags = {
  NOTE: 'note',
  PROFILE_PICTURE: 'profile-picture',
  PROFILE: 'profile',
  SCREENER: 'screener',
  SURVEY: 'survey',
  CASE: 'case',
  CASE_SUMMARY: 'case-summary',
  CARE_NAVIGATOR: 'care-navigator',
  REPORT: 'report',
  RESOURCE: 'resource',
  RULE: 'rule',
  CATEGORY: 'category',
  PARENT: 'parent',
  ASSET: 'asset',
  FLOW: 'flow',
  CHILD_TASKS: 'child-tasks',
  TEACHER: 'teacher',
  SCHOOL_SETTINGS: 'school-settings',
  CASE_ANSWERS: 'case-answers',
  TIMED_THEMES: 'timed-themes',
};

export default ResourceTags;
