import UserRoles from 'clay-commons/enum/user-roles';

import AdminPage from './pages/admin-page';
import buildPath from './build-path';

const routes = [
  {
    path: '/admin',
    component: AdminPage,
    roles: [UserRoles.ADMIN],
  },
];

export const subPages = {
  caseList: () => buildPath(),
};

export default routes;
