import React from 'react';

import { bool, node, string, arrayOf, shape, func } from 'prop-types';

import ClayLoading from './loading';
import ClayError from './error';

const AsyncStateWrapper = ({
  children,
  isLoading = false,
  error,
  actions = [],
}) => {
  // rendering loader should takes precedence to prevent error being rendered while retrying action.
  if (isLoading) {
    return <ClayLoading />;
  }

  return error ? <ClayError message={error} actions={actions} /> : children;
};

export default AsyncStateWrapper;

AsyncStateWrapper.propTypes = {
  children: node.isRequired,
  isLoading: bool,
  error: string,
  actions: arrayOf(
    shape({ label: string.isRequired, handler: func.isRequired })
  ),
};
