import { rest } from 'msw';
import { nanoid } from '@reduxjs/toolkit';

let token;
let user;
export const handlers = [
  rest.post('*/auth/v1/login', (req, res, ctx) => {
    const { email } = req.body;
    user = { email };
    token = nanoid();

    return res(ctx.delay(1000), ctx.json({ data: { token } }), ctx.status(201));
  }),

  rest.get('*/auth/v1/me', (req, res, ctx) => {
    const headers = req.headers.all();
    if (!token || headers.authorization !== `Bearer ${token}`) {
      return res(ctx.json({ message: 'Unauthorized' }), ctx.status(401));
    }

    return res(ctx.delay(1000), ctx.json({ data: user }), ctx.status(200));
  }),
];
