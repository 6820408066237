import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'styled-system';

import NavigationIcon from '@core/components/navigation-icons/navigation-icon';
import { Pencil } from '@core/components/icons';
import { $fill } from '@core/theme/system';

const PencilIcon = styled(Pencil)`
  ${compose($fill)}
`;

const EditNavigationIcon = ({ title = 'Edit', onClick, color, disabled }) => (
  <NavigationIcon title={title} onClick={onClick} disabled={disabled}>
    <PencilIcon $fill={color} />
  </NavigationIcon>
);

export default EditNavigationIcon;

EditNavigationIcon.propTypes = {
  title: PropTypes.string,
  onClick: NavigationIcon.propTypes.onClick,
  color: NavigationIcon.propTypes.color,
  disabled: NavigationIcon.propTypes.disabled,
};
