import { stringifyUrl } from 'query-string';

import ResourceTags from '@core/enums/resource-tags';
import { apiService } from '@core/store';
import { paginateResponse } from '@core/api-service';

const ADMIN_BASE_PATH = 'admin/v1';

const service = apiService.injectEndpoints({
  endpoints: (builder) => ({
    createRule: builder.mutation({
      query: (rule) => ({
        url: `/${ADMIN_BASE_PATH}/rule`,
        method: 'POST',
        body: rule,
      }),
      invalidatesTags: [{ type: ResourceTags.RULE, id: 'LIST' }],
    }),
    deleteRule: builder.mutation({
      query: ({ ruleId }) => ({
        url: `/${ADMIN_BASE_PATH}/rule/${ruleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: ResourceTags.RULE, id: 'LIST' }],
    }),
    listRules: builder.query({
      query: (query) => ({
        url: stringifyUrl(
          { url: `/${ADMIN_BASE_PATH}/rule`, query },
          { skipEmptyString: true }
        ),
      }),
      providesTags: ({ content: result } = {}) =>
        result
          ? [
              ...result?.map(({ id }) => ({ type: ResourceTags.RULE, id })),
              { type: ResourceTags.RULE, id: 'LIST' },
            ]
          : [{ type: ResourceTags.RULE, id: 'LIST' }],
      transformResponse: paginateResponse,
    }),
    getRule: builder.query({
      query: ({ id, query }) => ({
        url: stringifyUrl({ url: `/${ADMIN_BASE_PATH}/rule/${id}`, query }),
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: ResourceTags.RULE, id: arg.id },
      ],
    }),
    editRule: builder.mutation({
      query: ({ id, rule }) => ({
        url: `/${ADMIN_BASE_PATH}/rule/${id}`,
        method: 'PUT',
        body: rule,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: ResourceTags.RULE, id: arg.id },
        { type: ResourceTags.RULE, id: 'LIST' },
      ],
    }),
    createCategory: builder.mutation({
      query: (category) => ({
        url: `/${ADMIN_BASE_PATH}/category`,
        method: 'POST',
        body: category,
      }),
      invalidatesTags: [ResourceTags.CATEGORY],
    }),
    editCategory: builder.mutation({
      query: ({ category }) => ({
        url: `/${ADMIN_BASE_PATH}/category/${category.id}`,
        method: 'PATCH',
        body: category,
      }),
      invalidatesTags: [ResourceTags.CATEGORY],
    }),
    deleteCategory: builder.mutation({
      query: ({ categoryId }) => ({
        url: `/${ADMIN_BASE_PATH}/category/${categoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ResourceTags.CATEGORY],
    }),
    listCategories: builder.query({
      query: (query) => ({
        url: stringifyUrl(
          { url: `/${ADMIN_BASE_PATH}/category`, query },
          { skipEmptyString: true }
        ),
      }),
      providesTags: [ResourceTags.CATEGORY],
      transformResponse: paginateResponse,
    }),
    getCategory: builder.query({
      query: (categoryId) => ({
        url: `/${ADMIN_BASE_PATH}/category/${categoryId}`,
        method: 'GET',
      }),
      providesTags: [ResourceTags.CATEGORY],
    }),
    addCategoryRule: builder.mutation({
      query: ({ categoryId, ruleId, type }) => ({
        url: `/${ADMIN_BASE_PATH}/category/${categoryId}/rule/${ruleId}/${type}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        ResourceTags.CATEGORY,
        { type: ResourceTags.RULE, id: arg.ruleId },
      ],
    }),
    deleteCategoryRule: builder.mutation({
      query: ({ categoryId, ruleId, type }) => ({
        url: `/${ADMIN_BASE_PATH}/category/${categoryId}/rule/${ruleId}/${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ResourceTags.CATEGORY],
    }),
  }),
});

const {
  useCreateRuleMutation,
  useDeleteRuleMutation,
  useListRulesQuery,
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
  useListCategoriesQuery,
  useGetCategoryQuery,
  useAddCategoryRuleMutation,
  useDeleteCategoryRuleMutation,
  useGetRuleQuery,
  useEditRuleMutation,
} = service;

export {
  service,
  useCreateRuleMutation,
  useDeleteRuleMutation,
  useListRulesQuery,
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
  useListCategoriesQuery,
  useGetCategoryQuery,
  useAddCategoryRuleMutation,
  useDeleteCategoryRuleMutation,
  useGetRuleQuery,
  useEditRuleMutation,
};
