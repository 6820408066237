import ResourceTags from '@core/enums/resource-tags';
import { apiService } from '@core/store';

export const PROFILE_BASE_PATH = 'profile/v1';

const service = apiService.injectEndpoints({
  tagTypes: [
    ResourceTags.NOTE,
    ResourceTags.PROFILE_PICTURE,
    ResourceTags.SCREENER,
    ResourceTags.PROFILE,
    ResourceTags.CASE,
  ],
  endpoints: (builder) => ({
    me: builder.query({
      query: () => ({
        url: `/${PROFILE_BASE_PATH}/me`,
        method: 'GET',
      }),
      providesTags: [ResourceTags.PROFILE],
    }),
    myProfilePicture: builder.query({
      query: () => ({
        url: `/${PROFILE_BASE_PATH}/me/profile-picture`,
        method: 'GET',
      }),
      providesTags: [ResourceTags.PROFILE_PICTURE],
    }),
    updateMe: builder.mutation({
      query: (expert) => ({
        url: `/${PROFILE_BASE_PATH}/me`,
        method: 'PATCH',
        body: expert,
      }),
      invalidatesTags: [ResourceTags.PROFILE],
    }),
    updateProfilePicture: builder.mutation({
      query: (picture) => ({
        url: `/${PROFILE_BASE_PATH}/me/profile-picture`,
        method: 'PUT',
        body: picture,
      }),
      invalidatesTags: [ResourceTags.PROFILE_PICTURE, ResourceTags.PROFILE],
    }),
    userProfilePicture: builder.query({
      query: (userId) => ({
        url: `/${PROFILE_BASE_PATH}/user/${userId}/profile-picture`,
        method: 'GET',
      }),
    }),
    caseSummary: builder.query({
      query: (caseId) => ({
        url: `/${PROFILE_BASE_PATH}/case/${caseId}/summary`,
        method: 'GET',
      }),
      providesTags: [ResourceTags.CASE_SUMMARY],
    }),
  }),
});

const {
  useMeQuery,
  useMyProfilePictureQuery,
  useUpdateMeMutation,
  useUpdateProfilePictureMutation,
  useUserProfilePictureQuery,
  useCaseSummaryQuery,
} = service;

export {
  service,
  useMeQuery,
  useMyProfilePictureQuery,
  useUpdateMeMutation,
  useUpdateProfilePictureMutation,
  useUserProfilePictureQuery,
  useCaseSummaryQuery,
};
