import React, { useMemo, useState } from 'react';

import { string, bool, func, shape } from 'prop-types';
import * as Yup from 'yup';
import styled from 'styled-components';
import Drawer from 'react-modern-drawer';

import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import {
  BackdropRow,
  Box,
  ClayTable,
  ClayText,
  Column,
  Heading2,
  ScrollableColumn,
  SortableHeader,
  SubmitButton,
  TableData,
  TableHeaderItem,
  TableRow,
} from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import Search from '@core/components/search';
import PaginationSizeSelector, {
  sizeOptions,
} from '@core/components/pagination/pagination-size-selector';
import Pagination from '@core/components/pagination';
import { Pencil } from '@core/components/icons';
import Form from '@core/components/form';
import useForm from '@core/hooks/use-form';
import InputField, { Error } from '@core/components/form/input-field';

import { useListCurrentThemesQuery, usePatchThemeMutation } from '../service';

export default function ThemesPage() {
  const {
    data = [],
    pagination,
    isLoading,
    error,
  } = useRequestWithUrlPagination({
    request: useListCurrentThemesQuery,
    initialSort: 'ageRange.asc',
  });

  const [editingTheme, setEditingTheme] = useState(null);

  return (
    <ScrollableColumn flex="1 1 auto" width="100%" alignItems="center">
      <Box $width="100%" p="smallMedium">
        <h2>Themes</h2>
      </Box>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <BackdropRow
          justifyContent="space-around"
          px="medium"
          width="100%"
          py="smallMedium"
          $gap="medium"
        >
          <Search
            initialValue={pagination.search}
            onSearch={pagination.changeSearch}
          />
          <PaginationSizeSelector
            onChange={pagination.changeSize}
            size={pagination.size}
            options={sizeOptions}
          />
        </BackdropRow>
        <ScrollableColumn $width="100%" p="smallMedium">
          <ClayTable borderRadius="large" $height="fit-content">
            <thead>
              <TableRow variant="header" $width="100%" position="sticky">
                <TableHeaderItem>
                  <SortableHeader
                    field="name"
                    label="Name"
                    currentSort={pagination.sort}
                    onSort={pagination.changeSort}
                  />
                </TableHeaderItem>
                <TableHeaderItem>
                  <SortableHeader
                    field="ageRange"
                    label="Age Range"
                    currentSort={pagination.sort}
                    onSort={pagination.changeSort}
                  />
                </TableHeaderItem>
                <TableHeaderItem>Start Date</TableHeaderItem>
                <TableHeaderItem>End Date</TableHeaderItem>
                <TableHeaderItem>Actions</TableHeaderItem>
              </TableRow>
            </thead>
            <tbody>
              {data.map((theme, index) => (
                <TableRow
                  key={`${theme.id}.row`}
                  $width="100%"
                  variant={index % 2 === 0 ? 'dark' : 'light'}
                >
                  <TableData>{theme.name}</TableData>
                  <TableData>{theme.ageRange}</TableData>
                  <TableData>{theme.startDay}</TableData>
                  <TableData>{theme.endDay}</TableData>
                  <TableData>
                    <StyledPencil onClick={() => setEditingTheme(theme)} />
                    {editingTheme?.id === theme.id ? (
                      <EditThemeDrawer
                        open
                        onClose={() => setEditingTheme(null)}
                        theme={theme}
                      />
                    ) : null}
                  </TableData>
                </TableRow>
              ))}
            </tbody>
          </ClayTable>
        </ScrollableColumn>
        <Pagination pagination={pagination} />
      </AsyncStateWrapper>
    </ScrollableColumn>
  );
}

const StyledPencil = styled(Pencil)`
  cursor: pointer;
`;

const schema = Yup.object().shape({
  name: Yup.string()
    .matches(/^.{1,255}$/, 'Name must be less than 255 characters')
    .required('Name is required'),
});

const EditThemeDrawer = ({ open, onClose, theme }) => {
  const [mutateTheme] = usePatchThemeMutation();

  const fields = useMemo(
    () => ({
      name: { type: 'text', initialValue: theme.name },
    }),
    [theme.name]
  );

  const { handleSubmit, inputs, errors, labels, submitDisabled } = useForm({
    schema,
    fields,
    submit: (values) => {
      mutateTheme({ id: theme.id, ...values });
    },
    onSuccess: onClose,
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      enableOverlay
      direction="right"
      size="75vw"
    >
      <Column alignContent="center" p="medium" $gap="large">
        <Heading2>Theme:</Heading2>
        <ClayText>
          <strong>{theme?.name}</strong> for age:{' '}
          <strong>{theme?.ageRange}</strong> from date:{' '}
          <strong>{theme?.startDay}</strong> to:{' '}
          <strong>{theme?.endDay}</strong>
        </ClayText>
        <Form onSubmit={handleSubmit} $gap="medium">
          {Object.keys(inputs).map((key) => (
            <InputField
              key={key}
              label={labels[key]}
              input={{ ...inputs[key], p: 'small' }}
              error={errors[key]}
              pb="medium"
            />
          ))}
          <SubmitButton
            type="submit"
            disabled={submitDisabled}
            variant={submitDisabled ? 'disabled' : 'enabled'}
          >
            Submit
          </SubmitButton>
          {errors.formSubmissionError && (
            <Error message={errors.formSubmissionError} />
          )}
        </Form>
      </Column>
    </Drawer>
  );
};

EditThemeDrawer.propTypes = {
  open: bool,
  onClose: func,
  theme: shape({
    id: string,
    name: string,
    ageRange: string,
    startDay: string,
    endDay: string,
  }),
};
