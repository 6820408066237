import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'styled-system';

import NavigationIcon from '@core/components/navigation-icons/navigation-icon';
import { ReactComponent as Icon } from '@core/components/navigation-icons/add/icon.svg';
import { $fill } from '@core/theme/system';

const AddIcon = styled(Icon)`
  ${compose($fill)}
`;

const AddNavigationIcon = ({ title = 'Add', onClick, color, disabled }) => (
  <NavigationIcon title={title} onClick={onClick} disabled={disabled}>
    <AddIcon $fill={color} />
  </NavigationIcon>
);
export default AddNavigationIcon;

AddNavigationIcon.propTypes = {
  title: PropTypes.string,
  onClick: NavigationIcon.propTypes.onClick,
  color: NavigationIcon.propTypes.color,
  disabled: NavigationIcon.propTypes.disabled,
};
