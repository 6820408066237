import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from '@frontegg/react';

import SideBar from '@core/components/side-bar';
import { Row } from '@core/components/basic';

import { setCredentials, setId } from '@features/authentication/store';

import AppRouter from './app-router';
import GlobalStyle from './global-style';

function App() {
  const dispatch = useDispatch();
  const { accessToken, id } = useAuthUser(); // redirects to /login if not authenticated

  // token in store to be used in api requests
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (accessToken) {
      // sets the token retrieved from frontegg in store
      dispatch(setCredentials({ accessToken }));
    }

    dispatch(setId({ id }));
  }, [dispatch, accessToken, id]);

  return (
    <>
      <GlobalStyle />
      {/* Should only render content when the authentication token is set in the store */}
      {token && (
        <Row flex={1}>
          <SideBar />
          <AppRouter />
        </Row>
      )}
    </>
  );
}

export default App;
