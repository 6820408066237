import { stringifyUrl } from 'query-string';

import { apiService } from '@core/store';
import { paginateResponse } from '@core/api-service';

export const SCHOOL_BASE_PATH = 'school/v1';

const service = apiService.injectEndpoints({
  endpoints: (builder) => ({
    listAssistantAnswers: builder.query({
      query: ({ childId, ...query }) => ({
        url: stringifyUrl({
          url: `${SCHOOL_BASE_PATH}/admin/assistant/answer`,
          query,
        }),
        method: 'GET',
      }),
      transformResponse: paginateResponse,
    }),
    listAssistantLessons: builder.query({
      query: ({ childId, ...query }) => ({
        url: stringifyUrl({
          url: `${SCHOOL_BASE_PATH}/admin/assistant/lesson`,
          query,
        }),
        method: 'GET',
      }),
      transformResponse: paginateResponse,
    }),
  }),
});

const { useListAssistantAnswersQuery, useListAssistantLessonsQuery } = service;

export { useListAssistantAnswersQuery, useListAssistantLessonsQuery, service };
