import styled from 'styled-components';
import { compose, border, space } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

export const Divider = styled.hr`
  ${compose(border, space)}
`;

Divider.propTypes = createPropTypes(border.propNames, space.propNames);

Divider.defaultProps = {
  width: '100%',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'whisper',
};
