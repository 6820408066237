import UserRoles from 'clay-commons/enum/user-roles';

import RootAssetPage from '@features/assets/pages/root';
import buildPath from '@features/assets/build-path';

export const root = {
  path: buildPath(),
  component: RootAssetPage,
  roles: [UserRoles.ADMIN],
};

const routes = [root];

export default routes;
