const colors = {
  white: '#FFFFFF',
  black: '#000000',
  spotBrick: '#CC6D63',
  textBlack: '#1F1D1A',
  textLavender: '#626A80',
  textCherry: '#6E3C3C',
  textClay: '#BF6955',
  backgroundGrey: '#F9F9F9',
  darkerBackgroundGrey: '#F2F2F2',
  backgroundPeach: '#FBE9DE',
  tintBlack: '#8F8E8C',
  rose: '#F3E1E0',
  spotRose: '#CD6E52',
  spotMint: '#65B981',
  backgroundSand: '#F6EAE0',
  // Unbranded colors. Reference: https://www.htmlcsscolor.com
  roseWhite: '#F3EEED',
  coral: '#FF744C',
  celeste: '#D2D2D1',
  whisper: '#EEEEEE',
  dustyGrey: '#B39B9B',
  dustStorm: '#ECCEC3',
  swirl: '#CFCECD',
  charm: '#CC637C', // error color
  tulipTree: '#E1B33B',
  lilac: '#E6E6F5',
  spotLilac: '#C1C2E5',
  lavender: '#616A82;',
  lavenderBlush: '#FAEFF2;',
  sepia: '#9F5844;',
  amparoBlue: '#4863A8',
  grey: '#E6E6E6',
  guyabano: '#f8f8f8',
  whiteSmoke: '#f1f1f1',
};

export default colors;
