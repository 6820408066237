import React from 'react';

import { Box, ScrollableColumn, Heading2 } from '@core/components/basic';

import CaseList from '@features/admin/components/case-list';

const CasePage = () => (
  <ScrollableColumn flex="1 1 auto" width="100%" alignItems="center">
    <Box width={1} p={20}>
      <Heading2>Cases management</Heading2>
      <CaseList />
    </Box>
  </ScrollableColumn>
);

export default CasePage;
