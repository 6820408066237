import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const createService = (config) => {
  return createApi({
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_CMS_HOST,
    }),
    ...config,
  });
};

export default createService;
