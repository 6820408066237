import React from 'react';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
  Heading2,
  HiddenScrollColumn,
  SubmitButton,
} from '@core/components/basic';
import InputField from '@core/components/form/input-field';
import Form from '@core/components/form';
import useForm from '@core/hooks/use-form';
import Error from '@core/components/async-state/error';

const FIELDS = {
  PRIORITY: 'priority',
  CONCURRENT_ASSET_LIMIT: 'concurrentAssetLimit',
  NAME: 'name',
  DESCRIPTION: 'description',
};

const FlowForm = ({
  header,
  data = {},
  buttonLabel,
  onSubmit,
  onSuccess,
  disabled = false,
}) => {
  const form = useForm({
    schema: Yup.object().shape({
      [FIELDS.PRIORITY]: Yup.number().min(0).required(),
      [FIELDS.CONCURRENT_ASSET_LIMIT]: Yup.number().min(0).required(),
      [FIELDS.NAME]: Yup.string(),
      [FIELDS.DESCRIPTION]: Yup.string(),
    }),
    fields: {
      [FIELDS.PRIORITY]: {
        type: 'number',
        initialValue: data.priority,
        disabled,
      },
      [FIELDS.CONCURRENT_ASSET_LIMIT]: {
        type: 'number',
        initialValue: data.concurrentAssetLimit,
        disabled,
      },
      [FIELDS.NAME]: {
        type: 'text',
        initialValue: data.name,
        disabled,
      },
      [FIELDS.DESCRIPTION]: {
        type: 'textarea',
        initialValue: data.description,
        disabled,
      },
    },
    submit: onSubmit,
    onSuccess,
  });

  return (
    <HiddenScrollColumn
      flex={1}
      justifyContent="center"
      p="smallMedium"
      $gap="smallMedium"
    >
      <Heading2>{header}</Heading2>
      <Form onSubmit={form.handleSubmit}>
        {Object.keys(form.inputs).map((key) => (
          <InputField
            key={key}
            label={form.labels[key]}
            input={form.inputs[key]}
            error={form.errors[key]}
            p="extraSmall"
          />
        ))}
        {onSubmit && (
          <SubmitButton
            type="submit"
            disabled={form.submitDisabled}
            variant={form.submitDisabled ? 'disabled' : 'enabled'}
          >
            {buttonLabel}
          </SubmitButton>
        )}
      </Form>
      {form.errors.formSubmissionError && (
        <Error message={form.errors.formSubmissionError} />
      )}
    </HiddenScrollColumn>
  );
};

export default FlowForm;

FlowForm.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    priority: PropTypes.number,
    concurrentAssetLimit: PropTypes.number,
  }),
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
};
