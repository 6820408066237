import React, { useCallback } from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';

import { Row } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import { CloseNavigationIcon } from '@core/components/navigation-icons';

import {
  useGetAssetDetailsOfTypeQuery,
  usePatchAssetOfTypeMutation,
} from '@features/assets/service';
import AssetForm, { FIELDS } from '@features/assets/components/form';

const ViewAssetDrawer = ({ onClose, type, externalId }) => {
  const {
    data: asset = {},
    isLoading,
    error,
  } = useGetAssetDetailsOfTypeQuery({ type, externalId });

  const [patch] = usePatchAssetOfTypeMutation();

  const onSubmit = useCallback(
    ({ name }) => patch({ type, externalId, name }),
    [patch, type, externalId]
  );

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row justifyContent="end" p="small">
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <AssetForm
          type={type}
          header={`Edit "${type}" asset`}
          data={asset}
          requiredFields={[FIELDS.NAME]}
          disabledFields={[FIELDS.ASSET]}
          buttonLabel="Update"
          onSubmit={onSubmit}
          onSuccess={onClose}
        />
      </AsyncStateWrapper>
    </Drawer>
  );
};

export default ViewAssetDrawer;

ViewAssetDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: AssetForm.propTypes.type,
  externalId: PropTypes.number.isRequired,
};
