import React, { useCallback } from 'react';

import { createPropTypes } from '@styled-system/prop-types';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import { compose, flexbox, color, space } from 'styled-system';
import UserRoles from 'clay-commons/enum/user-roles';

import AuthorizedContent from '@core/components/authorized-content';

import buildAssetsPath from '@features/assets/build-path';
import buildFlowsPath from '@features/flows/build-path';

import { Column } from '../basic';

import SideBarButton from './button';
import {
  ClayLogo,
  SettingsIcon,
  UserManagementIcon,
  AssetIcon,
  FlowIcon,
  AssistantIcon,
  AdminIcon,
  LogoutIcon,
  PaletteIcon,
} from './icons';

const SideBar = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const navigateTo = useCallback(
    (route) => () => {
      push(route);
    },
    [push]
  );

  const isActiveRoute = useCallback(
    (route) => matchPath(pathname, route) !== null,
    [pathname]
  );

  return (
    <Navigation
      flexDirection="column"
      flexBasis="104px"
      flexShrink={0}
      bg="white"
      p="medium"
      justifyContent="space-between"
    >
      <SideBarButton
        onClick={navigateTo(ROUTES.HOME)}
        active={isActiveRoute(ROUTES.HOME)}
      >
        <ClayLogo />
      </SideBarButton>
      <Column flexGrow={0.75} $gap="medium">
        <AuthorizedContent requiredRoles={[UserRoles.ADMIN]}>
          <SideBarButton
            onClick={navigateTo(ROUTES.ASSISTANT)}
            active={isActiveRoute(ROUTES.ASSISTANT)}
          >
            <AssistantIcon />
          </SideBarButton>
        </AuthorizedContent>
        <AuthorizedContent requiredRoles={[UserRoles.ADMIN]}>
          <SideBarButton
            onClick={navigateTo(ROUTES.ADMIN)}
            active={isActiveRoute(ROUTES.ADMIN)}
          >
            <SettingsIcon />
          </SideBarButton>
          <SideBarButton
            onClick={navigateTo(ROUTES.ADMIN_USERS)}
            active={isActiveRoute(ROUTES.ADMIN_USERS)}
          >
            <UserManagementIcon />
          </SideBarButton>
          <SideBarButton
            onClick={navigateTo(ROUTES.ADMIN_RULES)}
            active={isActiveRoute(ROUTES.ADMIN_RULES)}
          >
            <UserManagementIcon />
          </SideBarButton>
          <SideBarButton
            title="Flows"
            onClick={navigateTo(buildFlowsPath())}
            active={isActiveRoute(buildFlowsPath())}
          >
            <FlowIcon />
          </SideBarButton>
          <SideBarButton
            title="Assets"
            onClick={navigateTo(buildAssetsPath())}
            active={isActiveRoute(buildAssetsPath())}
          >
            <AssetIcon />
          </SideBarButton>
          <SideBarButton
            title="Settings"
            onClick={navigateTo(ROUTES.SETTINGS)}
            active={isActiveRoute(ROUTES.SETTINGS)}
          >
            <AdminIcon height={36} width={36} />
          </SideBarButton>
          <SideBarButton
            title="Themes"
            onClick={navigateTo(ROUTES.THEMES)}
            active={isActiveRoute(ROUTES.THEMES)}
          >
            <PaletteIcon height={36} width={36} />
          </SideBarButton>
        </AuthorizedContent>
      </Column>
      <Column justifyContent="center" alignItems="center">
        <SideBarButton title="Logout" onClick={navigateTo('/account/logout')}>
          <LogoutIcon height={36} width={36} />
        </SideBarButton>
      </Column>
    </Navigation>
  );
};

export default SideBar;

const ROUTES = {
  HOME: '/',
  ADMIN: '/admin',
  ADMIN_USERS: '/admin-users',
  ADMIN_RULES: '/admin-rules',
  PROFILE: '/profile',
  ASSISTANT: '/assistant',
  SETTINGS: '/settings',
  THEMES: '/themes',
};

const Navigation = styled.nav`
  ${compose(flexbox, color, space)}
  display: flex;
`;

Navigation.propTypes = createPropTypes(
  flexbox.propNames,
  color.propNames,
  space.propNames
);
