/**
 * Transient alternatives to default systems.
 */
import { system } from 'styled-system';

// Reference: https://styled-system.com/custom-props#transform
const transform = (value, scale) => scale[`${value}`] ?? value;

export const $layout = system({
  $size: { properties: ['width', 'height'], scale: 'space', transform },
  $height: { property: 'height', scale: 'space', transform },
  $minHeight: { property: 'min-height', scale: 'space', transform },
  $width: { property: 'width', scale: 'space', transform },
  $minWidth: { property: 'min-width', scale: 'space', transform },
  $overflow: { property: 'overflow' },
  $fit: { property: 'objectFit' },
});

export const $flexbox = system({
  $gap: { property: 'gap', scale: 'space', transform },
  $flexBasis: { property: 'flexBasis', scale: 'space', transform },
});

export const $color = system({
  $color: { property: 'color', scale: 'colors' },
});

export const $fill = system({
  $color: { property: 'fill', scale: 'colors' },
});

export const $border = system({
  $borderWidth: { property: 'borderWidth', scale: 'borderWidths', transform },
});

export const $typography = system({
  $fontSize: { property: 'fontSize', scale: 'fontSizes', transform },
  $fontFamily: { property: 'fontFamily', scale: 'fonts', transform },
  $fontWeight: { property: 'fontWeight', scale: 'fontWeights', transform },
});
