import React, { useCallback, useState } from 'react';

import AsyncStateWrapper from '@core/components/async-state';
import {
  BackdropRow,
  Box,
  ClayButton,
  ClayTable,
  ClayText,
  Column,
  Flex,
  HiddenScrollColumn,
  ScrollableColumn,
  SortableHeader,
  TableData,
  TableHeaderItem,
  TableRow,
} from '@core/components/basic';
import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import Pagination from '@core/components/pagination';
import PaginationSizeSelector, {
  sizeOptions,
} from '@core/components/pagination/pagination-size-selector';
import Modal from '@core/components/modal';
import Search from '@core/components/search';

import {
  useDeleteParentMutation,
  useListParentDeletionsQuery,
} from '@features/admin-users/service';

const ParentDeletionListPage = () => {
  const {
    data: parents,
    pagination,
    ...parentListAsyncState
  } = useRequestWithUrlPagination({
    request: useListParentDeletionsQuery,
  });

  const [deleteParent, { isError: deletionError }] = useDeleteParentMutation();

  const [userToDelete, setUserToDelete] = useState();

  const closeConfirmation = useCallback(() => {
    setUserToDelete(undefined);
  }, []);

  const confirmDeletion = useCallback(async () => {
    await deleteParent({ userId: userToDelete?.id });
    closeConfirmation();
  }, [closeConfirmation, deleteParent, userToDelete?.id]);

  const onDelete = useCallback(async (name, userId) => {
    setUserToDelete({
      id: userId,
      name,
    });
  }, []);

  return (
    <ScrollableColumn flex="1 1 auto" $width="100%" alignItems="center">
      <Box $width="100%" p="smallMedium">
        <h2>Parent Deletions</h2>
        <AsyncStateWrapper
          isLoading={parentListAsyncState.isLoading}
          error={parentListAsyncState.error?.data?.message}
        >
          <HiddenScrollColumn
            width="100%"
            alignItems="center"
            flex={1}
            overflowY="scroll"
          >
            <BackdropRow
              justifyContent="space-around"
              px="medium"
              width="100%"
              py="smallMedium"
            >
              <Search
                initialValue={pagination.search}
                onSearch={pagination.changeSearch}
              />{' '}
              <Flex flex={1} justifyContent="flex-end">
                <PaginationSizeSelector
                  onChange={pagination.changeSize}
                  size={pagination.size}
                  options={sizeOptions}
                />
              </Flex>
            </BackdropRow>

            <ScrollableColumn $width="100%" p="smallMedium">
              <ClayTable borderRadius="large" $height="fit-content">
                <thead>
                  <TableRow variant="header" $width="100%" position="sticky">
                    <TableHeaderItem>Id</TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="email"
                        label="Email"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="firstName"
                        label="First Name"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="lastName"
                        label="Last Name"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>Actions</TableHeaderItem>
                  </TableRow>
                </thead>

                <tbody>
                  {parents?.map(
                    ({ userId, email, firstName, lastName }, index) => (
                      <TableRow
                        key={userId}
                        variant={index % 2 === 0 ? 'dark' : 'light'}
                      >
                        <TableData>{userId}</TableData>
                        <TableData>{email}</TableData>
                        <TableData>{firstName}</TableData>
                        <TableData>{lastName}</TableData>

                        <TableData py="small">
                          <ClayButton
                            variant="linkButton"
                            type="button"
                            onClick={() => onDelete(firstName, userId)}
                          >
                            <p>Delete</p>
                          </ClayButton>
                        </TableData>
                      </TableRow>
                    )
                  )}
                </tbody>
              </ClayTable>
            </ScrollableColumn>
            <Pagination pagination={pagination} />

            {deletionError && (
              <ClayText
                $fontFamily="inter"
                $fontSize="body"
                $fontWeight="heavy"
                $color="charm"
              >
                Could not delete parent
              </ClayText>
            )}
          </HiddenScrollColumn>
        </AsyncStateWrapper>
      </Box>
      {userToDelete && (
        <Modal onConfirm={confirmDeletion} onCancel={closeConfirmation}>
          <Column alignItems="center" $gap="small">
            <ClayText $fontSize="bodyLarge" textAlign="center">
              Are you sure you want to delete the parent{' '}
              <strong>{userToDelete?.name}</strong>?
            </ClayText>
            <ClayText $fontSize="body" textAlign="center">
              The user and all related resources will be erased from the
              database.
            </ClayText>
          </Column>
        </Modal>
      )}
    </ScrollableColumn>
  );
};

export default ParentDeletionListPage;
