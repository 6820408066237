import React, { useCallback, useState } from 'react';

import { string, func, oneOf } from 'prop-types';
import styled from 'styled-components';
import { compose, flexbox, position, space } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

import theme from '@core/theme';
import { $color, $layout } from '@core/theme/system';
import { ClayInput } from '@core/components/basic';
import { usePageFilters } from '@core/state/page-filters';

import { ReactComponent as SearchIcon } from './search.svg';

const Search = ({
  placeholder = 'Search',
  initialValue = '',
  onSearch,
  background = 'backgroundGrey',
  iconColor = 'spotBrick',
}) => {
  const {
    filters: { search },
    patch,
  } = usePageFilters({ defaults: { search: initialValue } });

  const [value, setValue] = useState(search);
  const doSearch = useCallback(() => {
    patch({ search: value });
    onSearch?.(value);
  }, [patch, onSearch, value]);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      doSearch();
    },
    [doSearch]
  );

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  return (
    <InputContainer
      onSubmit={onSubmit}
      alignItems="center"
      flex={1}
      $height="extraLarge"
      position="relative"
    >
      <StyledSearchIcon
        alt="Search"
        onClick={onSubmit}
        $size="smallMedium"
        position="absolute"
        left="small"
        $color={iconColor}
      />
      <SearchInput
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        $fontFamily="inter"
        $fontSize="caption"
        py="extraSmall"
        pl="huge"
        pr="extraSmall"
        bg={background}
        borderRadius="extraLarge"
        flex={1}
        $height="100%"
      />
    </InputContainer>
  );
};

export default Search;

Search.propTypes = {
  placeholder: string,
  initialValue: string,
  onSearch: func,
  background: oneOf(Object.keys(theme.colors)),
  iconColor: oneOf(Object.keys(theme.colors)),
};

const InputContainer = styled.form`
  ${compose(flexbox, $layout, position)}
  display: flex;
`;

InputContainer.propTypes = createPropTypes(
  flexbox.propNames,
  $layout.propNames,
  position.propNames
);

const SearchInput = styled(ClayInput)`
  ${compose(space, flexbox, $layout)}
  display: flex;
`;

SearchInput.propTypes = {
  ...ClayInput.propTypes,
  ...createPropTypes(space.propNames, flexbox.propNames, $layout.propNames),
};

const StyledSearchIcon = styled(SearchIcon)`
  ${compose($layout, position, $color)}
`;

SearchIcon.propTypes = createPropTypes($layout.propNames, position.propNames);
