/**
 * User role names
 *
 * @enum {string}
 */
module.exports = {
    PARENT: 'parent',
    ADMIN: 'admin',
    TEACHER: 'teacher'
};
