import React from 'react';

import { node, string } from 'prop-types';

import { Page, Heading1, Box } from '@core/components/basic';
import spacing from '@core/theme/space';

const AdminPageFrame = ({ children, title = 'Admin' }) => (
  <Page
    alignItems="center"
    py={spacing.smallMedium}
    justifyContent="space-between"
    flexDirection="column"
  >
    <Box p="smallMedium">
      <Heading1>{title}</Heading1>
    </Box>
    {children}
  </Page>
);

export default AdminPageFrame;

AdminPageFrame.propTypes = {
  children: node.isRequired,
  title: string,
};
