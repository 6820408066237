import React, { createContext, useCallback, useMemo } from 'react';

import styled from 'styled-components';
import * as Yup from 'yup';
import { sha1 } from 'object-hash';
import { shape, number, func } from 'prop-types';

import AsyncStateWrapper from '@core/components/async-state';
import {
  Column,
  HiddenScrollColumn,
  SubmitButton,
} from '@core/components/basic';
import InputField from '@core/components/form/input-field';
import Form from '@core/components/form';
import useForm from '@core/hooks/use-form';

import {
  useGetSchoolSettingsQuery,
  usePatchSchoolSettingsMutation,
} from '../../service';

import RootConfiguration from './configuration';

export default function SchoolSettingsPage() {
  const { data, ...getSettings } = useGetSchoolSettingsQuery();
  const [patch, patchSettings] = usePatchSchoolSettingsMutation();
  const submit = useCallback((body) => patch({ body }), [patch]);
  const error = getSettings.error ?? patchSettings.error;

  return (
    <AsyncStateWrapper
      isLoading={getSettings.isLoading || patchSettings.isLoading}
      error={error?.data?.message}
    >
      <HiddenScrollColumn
        width="100%"
        alignItems="center"
        flex={1}
        overflowY="scroll"
      >
        {data && (
          <Column width="100%" alignItems="center" $gap="large">
            <SchoolSettingsForm key={sha1(data)} data={data} submit={submit} />
            <SchoolSettingsContext.Provider value={data}>
              <RootConfiguration />
            </SchoolSettingsContext.Provider>
          </Column>
        )}
      </HiddenScrollColumn>
    </AsyncStateWrapper>
  );
}

export const SchoolSettingsContext = createContext({});
function SchoolSettingsForm({ data, submit }) {
  const form = useForm({
    fields: useMemo(
      () => ({
        scheduleLimit: {
          type: 'number',
          initialValue: data?.scheduleLimit,
        },
        questionLimit: {
          type: 'number',
          initialValue: data?.questionLimit,
        },
        standaloneLessonLimit: {
          type: 'number',
          initialValue: data?.standaloneLessonLimit,
        },
        observationLimit: {
          type: 'number',
          initialValue: data?.observationLimit,
        },
        educationStandardLimit: {
          type: 'number',
          initialValue: data?.educationStandardLimit,
        },
        lessonPrintingLimit: {
          type: 'number',
          initialValue: data?.lessonPrintingLimit,
        },
        limitRange: {
          type: 'number',
          initialValue: data?.limitRange,
        },
      }),
      [data]
    ),
    schema: Yup.object().shape({
      scheduleLimit: Yup.number().min(0).required(),
      questionLimit: Yup.number().min(0).required(),
      standaloneLessonLimit: Yup.number().min(0).required(),
      observationLimit: Yup.number().min(0).required(),
      educationStandardLimit: Yup.number().min(0).required(),
      lessonPrintingLimit: Yup.number().min(0).required(),
      limitRange: Yup.number().min(0).required(),
    }),
    submit,
  });

  return (
    <Form
      onSubmit={form.handleSubmit}
      width="100%"
      flexDirection="row"
      display="flex"
      flexWrap="wrap"
    >
      {Object.keys(form.inputs).map((key) => (
        <InputField
          key={key}
          label={form.labels[key]}
          input={form.inputs[key]}
          error={form.errors[key]}
          p="small"
          width="20%"
        />
      ))}
      <Break />
      <SubmitButton
        type="submit"
        disabled={form.submitDisabled}
        variant={form.submitDisabled ? 'disabled' : 'enabled'}
      >
        Submit
      </SubmitButton>
    </Form>
  );
}

SchoolSettingsForm.propTypes = {
  data: shape({
    scheduleLimitPerUserPerDay: number,
  }).isRequired,
  submit: func.isRequired,
};

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;
