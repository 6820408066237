import React, { useCallback, useState } from 'react';

import { func, string, shape, arrayOf } from 'prop-types';

import {
  ClayButton,
  ClayText,
  Column,
  Heading3,
  Row,
  Select,
  SubmitButton,
} from '@core/components/basic';

const RuleTypes = {
  INCLUSIVE: 'inclusive',
  CLONING: 'cloning',
};

const CategoryRuleForm = ({
  category,
  rules = [],
  onAddRule,
  onRemoveRule,
  error = '',
}) => {
  const [selectedRule, setSelectedRule] = useState();
  const [selectedType, setSelectedType] = useState();
  const [errorDisplay, setErrorDisplay] = useState(error);

  const addRule = useCallback(() => {
    if (!selectedRule || !selectedType) {
      setErrorDisplay(
        'In order to associate a rule both the rule and type must be specified'
      );
      return;
    }
    onAddRule(selectedRule, selectedType);
    setErrorDisplay(undefined);
  }, [onAddRule, selectedRule, selectedType]);

  return (
    <Column flex={1} $gap="small">
      <Row $gap="small">
        <Column flex={1} $gap="small">
          <label htmlFor="rule">
            <ClayText
              $fontSize="caption"
              $fontWeight="medium"
              $fontFamily="inter"
              $color="tintBlack"
            >
              Name
            </ClayText>
          </label>
          <Select
            $width="100%"
            $height="large"
            p="tiny"
            borderRadius="medium"
            border="1px solid"
            borderColor="tintBlack"
            $fontSize="body"
            $fontFamily="inter"
            bg="white"
            name="rule"
            onChange={(event) => setSelectedRule(event.target.value)}
            value={selectedRule}
          >
            <option value="">Select a rule</option>
            {rules.map((rule) => (
              <option key={rule.id} value={rule.id}>
                {rule.name}
              </option>
            ))}
          </Select>
        </Column>
        <Column flex={1} $gap="small">
          <label htmlFor="ruleType">
            <ClayText
              $fontSize="caption"
              $fontWeight="medium"
              $fontFamily="inter"
              $color="tintBlack"
            >
              Type
            </ClayText>
          </label>
          <Select
            $width="100%"
            $height="large"
            p="tiny"
            borderRadius="medium"
            border="1px solid"
            borderColor="tintBlack"
            $fontSize="body"
            $fontFamily="inter"
            bg="white"
            name="ruleType"
            onChange={(event) => setSelectedType(event.target.value)}
            value={selectedType}
          >
            <option value="">Select a type</option>
            {Object.entries(RuleTypes).map(([key, value]) => (
              <option key={value} value={value}>
                {key}
              </option>
            ))}
          </Select>
        </Column>
        <Column justifyContent="flex-end">
          <SubmitButton onClick={addRule}>Add</SubmitButton>
        </Column>
      </Row>
      <Row>
        <ClayText $color="charm" $fontWeight="heavy" $fontFamily="inter">
          {error || errorDisplay}
        </ClayText>
      </Row>
      <Column $gap="medium" flex={1} pt="medium">
        <RuleList
          title="Inclusive Rules"
          rules={category.inclusiveRules}
          onRemove={(ruleId) => onRemoveRule(ruleId, RuleTypes.INCLUSIVE)}
        />
        <RuleList
          title="Cloning Rules"
          rules={category.cloningRules}
          onRemove={(ruleId) => onRemoveRule(ruleId, RuleTypes.CLONING)}
        />
      </Column>
    </Column>
  );
};

CategoryRuleForm.propTypes = {
  rules: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
  category: shape({
    id: string.isRequired,
    inclusiveRules: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
      })
    ).isRequired,
    cloningRules: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onAddRule: func.isRequired,
  onRemoveRule: func.isRequired,
  error: string,
};

export default CategoryRuleForm;

const RuleList = ({ title, rules, onRemove }) => (
  <Column $gap="small">
    <Heading3>{title}</Heading3>

    {!!rules.length &&
      rules.map((rule) => (
        <Row
          key={rule.id}
          alignItems="center"
          justifyContent="space-between"
          bg="darkerBackgroundGrey"
          p="smallMedium"
          borderRadius="medium"
        >
          <ClayText $fontFamily="inter" $fontSize="body">
            {rule.name}
          </ClayText>
          <ClayButton onClick={() => onRemove(rule.id)}>
            <ClayText $color="spotBrick" $fontFamily="inter" $fontSize="body">
              Remove
            </ClayText>
          </ClayButton>
        </Row>
      ))}
    {!rules.length && (
      <ClayText $fontFamily="inter" $fontSize="body">
        No rules of this type exist for this category
      </ClayText>
    )}
  </Column>
);

RuleList.propTypes = {
  title: string.isRequired,
  rules: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
  onRemove: func.isRequired,
};
