import UserRoles from 'clay-commons/enum/user-roles';

import RootPage from '.';

const routes = [
  {
    path: '/admin-rules',
    component: RootPage,
    roles: [UserRoles.ADMIN],
  },
];

export default routes;
