import React, { useCallback } from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';

import { Row } from '@core/components/basic';
import { CloseNavigationIcon } from '@core/components/navigation-icons';

import FlowAssetForm from '@features/flows/components/form/asset';
import { useAddAssetToFlowMutation } from '@features/flows/service';

const AddAssetDrawer = ({ onClose, flowId }) => {
  const [addAsset] = useAddAssetToFlowMutation();

  const onSubmit = useCallback(
    ({ asset, priority, channel, guard, start, interval, end }) =>
      addAsset({
        flowId,
        assetId: asset,
        priority,
        channel,
        guardId: guard === '' ? undefined : guard,
        startRuleId: start,
        interval: interval === '' ? undefined : interval,
        endRuleId: end === '' ? undefined : end,
      }),
    [addAsset, flowId]
  );

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row justifyContent="end" p="small">
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <FlowAssetForm
        header="Add asset"
        buttonLabel="Add"
        onSubmit={onSubmit}
        onSuccess={onClose}
      />
    </Drawer>
  );
};

export default AddAssetDrawer;

AddAssetDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
};
