import { createPropTypes } from '@styled-system/prop-types';
import styled from 'styled-components';
import { border, color, compose, space, flexbox, layout } from 'styled-system';

import { $flexbox, $typography, $layout } from '@core/theme/system';

export const ClayInput = styled.input`
  all: unset;
  ${compose(
    $typography,
    border,
    color,
    space,
    flexbox,
    $flexbox,
    layout,
    $layout
  )}
  box-sizing: border-box;
`;

ClayInput.propTypes = createPropTypes(
  $typography.propNames,
  border.propTypes,
  color.propTypes,
  space.propTypes,
  flexbox.propTypes,
  $flexbox.propTypes,
  layout.propTypes,
  $layout.propTypes
);

export const ClayTextarea = styled.textarea`
  all: unset;
  ${compose($typography, border, color, space, layout, $layout)}
  box-sizing: border-box;
`;

export const ClayCheckbox = styled.input`
  ${compose($typography, border, color, space, layout, $layout)}
  box-sizing: border-box;
`;
