import React, { useCallback, useMemo, useState } from 'react';

import { shape, string, arrayOf } from 'prop-types';
import styled from 'styled-components';

import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import { Flex, Row } from '@core/components/basic';
import ComposableList from '@core/components/composable-list/composable-list';
import { formatShortDate } from '@core/format-date';
import space from '@core/theme/space';
import InteractableBadge from '@core/components/interactable-badge';
import ClayAvatar from '@core/components/avatar';
import {
  TimerNavigationIcon,
  ViewNavigationIcon,
} from '@core/components/navigation-icons';

import { useListCasesQuery } from '@features/admin/service';

import TasksTableDrawer from './tasks-table-drawer';
import CaseDetailsDrawer from './case-details/case-details-drawer';

const CaseList = () => {
  const [childDetailsId, setChildDetailsId] = useState();
  const [selectedCaseId, setSelectedCaseId] = useState();

  const columns = useMemo(
    () => ({
      firstName: {
        header: 'Parent Name',
        sortable: true,
        valueBuilder: (entry) => `${entry?.parent?.firstName ?? ''} 
                    ${entry?.parent?.lastName ?? ''}`,
      },
      childName: {
        header: 'Child Name',
        sortable: true,
        valueBuilder: (entry) => entry.child.name,
      },
      childId: {
        header: 'Child Id',
        sortable: true,
      },
      childBirthDate: {
        header: 'Child Birthdate',
        sortable: true,
        valueBuilder: (entry) =>
          entry.child?.birthDate ? formatShortDate(entry.child.birthDate) : '',
      },
      email: {
        header: 'Parent Email',
        sortable: true,
        valueBuilder: (entry) => entry.parent.email,
      },
      id: {
        header: 'Case id',
      },
      experts: {
        header: 'Care Navigators',
        valueBuilder: (entry) => <TableCellExpertsBadgeList {...entry} />,
      },
    }),
    []
  );

  const changeChildDetailsId = useCallback((id) => {
    setChildDetailsId(id);
    setSelectedCaseId(null);
  }, []);

  const changeSelectedCaseId = useCallback((id) => {
    setSelectedCaseId(id);
    setChildDetailsId(null);
  }, []);

  const actionsBuilder = useCallback(
    ({ childId, child, id }) => (
      <>
        <Row $gap="small">
          <TimerNavigationIcon
            title="See Scheduled Tasks"
            onClick={() => changeChildDetailsId(childId)}
          />

          <ViewNavigationIcon
            title="See Case Details"
            onClick={() => changeSelectedCaseId(id)}
          />
        </Row>
        {childDetailsId === childId && (
          <TasksTableDrawer
            childId={childId}
            open
            onClose={() => changeChildDetailsId(null)}
            childName={child.name}
          />
        )}
        {selectedCaseId === id && (
          <CaseDetailsDrawer
            caseId={id}
            open
            onClose={() => changeSelectedCaseId(null)}
          />
        )}
      </>
    ),
    [changeChildDetailsId, changeSelectedCaseId, childDetailsId, selectedCaseId]
  );

  return (
    <ComposableList
      columns={columns}
      useRequest={useListCasesRequest}
      withSearch
      actionsBuilder={actionsBuilder}
    />
  );
};

export default CaseList;

const TableCellExpertsBadgeList = ({ experts = [], id }) => {
  return (
    experts.length > 0 && (
      <BadgeContainer py="extraSmall" pr="extraSmall" flexWrap="wrap">
        {experts.map(({ profilePicture, name, userId }) => (
          <InteractableBadge
            key={`${id}-${userId}`}
            mode="grey"
            prefix={<ClayAvatar src={profilePicture.url} $size="medium" />}
          >
            {name}
          </InteractableBadge>
        ))}
      </BadgeContainer>
    )
  );
};

TableCellExpertsBadgeList.propTypes = {
  parent: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
  }),
  child: shape({
    name: string.isRequired,
  }),
  experts: arrayOf(
    shape({
      name: string,
      userId: string,
      profilePicture: shape({
        url: string,
      }),
    })
  ),
  id: string,
};

const BadgeContainer = styled(Flex)`
  & > * {
    margin-right: ${space.tiny};
    margin-bottom: ${space.tiny};
  }
`;
const useListCasesRequest = () =>
  useRequestWithUrlPagination({ request: useListCasesQuery });
