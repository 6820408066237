import { createPropTypes } from '@styled-system/prop-types';
import styled from 'styled-components';
import { layout, space, compose, flexbox, flex } from 'styled-system';

export const UL = styled.ul`
  all: unset;

  ${compose(flex, flexbox, layout, space)}
`;

export const LI = styled.li`
  all: unset;

  ${compose(space, flex)}
`;

UL.propTypes = createPropTypes(
  flex.propNames,
  flexbox.propNames,
  layout.propNames,
  space.propNames
);

LI.propTypes = createPropTypes(space.propNames, flex.propNames);
