import React from 'react';

import ReactDOM from 'react-dom';

import { useDevelopmentMockServiceWorkers } from '@features/mock-service-workers';

import AppProvider from './app-provider';
import App from './app';
import reportWebVitals from './reportWebVitals';

if (!process.env.REACT_APP_CMS_HOST) {
  throw Error('REACT_APP_CMS_HOST environment variable is missing.');
}

/* eslint-disable react-hooks/rules-of-hooks */
// this is not a hook
useDevelopmentMockServiceWorkers();
/* eslint-enable react-hooks/rules-of-hooks */

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
