import React from 'react';

import { string, node } from 'prop-types';

import { ClayText, Column, Row } from '@core/components/basic';
import { formatShortDate } from '@core/format-date';

const { TextButton } = require('@core/components/composite/buttons');

const { useLazyReportQuery } = require('@features/admin/service');

const PublishedReportAction = ({ id }) => {
  const [queryTrigger] = useLazyReportQuery();
  const download = async () => {
    const { data } = await queryTrigger(id);

    if (data?.file) {
      let link = document.createElement('a');
      link.href = data.file.url;
      link.target = '_blank';
      link.click();
    }
  };

  return (
    <TextButton
      text="Open"
      fontSize="caption"
      fontWeight="medium"
      color="textClay"
      onClick={download}
    />
  );
};

PublishedReportAction.propTypes = {
  id: string.isRequired,
};

const PublishedDocument = ({ name, date, action }) => {
  return (
    <Row
      bg="backgroundGrey"
      borderRadius="medium"
      py="small"
      px="smallMedium"
      justifyContent="space-between"
    >
      <Column $gap="tiny">
        <Row $gap="tiny">
          <ClayText $fontSize="body" $color="textBlack">
            {name}
          </ClayText>
        </Row>
        <ClayText $fontSize="body" $color="tintBlack">
          Sent on: {formatShortDate(date)}
        </ClayText>
      </Column>
      {action}
    </Row>
  );
};

PublishedDocument.propTypes = {
  name: string.isRequired,
  date: string.isRequired,
  action: node.isRequired,
};

export { PublishedDocument, PublishedReportAction };
