const space = {
  extraTiny: '1px',
  tiny: '4px',
  extraSmall: '6px',
  small: '10px',
  smallMedium: '16px',
  medium: '24px',
  large: '38px',
  extraLarge: '48px',
  huge: '56px',
  extraHuge: '70px',
};

export default space;
