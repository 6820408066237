import React from 'react';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Channels from 'clay-commons/enum/channel-types';

import {
  Heading2,
  HiddenScrollColumn,
  SubmitButton,
} from '@core/components/basic';
import InputField from '@core/components/form/input-field';
import Form from '@core/components/form';
import useForm from '@core/hooks/use-form';
import Error from '@core/components/async-state/error';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import usePaginatedRequest from '@core/hooks/pagination/use-paginated-request';
import { useListRulesQuery } from '@core/api/admin';

import { useListAssetsQuery } from '@features/assets/service';

export const FIELDS = {
  ASSET: 'asset',
  PRIORITY: 'priority',
  CHANNEL: 'channel',
  GUARD: 'guard',
  START: 'start',
  INTERVAL: 'interval',
  END: 'end',
};

const FlowAssetForm = ({
  header,
  data = {},
  buttonLabel,
  onSubmit,
  onSuccess,
  disabledFields = [],
}) => {
  const { data: assets = [], ...assetList } = usePaginatedRequest({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    request: () => useListAssetsQuery({ pagination: false }),
  });

  const { data: rules = [], ...ruleList } = usePaginatedRequest({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    request: () => useListRulesQuery({ pagination: false }),
  });

  const form = useForm({
    schema: Yup.object().shape({
      [FIELDS.ASSET]: Yup.string().required(),
      [FIELDS.PRIORITY]: Yup.number().min(0).required(),
      [FIELDS.CHANNEL]: Yup.string().required(),
      [FIELDS.GUARD]: Yup.string(),
      [FIELDS.START]: Yup.string().required(),
      [FIELDS.INTERVAL]: Yup.number()
        .min(0)
        .test({
          message: 'Must match a number of days',
          test(value) {
            return (
              value === undefined ||
              isNaN(value) ||
              Number(value) % (1000 * 60 * 60 * 24) === 0
            );
          },
        }),
      [FIELDS.END]: Yup.string(),
    }),
    fields: {
      [FIELDS.ASSET]: {
        type: 'select',
        options: assets.map(({ type, name, externalId, id }) => ({
          label: `${type} - ${name ?? externalId}`,
          value: id,
        })),
        initialValue: data.assetId,
        disabled: disabledFields.includes(FIELDS.ASSET),
      },
      [FIELDS.PRIORITY]: {
        type: 'number',
        initialValue: data.priority,
        disabled: disabledFields.includes(FIELDS.PRIORITY),
      },
      [FIELDS.CHANNEL]: {
        type: 'select',
        options: Object.entries(Channels).map(([key, value]) => ({
          label: key,
          value,
        })),
        initialValue: data.channel,
        disabled: disabledFields.includes(FIELDS.CHANNEL),
      },
      [FIELDS.GUARD]: {
        type: 'select',
        options: rules.map(({ id, name }) => ({ label: name, value: id })),
        initialValue: data.guardId,
        disabled: disabledFields.includes(FIELDS.GUARD),
      },
      [FIELDS.START]: {
        type: 'select',
        options: rules.map(({ id, name }) => ({ label: name, value: id })),
        initialValue: data.startRuleId,
        disabled: disabledFields.includes(FIELDS.START),
      },
      [FIELDS.INTERVAL]: {
        type: 'text',
        initialValue: data.interval,
        disabled: disabledFields.includes(FIELDS.INTERVAL),
      },
      [FIELDS.END]: {
        type: 'select',
        options: rules.map(({ id, name }) => ({ label: name, value: id })),
        initialValue: data.endRuleId,
        disabled: disabledFields.includes(FIELDS.END),
      },
    },
    submit: onSubmit,
    onSuccess,
  });

  const isLoading = assetList.isLoading || ruleList.isLoading;
  const error = assetList.error ?? ruleList.error;

  return (
    <HiddenScrollColumn
      flex={1}
      justifyContent="center"
      p="smallMedium"
      $gap="smallMedium"
    >
      <Heading2>{header}</Heading2>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <Form onSubmit={form.handleSubmit}>
          {Object.keys(form.inputs).map((key) => (
            <InputField
              key={key}
              label={form.labels[key]}
              input={form.inputs[key]}
              error={form.errors[key]}
              p="extraSmall"
            />
          ))}
          {onSubmit && (
            <SubmitButton
              type="submit"
              disabled={form.submitDisabled}
              variant={form.submitDisabled ? 'disabled' : 'enabled'}
            >
              {buttonLabel}
            </SubmitButton>
          )}
        </Form>
        {form.errors.formSubmissionError && (
          <Error message={form.errors.formSubmissionError} />
        )}
      </AsyncStateWrapper>
    </HiddenScrollColumn>
  );
};

export default FlowAssetForm;

FlowAssetForm.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.shape({
    assetId: PropTypes.string,
    priority: PropTypes.number,
    interval: PropTypes.number,
    channel: PropTypes.oneOf(Object.values(Channels)),
    guardId: PropTypes.string,
    startRuleId: PropTypes.string,
    endRuleId: PropTypes.string,
  }),
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  disabledFields: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FIELDS))),
};
