import UserRoles from 'clay-commons/enum/user-roles';

import ThemesPage from './pages';

export const root = {
  path: '/themes',
  component: ThemesPage,
  roles: [UserRoles.ADMIN],
};

const routes = [root];

export default routes;
