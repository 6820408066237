import React from 'react';

import { arrayOf, bool, node, string, shape } from 'prop-types';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';

const NavigationDropdown = ({ routes, defaultRoute }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const selectedLocation = routes.find((route) =>
    pathname.endsWith(route.path)
  );

  return (
    <>
      <Select
        value={selectedLocation?.title}
        onChange={(event) => {
          history.push(
            routes.find((route) => route.title === event.target.value).path
          );
        }}
      >
        {routes.map(
          ({ path, title, skipTopNavigation }) =>
            !skipTopNavigation &&
            title && (
              <MenuItem key={`route-link-${title}-to-${path}`} value={title}>
                {title}
              </MenuItem>
            )
        )}
      </Select>
      <Switch>
        {routes.map(({ exact, path, component }) => (
          <Route
            path={path}
            key={`route-${exact ? `exact-${path}` : path}`}
            component={() => component}
            exact={exact}
          />
        ))}
        <Redirect path="*" to={defaultRoute ?? routes[0].path} />
      </Switch>
    </>
  );
};

NavigationDropdown.propTypes = {
  routes: arrayOf(
    shape({
      title: string,
      path: string.isRequired,
      component: node.isRequired,
      exact: bool,
      skipTopNavigation: bool,
    })
  ).isRequired,
  defaultRoute: string,
};

export default NavigationDropdown;
