import { createPropTypes } from '@styled-system/prop-types';
import styled from 'styled-components';
import { layout, space, flexbox, compose } from 'styled-system';

const Form = styled.form`
  ${compose(flexbox, space, layout)}
`;

Form.propTypes = createPropTypes(flexbox.propNames, space.propNames);

export default Form;
