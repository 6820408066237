import React from 'react';

import { node, string } from 'prop-types';

import { Column, Divider, Row } from '@core/components/basic';

import { CapitalizedText, KeyText, ValueText } from './text';

export const SectionItem = ({ name, value }) => (
  <Row $gap={15}>
    <KeyText>{parseSectionItem(name)}</KeyText>
    <ValueText>{value}</ValueText>
  </Row>
);

SectionItem.propTypes = {
  name: string.isRequired,
  value: node.isRequired,
};

export const SectionItemGroup = ({ name, children }) => (
  <>
    <Divider />
    <Column pt="smallMedium">
      <CapitalizedText $fontSize="body" $fontWeight="heavy">
        {parseSectionItem(name)}
      </CapitalizedText>
      <Column>{children}</Column>
    </Column>
  </>
);

SectionItemGroup.propTypes = {
  name: string.isRequired,
  children: node.isRequired,
};

const parseSectionItem = (key) => {
  return key.replace('_', ' ').replace('-', ' ');
};
