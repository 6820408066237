import React from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Column, Heading2, Heading3, Row } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import {
  AddNavigationIcon,
  CloseNavigationIcon,
  DeleteNavigationIcon,
  EditNavigationIcon,
  ViewNavigationIcon,
} from '@core/components/navigation-icons';
import CollapsibleSection from '@core/components/collapsible-section';

import { useGetFlowDetailsQuery } from '@features/flows/service';
import FlowForm from '@features/flows/components/form/flow';

const ViewFlowDrawer = ({
  onClose,
  flowId,
  onEdit,
  onRemove,
  onAddGuard,
  onRemoveGuard,
  onAddAsset,
  onEditAsset,
  onRemoveAsset,
}) => {
  const {
    data: flow = { guards: [], assets: [] },
    isLoading,
    error,
  } = useGetFlowDetailsQuery({ flowId });

  const history = useHistory();

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row
        justifyContent="end"
        alignItems="center"
        p="small"
        $gap="smallMedium"
      >
        <DeleteNavigationIcon onClick={() => onRemove(flowId)} />
        <EditNavigationIcon title="Edit" onClick={() => onEdit(flowId)} />
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <Column $gap="medium">
          <FlowForm
            header={`"${flow.name}" flow details`}
            data={flow}
            disabled
          />
          <Column p="smallMedium" $gap="small">
            <Row $gap="small" alignItems="center">
              <Heading2>Guards</Heading2>
              <AddNavigationIcon
                title="Add guard"
                onClick={() => onAddGuard(flowId)}
              />
            </Row>
            {flow.guards.map(({ id, name }) => (
              <Row key={id} $gap="small" alignItems="center">
                <ViewNavigationIcon
                  onClick={() => history.push(`/admin-rules/rules/${id}/edit`)}
                />
                <DeleteNavigationIcon
                  title="Remove guard"
                  onClick={() => onRemoveGuard(id)}
                />
                <Heading3>{name ?? id}</Heading3>
              </Row>
            ))}
          </Column>
          <Column p="smallMedium" $gap="small">
            <Row $gap="small" alignItems="center">
              <Heading2>Assets</Heading2>
              <AddNavigationIcon
                title="Add asset"
                onClick={() => onAddAsset(flowId)}
              />
            </Row>
            {flow.assets.map(
              ({
                priority,
                asset,
                channel,
                guard,
                startRule,
                interval,
                endRule,
              }) => {
                const preview = (
                  <Row $gap="small">
                    <EditNavigationIcon
                      title="Edit asset"
                      onClick={() => onEditAsset(asset.id)}
                    />
                    <DeleteNavigationIcon
                      title="Remove asset"
                      onClick={() => onRemoveAsset(asset.id)}
                    />
                  </Row>
                );

                return (
                  <CollapsibleSection
                    key={asset.externalId}
                    title={`${asset.type}: ${asset.name ?? asset.externalId}`}
                    preview={preview}
                    expandedPreview={preview}
                  >
                    <Column $gap="medium">
                      <Column>
                        <Heading3 $fontSize="bodyLarge">Priority</Heading3>
                        <Heading2 $fontSize="body" $fontWeight="normal">
                          {priority}
                        </Heading2>
                      </Column>
                      <Column>
                        <Heading3 $fontSize="bodyLarge">Channel</Heading3>
                        <Heading2 $fontSize="body" $fontWeight="normal">
                          {channel.toUpperCase()}
                        </Heading2>
                      </Column>
                      {guard && (
                        <Column>
                          <Heading3 $fontSize="bodyLarge">Guard</Heading3>
                          <Row $gap="small" alignItems="center">
                            <ViewNavigationIcon
                              title="View guard rule"
                              onClick={() =>
                                history.push(
                                  `/admin-rules/rules/${guard.id}/edit`
                                )
                              }
                            />
                            <Heading2 $fontSize="body" $fontWeight="normal">
                              {guard.name}
                            </Heading2>
                          </Row>
                        </Column>
                      )}
                      {startRule && (
                        <Column>
                          <Heading3 $fontSize="bodyLarge">Start</Heading3>
                          <Row $gap="small" alignItems="center">
                            <ViewNavigationIcon
                              title="View start rule"
                              onClick={() =>
                                history.push(
                                  `/admin-rules/rules/${startRule.id}/edit`
                                )
                              }
                            />
                            <Heading2 $fontSize="body" $fontWeight="normal">
                              {startRule.name}
                            </Heading2>
                          </Row>
                        </Column>
                      )}
                      {interval !== null && (
                        <Column>
                          <Heading3 $fontSize="bodyLarge">Interval</Heading3>
                          <Heading2 $fontSize="body" $fontWeight="normal">
                            {interval / (1000 * 60 * 60 * 24)} DAYS
                          </Heading2>
                        </Column>
                      )}
                      {endRule && (
                        <Column>
                          <Heading3 $fontSize="bodyLarge">End</Heading3>
                          <Row $gap="small" alignItems="center">
                            <ViewNavigationIcon
                              title="View end rule"
                              onClick={() =>
                                history.push(
                                  `/admin-rules/rules/${endRule.id}/edit`
                                )
                              }
                            />
                            <Heading2 $fontSize="body" $fontWeight="normal">
                              {endRule.name}
                            </Heading2>
                          </Row>
                        </Column>
                      )}
                    </Column>
                  </CollapsibleSection>
                );
              }
            )}
          </Column>
        </Column>
      </AsyncStateWrapper>
    </Drawer>
  );
};

export default ViewFlowDrawer;

ViewFlowDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAddGuard: PropTypes.func.isRequired,
  onRemoveGuard: PropTypes.func.isRequired,
  onAddAsset: PropTypes.func.isRequired,
  onEditAsset: PropTypes.func.isRequired,
  onRemoveAsset: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
};
