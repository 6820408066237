import React, { useCallback, useState } from 'react';

import { shape, func, string, arrayOf } from 'prop-types';
import {
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

import { Column, Row } from '@core/components/basic';

const Filter = ({
  selectedFilters,
  onSelectFilter,
  onDeselectFilter,
  categories,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubmenu, selectSubmenu] = useState(null);

  const toggle = useCallback((element) => {
    setOpen((prev) => !prev);
    setAnchorEl(element.currentTarget);
    selectSubmenu(null);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    setAnchorEl(null);
    selectSubmenu(null);
  }, []);

  return (
    <Row alignSelf="flex-start" pl="smallMedium">
      <Column>
        <List component="div">
          <ListItemButton onClick={toggle}>
            <ListItemText primary="Filters" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </List>
        <Popover anchorEl={anchorEl} open={anchorEl !== null} onClose={onClose}>
          <Collapse in={open} timeout="auto">
            {categories.map((category) => (
              <List component="div" disablePadding key={category.name}>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    selectSubmenu(
                      category.name === selectedSubmenu ? null : category.name
                    )
                  }
                >
                  <ListItemText primary={category.name} />
                  {selectedSubmenu === category.name ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse in={selectedSubmenu === category.name} timeout="auto">
                  {category.filters.map((filter) => {
                    const isSelected =
                      selectedFilters?.[category.field] === filter;

                    return (
                      <ListItemButton
                        sx={{ pl: 8, pr: 8 }}
                        onClick={() =>
                          isSelected
                            ? onDeselectFilter(category.field, undefined)
                            : onSelectFilter(category.field, filter)
                        }
                        key={filter}
                      >
                        <ListItemText primary={filter} />
                        {isSelected && (
                          <ListItemIcon sx={{ pl: 4 }}>
                            <CheckIcon />
                          </ListItemIcon>
                        )}
                      </ListItemButton>
                    );
                  })}
                </Collapse>
              </List>
            ))}
          </Collapse>
        </Popover>
      </Column>
      <Row $gap="small" alignSelf="center">
        {Object.keys(selectedFilters).map((filterKey) => (
          <Chip
            onDelete={() => onDeselectFilter(filterKey, undefined)}
            key={filterKey}
            label={
              <span>
                {`${
                  categories.find((category) => category.field === filterKey)
                    .name
                } ${selectedFilters[filterKey]}
                  `}
              </span>
            }
          />
        ))}
      </Row>
    </Row>
  );
};

Filter.propTypes = {
  selectedFilters: shape({
    [string]: string,
  }),
  onSelectFilter: func.isRequired,
  onDeselectFilter: func.isRequired,
  categories: arrayOf(
    shape({
      name: string.isRequired,
      field: string.isRequired,
      filters: arrayOf(string),
    })
  ).isRequired,
};

export default Filter;
