import { stringifyUrl } from 'query-string';

import { apiService } from '@core/store';
import ResourceTags from '@core/enums/resource-tags';
import { paginateResponse } from '@core/api-service';

export const { useListCurrentThemesQuery, usePatchThemeMutation } =
  apiService.injectEndpoints({
    addTagTypes: [ResourceTags.TIMED_THEMES],
    endpoints(builder) {
      return {
        listCurrentThemes: builder.query({
          query(query) {
            return {
              url: stringifyUrl({ url: '/school/v1/admin/timed-theme', query }),
            };
          },
          providesTags({ content: result } = {}) {
            return result
              ? [
                  ...result?.map(({ id }) => ({
                    type: ResourceTags.TIMED_THEMES,
                    id,
                  })),
                  { type: ResourceTags.TIMED_THEMES, id: 'LIST' },
                ]
              : [{ type: ResourceTags.TIMED_THEMES, id: 'LIST' }];
          },
          transformResponse: paginateResponse,
        }),
        patchTheme: builder.mutation({
          query(theme) {
            return {
              url: `/school/v1/admin/timed-theme/${theme.id}`,
              method: 'PATCH',
              body: { name: theme.name },
            };
          },
          invalidatesTags(result, error, theme) {
            return [
              { type: ResourceTags.TIMED_THEMES, id: theme.id },
              { type: ResourceTags.TIMED_THEMES, id: 'LIST' },
            ];
          },
        }),
      };
    },
  });
