import { createGlobalStyle } from 'styled-components';

import InterRegularWoff from '@core/theme/typography/inter/Inter-Regular.woff';
import InterRegularWoff2 from '@core/theme/typography/inter/Inter-Regular.woff2';
import InterMediumWoff from '@core/theme/typography/inter/Inter-Medium.woff';
import InterMediumWoff2 from '@core/theme/typography/inter/Inter-Medium.woff2';
import InterSemiBoldWoff from '@core/theme/typography/inter/Inter-SemiBold.woff';
import InterSemiBoldWoff2 from '@core/theme/typography/inter/Inter-SemiBold.woff2';
import FreightSemiBoldWoff from '@core/theme/typography/freight/freigtexprosem-webfont.woff';
import FreightSemiBoldWoff2 from '@core/theme/typography/freight/freigtexprosem-webfont.woff2';

import '@csstools/normalize.css';
import 'react-modern-drawer/dist/index.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const GlobalStyle = createGlobalStyle`
    html,
    body,
    #root {
      width: 100%;
      height: 100%;
    }

    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    .EZDrawer__container {
      overflow-y: scroll;
    }

    #root {
      display: flex;
    }

    * { 
      box-sizing: border-box; 
    }

    @font-face {
      font-family: 'Inter';
      src: url(${InterRegularWoff2}) format('woff2'),
          url(${InterRegularWoff}) format('woff');
      font-weight: normal;
      font-style: normal;
    }
  
    @font-face {
      font-family: 'Inter';
      src: url(${InterMediumWoff2}) format('woff2'),
          url(${InterMediumWoff}) format('woff');
      font-weight: 500;
      font-style: normal;
    }
  
   @font-face {
      font-family: 'Inter';
      src: url(${InterSemiBoldWoff2}) format('woff2'),
          url(${InterSemiBoldWoff}) format('woff');
      font-weight: 600;
      font-style: normal;
    }
  
    @font-face {
      font-family: 'Freight';
      src: url(${FreightSemiBoldWoff2}) format('woff2'),
          url(${FreightSemiBoldWoff}) format('woff');
      font-weight: normal;
      font-style: normal;
    } 
`;

export default GlobalStyle;
