import { apiService } from '@core/store';
import ResourceTags from '@core/enums/resource-tags';

export const {
  useGetSchoolSettingsQuery,
  usePatchSchoolSettingsMutation,
  usePatchQuestionFollowUpConfigurationMutation,
  usePatchAnswerConfigurationMutation,
  usePatchLessonConfigurationMutation,
  usePatchDaysConfigurationMutation,
  usePatchSectionConfigurationMutation,
  usePatchActivityConfigurationMutation,
  usePatchStandardsConfigurationMutation,
  usePatchLessonFileConfigurationMutation,
  usePatchAddActivityConfigurationMutation,
  usePatchTimedThemesConfigurationMutation,
  usePatchObservationConfigurationMutation,
} = apiService.injectEndpoints({
  endpoints(builder) {
    return {
      getSchoolSettings: builder.query({
        query() {
          return {
            url: '/school/v1/admin/settings',
          };
        },
        providesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchSchoolSettings: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchQuestionFollowUpConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/question-follow-up',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchAnswerConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/answer',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchLessonConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/lesson',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchDaysConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/days',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchSectionConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/section',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchActivityConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/activity',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchStandardsConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/standards',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchAddActivityConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/add-activity',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchLessonFileConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/lesson/file',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchTimedThemesConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/timed-themes',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
      patchObservationConfiguration: builder.mutation({
        query({ body }) {
          return {
            method: 'PATCH',
            url: '/school/v1/admin/settings/configuration/observation',
            body,
          };
        },
        invalidatesTags(_, error, __) {
          return error ? [] : [ResourceTags.SCHOOL_SETTINGS];
        },
      }),
    };
  },
});
