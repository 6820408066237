import React from 'react';

import styled, { keyframes } from 'styled-components/macro';
import { compose, borderRadius, border } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';
import { oneOf } from 'prop-types';

import { Flex } from '@core/components/basic';
import { $color, $layout, $border } from '@core/theme/system';

import space from '../../../theme/space';
import colors from '../../../theme/colors';

const ClayLoading = ({ size = 'huge', color = 'textClay' }) => (
  <Flex flex={1} alignItems="center" justifyContent="center">
    <RingSpinner
      $size={size}
      $color={color}
      borderRadius="circular"
      $borderWidth="large"
      borderStyle="solid"
      borderTopColor={color}
      borderLeftColor="transparent"
      borderBottomColor="transparent"
      borderRightColor="transparent"
    />
  </Flex>
);
export default ClayLoading;

ClayLoading.propTypes = {
  size: oneOf(Object.keys(space)),
  color: oneOf(Object.keys(colors)),
};

const motion = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RingSpinner = styled.div`
  ${compose($layout, $color, borderRadius, border, $border)}
  animation: ${motion} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`;

RingSpinner.propTypes = createPropTypes(
  $layout.propNames,
  $color.propNames,
  borderRadius.propNames,
  border.propNames,
  $border.propNames
);
