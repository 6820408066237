import React from 'react';

import { Page } from '@core/components/basic';

const HomePage = () => (
  <Page alignItems="center" justifyContent="center">
    <h1>Home</h1>
  </Page>
);

export default HomePage;
