import { useState, useCallback, useEffect } from 'react';

import { DEFAULT_PAGINATION_SIZE } from '@core/api-service';

import usePaginatedRequest from './use-paginated-request';

const useRequestWithStatePagination = ({
  request,
  initialPage = 1,
  initialSize = DEFAULT_PAGINATION_SIZE,
  initialSort,
}) => {
  const [page, setPage] = useState(initialPage);
  const [size, setSize] = useState(initialSize);
  const [sort, setSort] = useState(initialSort);
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState({});

  const { pagination, ...response } = usePaginatedRequest({
    page,
    size,
    sort,
    search,
    request,
    filters,
  });

  const changeSize = useCallback(
    (size) => {
      const maxPageWithNewSize = Math.ceil(pagination.totalItems / size);
      setSize(Number.parseInt(size));
      setPage((page) =>
        page > maxPageWithNewSize ? maxPageWithNewSize : Number.parseInt(page)
      );
    },
    [pagination?.totalItems]
  );

  const changeSort = useCallback((field, order) => {
    setSort(`${field}.${order}`);
    setPage(1);
  }, []);

  const changeSearch = useCallback((search) => {
    setPage(1);
    setSearch(search);
  }, []);

  const changeFilters = useCallback((category, filter) => {
    setPage(1);
    setFilters((prev) => ({
      ...prev,
      [category]: filter,
    }));
  }, []);

  useEffect(() => {
    if (response.data?.length === 0 && pagination.currentPage > 1) {
      setPage(pagination.currentPage - 1);
    }
  }, [response.data?.length, pagination.currentPage]);

  return {
    ...response,
    pagination: {
      ...pagination,
      changePage: setPage,
      changeSize,
      changeSort,
      changeSearch,
      changeFilters,
    },
  };
};

export default useRequestWithStatePagination;
