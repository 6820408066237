import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import LinkHeader from 'http-link-header';
import contentRangeParser from 'parse-content-range-header';

import ResourceTags from './enums/resource-tags';

const { REACT_APP_API_HOST: API_HOST } = process.env;

if (!API_HOST) {
  throw Error('REACT_APP_API_HOST environment variable is missing.');
}

export const DEFAULT_PAGINATION_SIZE = 25;

const createService = (config) => {
  return createApi({
    tagTypes: Object.values(ResourceTags),
    baseQuery: fetchBaseQuery({
      baseUrl: `${API_HOST}`,
      prepareHeaders: (headers, { getState }) => {
        const {
          auth: { token },
        } = getState();

        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }

        return headers;
      },
    }),
    ...config,
  });
};

export const paginateResponse = (response, meta, arg) => {
  // If there is a pagination header and it is explicitly false do not paginate
  if (arg?.pagination !== undefined && !arg.pagination) {
    return response;
  }

  const linkHeader = meta.response.headers.get('link');
  const contentRangeHeader = meta.response.headers.get('content-range');

  // no pagination headers present, return response normally
  if (!linkHeader || !contentRangeHeader || response.length === 0) {
    return response;
  }

  const contentRange = contentRangeParser(contentRangeHeader);

  const parsedLink = LinkHeader.parse(linkHeader);
  const size = arg?.size ?? DEFAULT_PAGINATION_SIZE;

  const totalItems =
    (contentRange.length ?? parseInt(contentRangeHeader.split('/')?.[1])) || 0;

  return {
    content: response,
    pagination: {
      currentPage: Math.ceil((contentRange.last + 1) / size),
      totalPages: Math.ceil(totalItems / size),
      totalItems: totalItems,
      size,
      link: parsedLink.refs,
    },
  };
};

// Resources from s3 bucket should have 900000ms worth of life. (15m)
export const HALF_LIFE_POLLING_INTERVAL = 450000;

export default createService;
