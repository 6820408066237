import React from 'react';

import UserRoles from 'clay-commons/enum/user-roles';

import NavigationTabs from '@core/components/navigation-tabs';

import AdminPageFrame from '@features/admin/components/page-frame';

import ParentDeletionListPage from './pages/parent-deletion/list';
import TeacherListPage from './pages/teachers/list';

export const LIST_PARENT_DELETION_ROUTE = {
  title: 'Parent Deletions',
  path: '/admin-users/parent-deletions',
  exact: true,
  component: <ParentDeletionListPage />,
  roles: [UserRoles.ADMIN],
};

export const LIST_TEACHERS_ROUTE = {
  title: 'Teachers',
  path: '/admin-users/teachers',
  exact: true,
  component: <TeacherListPage />,
  roles: [UserRoles.ADMIN],
};

const routes = [LIST_TEACHERS_ROUTE, LIST_PARENT_DELETION_ROUTE];

const RootPage = () => (
  <AdminPageFrame>
    <NavigationTabs routes={routes} defaultRoute={LIST_TEACHERS_ROUTE.path} />
  </AdminPageFrame>
);

export default RootPage;
