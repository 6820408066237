import React, { useCallback } from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';

import { Row } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import { CloseNavigationIcon } from '@core/components/navigation-icons';

import {
  useGetFlowDetailsQuery,
  usePatchFlowMutation,
} from '@features/flows/service';
import FlowForm from '@features/flows/components/form/flow';

const EditFlowDrawer = ({ onClose, flowId }) => {
  const {
    data: flow = {},
    isLoading,
    error,
  } = useGetFlowDetailsQuery({ flowId });

  const [patch] = usePatchFlowMutation();

  const onSubmit = useCallback(
    ({ priority, concurrentAssetLimit, name, description }) =>
      patch({ flowId, priority, concurrentAssetLimit, name, description }),
    [patch, flowId]
  );

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row
        justifyContent="end"
        alignItems="center"
        p="small"
        $gap="smallMedium"
      >
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <FlowForm
          header={`Edit "${flow.name ?? flow.type}" flow`}
          data={flow}
          buttonLabel="Update"
          onSubmit={onSubmit}
          onSuccess={onClose}
        />
      </AsyncStateWrapper>
    </Drawer>
  );
};

export default EditFlowDrawer;

EditFlowDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
};
