import React, { useMemo } from 'react';

import { string, func, bool } from 'prop-types';
import Drawer from 'react-modern-drawer';
import styled from 'styled-components';
import { merge } from 'lodash';
import QuestionTypes from 'clay-commons/enum/question-types';

import { ScrollableColumn, ClayText } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import CollapsibleSection from '@core/components/collapsible-section';
import { formatShortDate } from '@core/format-date';

import {
  useCaseAnswersQuery,
  useCaseQuery,
  useCaseReportsQuery,
} from '@features/admin/service';

import { PublishedDocument, PublishedReportAction } from './report';
import CaseSummary from './case-details-summary';
import { SectionItem, SectionItemGroup } from './section';

const CaseDetailsDrawer = ({ caseId, onClose, open }) => {
  const { data: caseData, caseError, isCaseLoading } = useCaseQuery({ caseId });

  const {
    data: reports = [],
    reportError,
    isReportLoading,
  } = useCaseReportsQuery({ caseId });

  const {
    data: caseAnswers,
    answersError,
    areAnswersLoading,
  } = useCaseAnswersQuery({ caseId });

  const error = caseError || reportError || answersError;

  const isLoading = isCaseLoading || isReportLoading || areAnswersLoading;

  const sections = useMemo(() => generateSections(caseAnswers), [caseAnswers]);

  const RenderedSections = useMemo(
    () =>
      sections && (
        <>
          {Object.entries(sections).map(([header, section]) => (
            <CollapsibleSection
              key={header}
              title={header}
              preview={
                <PreviewText $fontFamily="Inter">
                  {preparePreview(Object.values(section))}
                </PreviewText>
              }
            >
              {renderItemGroup(section)}
            </CollapsibleSection>
          ))}
        </>
      ),
    [sections]
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      enableOverlay
      direction="right"
      size="75vw"
    >
      <AsyncStateWrapper error={error?.data.message} isLoading={isLoading}>
        <ScrollableColumn $gap="medium" p="medium">
          <CaseSummary enrollmentDate={caseData?.createdAt} />
          {RenderedSections}
          {reports.length > 0 &&
            reports.map((report) => (
              <PublishedDocument
                key={report.id}
                name={report.file.name}
                date={report.createdAt}
                action={<PublishedReportAction id={report.id} />}
              />
            ))}
        </ScrollableColumn>
      </AsyncStateWrapper>
    </Drawer>
  );
};

CaseDetailsDrawer.propTypes = {
  caseId: string.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
};

export default CaseDetailsDrawer;

const PreviewText = styled(ClayText)`
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`;

PreviewText.propTypes = {
  ...ClayText.propTypes,
};

function renderItemGroup(section) {
  return Object.entries(section).map(([itemHeader, itemValue]) =>
    isObject(itemValue) ? (
      <SectionItemGroup key={itemHeader} name={itemHeader}>
        {renderItemGroup(itemValue)}
      </SectionItemGroup>
    ) : (
      <SectionItem key={itemHeader} name={itemHeader} value={`${itemValue}`} />
    )
  );
}

function generateSections(answers) {
  function split(questionRef) {
    return questionRef.match(/(?:(?!\.).)+/g);
  }

  function buildItem(parts, { value, type }) {
    const [subItem, ...otherParts] = parts;

    return subItem
      ? { [subItem]: buildItem(otherParts, { value, type }) }
      : transformAnswerValue(value, type);
  }

  return answers
    ?.filter(({ questionRef }) => questionRef)
    .sort(
      (answer, nextAnswer) =>
        answer.questionRef.match(/\./g).length -
        nextAnswer.questionRef.match(/\./g).length
    )
    .reduce((sections, { value, type, questionRef }) => {
      const [sectionHeader, sectionItem, ...otherMatches] = split(questionRef);

      if (!sectionHeader) {
        return sections;
      }

      const sectionItems = sections[sectionHeader] ?? {};
      const item = buildItem(otherMatches, { value, type });

      return {
        ...sections,
        [sectionHeader]: {
          ...sectionItems,
          [sectionItem]: isObject(item)
            ? merge(sectionItems[sectionItem] ?? {}, item)
            : item,
        },
      };
    }, {});
}

const transformAnswerValue = (value, questionType) => {
  switch (questionType) {
    case QuestionTypes.BOOLEAN:
      return value ? 'Yes' : 'No';
    case QuestionTypes.MULTIPLE_CHOICE:
      return value.map(({ label }) => label).join(', ');
    case QuestionTypes.DATE:
      return formatShortDate(value);
    default:
      return value;
  }
};

const isObject = (value) => typeof value === 'object';

function preparePreview([first, second]) {
  function previewOf(value) {
    if (!isObject(value)) {
      return value;
    }

    const [nestedValue] = Object.values(value);
    return previewOf(nestedValue);
  }

  return [first, second]
    .filter((value) => value)
    .map(previewOf)
    .join(', ');
}
