import React from 'react';

import AssetTypes from 'clay-commons/enum/asset-types';

import NavigationTabs from '@core/components/navigation-tabs/navigation-tabs';

import AdminPageFrame from '@features/admin/components/page-frame';
import buildPath from '@features/assets/build-path';
import ListAssetPage from '@features/assets/pages/list';

const { CHILD_RESCREENING, SCREENER, RESOURCE, SURVEY } = AssetTypes;

const RootAssetPage = () => (
  <AdminPageFrame>
    <NavigationTabs
      routes={routes}
      defaultRoute={buildPath({ type: CHILD_RESCREENING })}
    />
  </AdminPageFrame>
);

export default RootAssetPage;

const routes = [
  {
    title: 'Child Rescreenings',
    path: buildPath({
      type: CHILD_RESCREENING,
    }),
    exact: true,
    component: <ListAssetPage type={CHILD_RESCREENING} />,
  },
  {
    title: 'Resources',
    path: buildPath({
      type: RESOURCE,
    }),
    exact: true,
    component: <ListAssetPage type={RESOURCE} />,
  },
  {
    title: 'Screeners',
    path: buildPath({
      type: SCREENER,
    }),
    exact: true,
    component: <ListAssetPage type={SCREENER} />,
  },
  {
    title: 'Surveys',
    path: buildPath({
      type: SURVEY,
    }),
    exact: true,
    component: <ListAssetPage type={SURVEY} />,
  },
];
