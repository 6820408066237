import AssetTypes from 'clay-commons/enum/asset-types';

function build({ type } = {}) {
  if (!Object.values(AssetTypes).includes(type)) {
    return '/assets';
  }

  return `/assets/${type}`;
}

export default build;
