import React from 'react';

import { shape, string, any, func, arrayOf, bool } from 'prop-types';
import styled from 'styled-components';

import spacing from '@core/theme/space';
import fontSizes from '@core/theme/typography/font-sizes';
import colors from '@core/theme/colors';

import {
  ClayCheckbox,
  ClayInput,
  ClayTextarea,
  Column,
  Flex,
  Row,
  Select,
} from '../basic';

const InputField = (props) =>
  INPUT_MAP[props.input.type]
    ? INPUT_MAP[props.input.type](props)
    : INPUT_MAP['text'](props);

const INPUT_MAP = {
  text: (props) => <TextInputField {...props} />,
  checkbox: (props) => <CheckboxInputField {...props} />,
  textarea: (props) => <TextareaInputField {...props} />,
  select: (props) => <SelectInputField {...props} />,
  number: (props) => <TextInputField {...props} />,
};

InputField.propTypes = {
  ...Flex.propTypes,
  label: shape({
    htmlFor: string.isRequired,
    value: string.isRequired,
  }),
  input: shape({
    name: string.isRequired,
    id: string.isRequired,
    value: any.isRequired,
    type: string.isRequired,
    onChange: func.isRequired,
    disabled: bool,
    options: arrayOf(
      shape({
        label: string.isRequired,
        value: any.isRequired,
      })
    ),
  }).isRequired,
  error: string,
};

export const StyledLabel = styled.label`
  text-transform: capitalize;
  font-size: ${fontSizes.caption};
  align-self: flex-start;
  height: 100%;
  padding: ${spacing.small};
`;

export const ErrorWrapper = styled(Flex)`
  min-height: ${spacing.smallMedium};
  pl: ${spacing.small};
`;

export const Error = styled.span`
  text-transform: uppercase;
  font-size: ${fontSizes.caption};
  color: ${colors.charm};
`;

export default InputField;

const TextInputField = ({ label, input, error, ...props }) => (
  <Flex
    flexDirection="column"
    height="fit-content"
    position="relative"
    {...props}
  >
    {label && <StyledLabel htmlFor={label.htmlFor}>{label.value}</StyledLabel>}
    <ClayInput
      $fontFamily="inter"
      $fontSize="caption"
      py="extraSmall"
      px="extraSmall"
      bg="backgroundGrey"
      borderRadius="extraLarge"
      flex={1}
      $height="100%"
      {...input}
    />
    {error && (
      <ErrorWrapper>
        <Error>{error}</Error>
      </ErrorWrapper>
    )}
  </Flex>
);

TextInputField.propTypes = {
  ...InputField.propTypes,
};

const CheckboxInputField = ({ label, input, error, ...props }) => (
  <Row alignItems="center" {...props}>
    {label && <StyledLabel htmlFor={label.htmlFor}>{label.value}</StyledLabel>}
    <ClayCheckbox
      py="extraSmall"
      px="extraSmall"
      bg="backgroundGrey"
      borderRadius="extraLarge"
      {...input}
      checked={input.value}
    />
    {error && (
      <ErrorWrapper>
        <Error>{error}</Error>
      </ErrorWrapper>
    )}
  </Row>
);

CheckboxInputField.propTypes = {
  ...InputField.propTypes,
};

const TextareaInputField = ({ label, input, error, ...props }) => (
  <Flex
    flexDirection="column"
    height="fit-content"
    position="relative"
    {...props}
  >
    {label && <StyledLabel htmlFor={label.htmlFor}>{label.value}</StyledLabel>}
    <ClayTextarea
      $fontFamily="inter"
      $fontSize="caption"
      py="extraSmall"
      px="extraSmall"
      bg="backgroundGrey"
      borderRadius="extraLarge"
      flex={1}
      $height="100%"
      {...input}
    />
    {error && (
      <ErrorWrapper>
        <Error>{error}</Error>
      </ErrorWrapper>
    )}
  </Flex>
);

TextareaInputField.propTypes = {
  ...InputField.propTypes,
};

const SelectInputField = ({ label, input, error, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      height="fit-content"
      position="relative"
      {...props}
    >
      {label && (
        <StyledLabel htmlFor={label.htmlFor}>{label.value}</StyledLabel>
      )}
      <Column $gap="extraSmall">
        <Select
          disabled={input.disabled}
          name={input.id}
          borderRadius="medium"
          bg="backgroundGrey"
          fontSize="body"
          {...props}
          value={input.value}
          onChange={input.onChange}
        >
          {input.options?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
        {error && (
          <ErrorWrapper>
            <Error>{error}</Error>
          </ErrorWrapper>
        )}
      </Column>
    </Flex>
  );
};

SelectInputField.propTypes = {
  ...InputField.propTypes,
};
