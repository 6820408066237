import React from 'react';

import UserRoles from 'clay-commons/enum/user-roles';

import NavigationTabs from '@core/components/navigation-tabs';

import AdminPageFrame from '@features/admin/components/page-frame';

import ListRules from './pages/rule/list';
import CreateRulePage from './pages/rule/create';
import ListCategories from './pages/category/list';
import CreateCategoryPage from './pages/category/create';
import EditCategoryPage from './pages/category/edit';
import RuleDetailsPage from './pages/rule/details';

export const LIST_RULES_ROUTE = {
  title: 'Rules',
  path: '/admin-rules/rules',
  exact: true,
  component: <ListRules />,
  roles: [UserRoles.ADMIN],
};

export const CREATE_RULE_ROUTE = {
  skipTopNavigation: true,
  title: 'Create Rule',
  path: '/admin-rules/rules/create',
  exact: true,
  component: <CreateRulePage />,
  roles: [UserRoles.ADMIN],
};

export const LIST_CATEGORIES_ROUTE = {
  title: 'Categories',
  path: '/admin-rules/categories',
  exact: true,
  component: <ListCategories />,
  roles: [UserRoles.ADMIN],
};

export const EDIT_RULES_ROUTE = {
  skipTopNavigation: true,
  title: 'Edit Category',
  path: `/admin-rules/rules/:id/edit`,
  exact: true,
  component: <RuleDetailsPage />,
  roles: [UserRoles.ADMIN],
};

export const CREATE_CATEGORY_ROUTE = {
  skipTopNavigation: true,
  title: 'Create Category',
  path: '/admin-rules/categories/create',
  exact: true,
  component: <CreateCategoryPage />,
  roles: [UserRoles.ADMIN],
};

export const EDIT_CATEGORY_ROUTE = {
  skipTopNavigation: true,
  title: 'Edit Category',
  path: `/admin-rules/categories/:id/edit`,
  exact: true,
  component: <EditCategoryPage />,
  roles: [UserRoles.ADMIN],
};

const routes = [
  LIST_RULES_ROUTE,
  CREATE_RULE_ROUTE,
  LIST_CATEGORIES_ROUTE,
  CREATE_CATEGORY_ROUTE,
  EDIT_CATEGORY_ROUTE,
  EDIT_RULES_ROUTE,
];

const RootPage = () => (
  <AdminPageFrame>
    <NavigationTabs routes={routes} defaultRoute={LIST_RULES_ROUTE.path} />
  </AdminPageFrame>
);

export default RootPage;
