import React from 'react';

import NavigationTabs from '@core/components/navigation-tabs/navigation-tabs';

import AdminPageFrame from '@features/admin/components/page-frame';

import SchoolSettingsPage from './school';

export default function RootSettingsPage() {
  return (
    <AdminPageFrame>
      <NavigationTabs
        routes={Object.values(routes)}
        defaultRoute={routes.school.path}
      />
    </AdminPageFrame>
  );
}

const routes = {
  school: {
    title: 'School Service',
    path: '/settings/school',
    component: <SchoolSettingsPage />,
  },
};
