import React from 'react';

import { node } from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from '@core/store';
import theme from '@core/theme';

import FronteggContextProvider from '@features/authentication/frontegg-loginbox/frontegg-provider';

const AppProvider = ({ children }) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {/* Frontegg already provides a BrowserRouter */}
      <FronteggContextProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </FronteggContextProvider>
    </PersistGate>
  </Provider>
);

AppProvider.propTypes = {
  children: node.isRequired,
};

export default AppProvider;
