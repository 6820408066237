import React from 'react';

import Drawer from 'react-modern-drawer';
import PropTypes from 'prop-types';

import { Column, Heading2, Row, Heading3 } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';
import {
  CloseNavigationIcon,
  DeleteNavigationIcon,
  EditNavigationIcon,
} from '@core/components/navigation-icons';

import { useGetAssetDetailsOfTypeQuery } from '@features/assets/service';
import AssetForm, { FIELDS } from '@features/assets/components/form';

const ViewAssetDrawer = ({ onClose, type, externalId, onEdit, onRemove }) => {
  const {
    data: asset = { flows: [] },
    isLoading,
    error,
  } = useGetAssetDetailsOfTypeQuery({ type, externalId });

  return (
    <Drawer open onClose={onClose} direction="right" size="50vw">
      <Row
        justifyContent="end"
        alignItems="center"
        p="small"
        $gap="smallMedium"
      >
        <DeleteNavigationIcon
          title="Unregister"
          onClick={() => onRemove(externalId)}
          disabled={!!error || asset.flows.length !== 0}
        />
        <EditNavigationIcon
          title="Rename"
          onClick={() => onEdit(externalId)}
          disabled={!!error}
        />
        <CloseNavigationIcon onClick={onClose} />
      </Row>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <Column $gap="medium">
          <AssetForm
            type={type}
            header={`"${type}" asset details`}
            data={asset}
            disabledFields={Object.values(FIELDS)}
          />
          {asset.flows.length !== 0 && (
            <Column p="smallMedium" $gap="small">
              <Heading2>Flows</Heading2>
              {asset.flows.map(({ id, type, name }) => (
                <Row key={id} $gap="small">
                  <Heading3>{name ?? id}</Heading3>
                </Row>
              ))}
            </Column>
          )}
        </Column>
      </AsyncStateWrapper>
    </Drawer>
  );
};

export default ViewAssetDrawer;

ViewAssetDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: AssetForm.propTypes.type,
  externalId: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
