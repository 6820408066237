import React from 'react';

import { node, func, bool } from 'prop-types';
import { colord } from 'colord';

import colors from '@core/theme/colors';
import { Column, Row } from '@core/components/basic';
import { BackgroundButton } from '@core/components/composite/buttons';

const Modal = ({ onConfirm, onCancel, loading, children }) => {
  return (
    <Row
      position="fixed"
      top="0"
      left="0"
      bg={colord(colors.black).alpha(0.1).toHex()}
      $width="100%"
      $height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Column
        bg="white"
        maxWidth="600px"
        minWidth="300px"
        p="medium"
        boxShadow="card"
        borderRadius="medium"
        $gap="medium"
      >
        <Row justifyContent="center">{children}</Row>
        <Row justifyContent="center" $gap="small">
          {!!onConfirm && (
            <BackgroundButton
              text="Confirm"
              onClick={onConfirm}
              loading={loading}
            />
          )}
          {!!onCancel && (
            <BackgroundButton
              text="Cancel"
              onClick={onCancel}
              disabled={loading}
            />
          )}
        </Row>
      </Column>
    </Row>
  );
};

export default Modal;

Modal.propTypes = {
  children: node,
  onConfirm: func,
  onCancel: func,
  loading: bool,
};
