import { createPropTypes } from '@styled-system/prop-types';
import styled from 'styled-components';
import { compose, typography, space } from 'styled-system';

import { $typography, $color, $layout } from '@core/theme/system';

export const ClayText = styled.span`
  ${compose($typography, $color, typography, space)}
  white-space: pre-line;
`;

ClayText.propTypes = createPropTypes(
  $typography.propNames,
  $color.propNames,
  typography.propNames,
  space.propNames
);

export const Heading1 = styled.h1`
  all: unset;
  ${compose($typography, $layout, space)}
`;

Heading1.propTypes = createPropTypes(
  $typography.propNames,
  $layout.propNames,
  space.propNames
);

Heading1.defaultProps = {
  $fontFamily: 'inter',
  $fontSize: 'heading',
  $fontWeight: '600',
};

export const Heading2 = styled.h2`
  all: unset;
  ${compose($typography, space)}
`;

Heading2.propTypes = createPropTypes($typography.propNames);

Heading2.defaultProps = {
  $fontFamily: 'inter',
  $fontSize: 'heading',
  $fontWeight: '600',
};

export const Heading3 = styled.h3`
  all: unset;
  ${compose($typography)}
`;

Heading3.propTypes = createPropTypes($typography.propNames);

Heading3.defaultProps = {
  $fontFamily: 'inter',
  $fontSize: 'bodyLarge',
  $fontWeight: '600',
};
