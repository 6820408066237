import React, { useCallback, useMemo } from 'react';

import { shape, arrayOf, string, number, func, bool } from 'prop-types';
import { parse } from 'query-string';

import spacing from '@core/theme/space';
import PaginationSizeSelector, {
  sizeOptions,
} from '@core/components/pagination/pagination-size-selector';

import { Row } from '../basic';
import StyledAnchor from '../basic/anchor';
import { LI, UL } from '../basic/list';

const Pagination = ({ pagination, withPageSelector = false }) => {
  const previous = useMemo(
    () => pagination.links.find((link) => link.rel.includes('previous')),
    [pagination.links]
  );

  const next = useMemo(
    () => pagination.links.find((link) => link.rel.includes('next')),
    [pagination.links]
  );

  const handleNavigationClick = useCallback(
    (event, uri) => {
      event.preventDefault();
      const { page } = parse(uri.split('?')?.[1], { parseNumbers: true });
      pagination.changePage(page);
    },
    [pagination]
  );

  if (pagination.links.length === 0) {
    return null;
  }

  return (
    <Row py={spacing.small} width="100%" px={spacing.medium}>
      {withPageSelector && (
        <PaginationSizeSelector
          onChange={pagination.changeSize}
          size={pagination.size}
          options={sizeOptions}
          position="absolute"
        />
      )}
      <UL display="flex" flex="1" justifyContent="center" px={spacing.small}>
        {previous?.uri && (
          <LI>
            <StyledAnchor
              href={previous?.uri}
              onClick={(event) => handleNavigationClick(event, previous?.uri)}
            >
              &lt;
            </StyledAnchor>
          </LI>
        )}
        {pagination?.links.map((link) => (
          <LI key={link.uri}>
            <StyledAnchor
              variant={pagination.currentPage === link.page ? 'selected' : null}
              href={link.uri}
              onClick={(event) => handleNavigationClick(event, link.uri)}
              rel={link.rel}
              px={spacing.extraSmall}
            >
              {link.page}
            </StyledAnchor>
          </LI>
        ))}
        {next?.uri && (
          <LI>
            <StyledAnchor
              href={next?.uri}
              onClick={(event) => handleNavigationClick(event, next?.uri)}
            >
              &gt;
            </StyledAnchor>
          </LI>
        )}
      </UL>
    </Row>
  );
};

Pagination.propTypes = {
  pagination: shape({
    links: arrayOf(
      shape({
        uri: string.isRequired,
        rel: string.isRequired,
        page: number.isRequired,
      })
    ).isRequired,
    changePage: func.isRequired,
    currentPage: number,
    changeSize: func.isRequired,
  }).isRequired,
  withPageSelector: bool,
};

export default Pagination;
