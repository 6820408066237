import React from 'react';

import PropTypes from 'prop-types';

import { ClayButton } from '@core/components/basic';

const NavigationIcon = ({
  title,
  onClick,
  color = 'textClay',
  disabled = false,
  children,
}) => (
  <ClayButton
    title={title}
    color={color}
    disabled={disabled}
    onClick={onClick}
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </ClayButton>
);
export default NavigationIcon;

NavigationIcon.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
