import React from 'react';

import { arrayOf, shape, string } from 'prop-types';
import { CSVLink } from 'react-csv';
import { utc } from 'moment';

import colors from '@core/theme/colors';

import { ClayButton } from '../basic';
import { Download } from '../icons';

const ExportCsvButton = ({ file, data = [], headers = [] }) => (
  <ClayButton>
    <CSVLink
      data={parseData(data)}
      headers={headers}
      filename={`${file}_${utc().format('YYYY_MM_DD_HH_mm_ss_SSS')}.csv`}
    >
      <Download fill={colors.textClay} />
    </CSVLink>
  </ClayButton>
);

const parseData = (data) =>
  data?.map((entry) =>
    Object.keys(entry).reduce((acc, key) => {
      if (typeof data[key] === 'object') {
        return {
          ...acc,
          [key]: parseData(entry[key]),
        };
      }

      return {
        ...acc,
        // CSV spec: RFC-4180,
        // "If double-quotes are used to enclose fields,
        // then a double-quote appearing inside a field must be escaped by preceding it with another double quote."
        [key]: `${entry[key]}`.replace(/"/g, '""'),
      };
    }, {})
  );

ExportCsvButton.propTypes = {
  file: string.isRequired,
  data: arrayOf(shape()).isRequired,
  headers: arrayOf(
    shape({
      label: string.isRequired,
      key: string.isRequired,
    })
  ),
};

export default ExportCsvButton;
