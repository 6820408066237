import React from 'react';

import { string, func, bool, oneOf } from 'prop-types';

import { ClayButton, ClayText, Row } from '@core/components/basic';

import ClayLoading from '../async-state/loading/loading.jsx';
import colors from '../../theme/colors';

export const BackgroundButton = ({
  text,
  submit = false,
  onClick,
  loading = false,
  disabled = false,
  backgroundColor = 'spotBrick',
  fontWeight = 'light',
  fontSize = 'caption',
  color = 'white',
  ...props
}) => {
  return (
    <ClayButton
      type={submit ? 'submit' : 'button'}
      onClick={(event) => (submit ? (() => {})() : onClick(event))}
      disabled={disabled || loading}
      opacity={disabled ? 0.5 : 1}
      {...props}
    >
      <Row
        backgroundColor={backgroundColor}
        borderRadius="huge"
        py="small"
        px="smallMedium"
        justifyContent="center"
      >
        {loading ? (
          <ClayLoading size="small" color="white" />
        ) : (
          <ClayText
            $color={color}
            $fontFamily="inter"
            $fontWeight={fontWeight}
            $fontSize={fontSize}
          >
            {text}
          </ClayText>
        )}
      </Row>
    </ClayButton>
  );
};

BackgroundButton.propTypes = {
  text: string.isRequired,
  loading: bool,
  disabled: bool,
  submit: bool,
  onClick: func,
  backgroundColor: string,
  fontWeight: string,
  fontSize: string,
  color: oneOf(Object.keys(colors)),
};

export const TextButton = ({
  text,
  onClick,
  fontWeight = 'light',
  fontSize = 'caption',
  color = 'spotBrick',
}) => (
  <ClayButton type="button" onClick={onClick}>
    <ClayText
      $color={color}
      $fontFamily="inter"
      $fontWeight={fontWeight}
      $fontSize={fontSize}
    >
      {text}
    </ClayText>
  </ClayButton>
);

TextButton.propTypes = {
  text: string.isRequired,
  onClick: func.isRequired,
  fontWeight: string,
  fontSize: string,
  color: string,
};
