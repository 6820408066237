import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import AsyncStateWrapper from '@core/components/async-state';
import {
  BackdropRow,
  Box,
  ClayButton,
  ClayTable,
  ClayText,
  Column,
  Flex,
  HiddenScrollColumn,
  Row,
  ScrollableColumn,
  SortableHeader,
  StyledLink,
  TableData,
  TableHeaderItem,
  TableRow,
} from '@core/components/basic';
import Pagination from '@core/components/pagination';
import PaginationSizeSelector, {
  sizeOptions,
} from '@core/components/pagination/pagination-size-selector';
import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import {
  useDeleteCategoryMutation,
  useListCategoriesQuery,
} from '@core/api/admin';
import Modal from '@core/components/modal';
import Search from '@core/components/search';

import {
  CREATE_CATEGORY_ROUTE,
  EDIT_CATEGORY_ROUTE,
} from '@features/admin-rules';

const ListCategories = () => {
  const history = useHistory();
  const {
    data: categories,
    pagination,
    ...categoriesListAsyncState
  } = useRequestWithUrlPagination({
    request: useListCategoriesQuery,
  });

  const [deleteCategory, { isError: deletionError }] =
    useDeleteCategoryMutation();
  const [categoryToDelete, setCategoryToDelete] = useState();

  const onDelete = useCallback(async (name, ruleId) => {
    setCategoryToDelete({
      id: ruleId,
      name,
    });
  }, []);

  const closeConfirmation = useCallback(() => {
    setCategoryToDelete(undefined);
  }, []);

  const confirmDeletion = useCallback(async () => {
    await deleteCategory({ categoryId: categoryToDelete?.id });
    closeConfirmation();
  }, [closeConfirmation, deleteCategory, categoryToDelete?.id]);

  return (
    <ScrollableColumn flex="1 1 auto" $width="100%" alignItems="center">
      <Box $width="100%" p="smallMedium">
        <AsyncStateWrapper
          isLoading={categoriesListAsyncState.isLoading}
          error={categoriesListAsyncState.error?.data?.message}
        >
          <HiddenScrollColumn
            width="100%"
            alignItems="center"
            flex={1}
            overflowY="scroll"
          >
            <BackdropRow
              justifyContent="space-around"
              px="medium"
              width="100%"
              py="smallMedium"
            >
              <Flex flex={1}>
                <StyledLink to={CREATE_CATEGORY_ROUTE.path}>
                  <ClayButton
                    bg="textClay"
                    p="small"
                    $color="white"
                    borderRadius="extraLarge"
                  >
                    Create
                  </ClayButton>
                </StyledLink>
              </Flex>
              <Search
                initialValue={pagination.search}
                onSearch={pagination.changeSearch}
              />
              <Flex flex={1} justifyContent="flex-end">
                <PaginationSizeSelector
                  onChange={pagination.changeSize}
                  size={pagination.size}
                  options={sizeOptions}
                />
              </Flex>
            </BackdropRow>

            <ScrollableColumn $width="100%" p="smallMedium">
              <ClayTable borderRadius="large" $height="fit-content">
                <thead>
                  <TableRow variant="header" $width="100%" position="sticky">
                    <TableHeaderItem>Id</TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="internalName"
                        label="Internal Name"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        field="name"
                        label="External Name"
                        currentSort={pagination.sort}
                        onSort={pagination.changeSort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>Default</TableHeaderItem>
                  </TableRow>
                </thead>

                <tbody>
                  {categories?.map(
                    ({ id, internalName, name, isDefault }, index) => (
                      <TableRow
                        key={id}
                        variant={index % 2 === 0 ? 'dark' : 'light'}
                      >
                        <TableData>{id}</TableData>
                        <TableData>{internalName}</TableData>
                        <TableData>{name}</TableData>
                        <TableData>{isDefault ? 'Yes' : 'No'}</TableData>
                        <TableData py="small">
                          <Row $gap="small" justifyContent="flex-end">
                            <ClayButton
                              variant="linkButton"
                              type="button"
                              onClick={() =>
                                history.push(
                                  EDIT_CATEGORY_ROUTE.path.replace(':id', id)
                                )
                              }
                            >
                              <p>Edit</p>
                            </ClayButton>

                            <ClayButton
                              variant="linkButton"
                              type="button"
                              onClick={() => onDelete(name, id)}
                            >
                              <p>Delete</p>
                            </ClayButton>
                          </Row>
                        </TableData>
                      </TableRow>
                    )
                  )}
                </tbody>
              </ClayTable>
            </ScrollableColumn>
            <Pagination pagination={pagination} />
            {deletionError && (
              <ClayText
                $fontFamily="inter"
                $fontSize="body"
                $fontWeight="heavy"
                $color="charm"
              >
                Could not delete category
              </ClayText>
            )}
          </HiddenScrollColumn>
        </AsyncStateWrapper>
      </Box>
      {categoryToDelete && (
        <Modal onConfirm={confirmDeletion} onCancel={closeConfirmation}>
          <Column alignItems="center" $gap="small">
            <ClayText $fontSize="bodyLarge" textAlign="center">
              Are you sure you want to delete the category{' '}
              <strong>{categoryToDelete?.name}</strong>?
            </ClayText>
          </Column>
        </Modal>
      )}
    </ScrollableColumn>
  );
};

export default ListCategories;
