import { useEffect, useMemo } from 'react';

import { parseUrl } from 'query-string';

const usePaginatedRequest = ({
  request,
  page,
  size,
  sort,
  search,
  filters,
}) => {
  const query = { page, size, sort, filter: search, ...filters };
  const { data, refetch, ...response } = request(query);

  useEffect(() => {
    refetch({ page, size, sort, filter: search, filters });
  }, [refetch, page, size, sort, search, filters]);

  const { link, ...pagination } = data?.pagination ?? query;

  const links = useLinks(link);

  return {
    ...response,
    data: data?.content ?? data,
    pagination: {
      ...pagination,
      links,
      currentPage: page,
      size,
      sort,
      filters,
      search: search?.toString(),
    },
  };
};

const useLinks = (link) =>
  useMemo(() => {
    const links =
      link?.reduce((acc, { rel, uri }) => {
        const {
          query: { page },
        } = parseUrl(uri, { parseNumbers: true });

        return {
          ...acc,
          [uri]: {
            uri,
            page,
            rel: acc[uri] ? `${acc[uri].rel} ${rel}` : rel,
          },
        };
      }, {}) ?? {};

    return Object.keys(links)
      .sort((a, b) => links[a].page - links[b].page)
      .map((sortedKey) => links[sortedKey]);
  }, [link]);

export default usePaginatedRequest;
