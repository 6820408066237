import { createSlice } from '@reduxjs/toolkit';

const store = createSlice({
  name: 'auth',
  initialState: { token: null },
  reducers: {
    setCredentials: (state, { payload }) => {
      state.token = payload.accessToken;
    },
    setId: (state, { payload }) => {
      state.id = payload.id;
    },
  },
});

// selectors
const selectToken = (state) => state.auth.token;
const selectId = (state) => state.auth.id;

// actions
const { setCredentials, setId } = store.actions;

export { selectToken, selectId, setCredentials, store, setId };
