import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import TreeItem from '@mui/lab/TreeItem';
import SaveIcon from '@mui/icons-material/Save';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NodeTypes from 'clay-commons/enum/node-definitions/types';
import NodeOperations from 'clay-commons/enum/node-definitions/operations';
import { sha1 } from 'object-hash';

import { ClayButton, Row } from '@core/components/basic';

import Container from '@features/admin-rules/components/node-form/container';
import Inputs from '@features/admin-rules/components/node-form/inputs';

const NodeFormContainer = ({
  level = 0,
  index = 0,
  node,
  onChange,
  ancestor,
}) => {
  const nodeId = `${level}-${index}`;
  const typeFieldId = `${nodeId}-type`;
  const valueFieldId = `${nodeId}-value`;

  const schema = useMemo(
    () =>
      Yup.object({
        [typeFieldId]: Yup.string().required(),
        [valueFieldId]: Yup.string().required(),
        [`${nodeId}-descendants`]: Yup.array().of(Yup.lazy(() => schema)),
      }),
    [typeFieldId, valueFieldId, nodeId]
  );

  const onSubmit = useCallback(
    async ({ [typeFieldId]: type, [valueFieldId]: value }) => {
      if (type === NodeTypes.OPERATOR && value === NodeOperations.NOT) {
        onChange({ type, value, descendants: [null] });
        return;
      }

      if (type === NodeTypes.OPERATOR && value !== NodeOperations.NOT) {
        onChange({ type, value, descendants: [null, null] });
        return;
      }

      onChange({ type, value });
    },
    [typeFieldId, valueFieldId, onChange]
  );

  return (
    <Formik
      enableReinitialize // clear descendants on ancestor change
      validateOnMount
      validationSchema={schema}
      initialValues={{
        [typeFieldId]: node?.type,
        [valueFieldId]: node?.value,
      }}
      onSubmit={onSubmit}
    >
      {(props) => (
        <NodeForm
          {...props}
          level={level}
          index={index}
          node={node}
          ancestor={ancestor}
          typeFieldId={typeFieldId}
          valueFieldId={valueFieldId}
          onChange={onChange}
        />
      )}
    </Formik>
  );
};

export default NodeFormContainer;

NodeFormContainer.propTypes = {
  level: PropTypes.number,
  index: PropTypes.number,
  node: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
    descendants: PropTypes.array,
  }),
  ancestor: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    descendants: PropTypes.array.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

const NodeForm = ({
  typeFieldId,
  valueFieldId,
  level,
  index,
  node,
  ancestor,
  onChange,
}) => {
  const { values, handleChange, handleSubmit, isValid, isSubmitting } =
    useFormikContext();

  const onNestedChange = useCallback(
    (index, state) => {
      onChange({
        ...node,
        descendants: node.descendants.reduce(
          (descendants, descendant, descendantIndex) => [
            ...descendants,
            descendantIndex === index ? state : descendant,
          ],
          []
        ),
      });
    },
    [node, onChange]
  );

  const state = useMemo(
    () => ({ type: values[typeFieldId], value: values[valueFieldId] }),
    [typeFieldId, valueFieldId, values]
  );

  const disabled = useMemo(
    () =>
      !isValid ||
      isSubmitting ||
      sha1({ type: node?.type, value: node?.value }) === sha1(state),
    [isValid, isSubmitting, node, state]
  );

  return (
    <TreeItem
      nodeId={`${typeFieldId}-${valueFieldId}`}
      label={disabled ? node?.value ?? '...' : '...'}
    >
      <Row
        $gap="small"
        justifyContent="space-between"
        alignItems="center"
        p="tiny"
        flexGrow={1}
      >
        <Inputs
          type={{ id: typeFieldId, value: state.type }}
          value={{ id: valueFieldId, value: state.value }}
          onChange={handleChange}
          ancestor={ancestor}
          index={index}
        />
        <ClayButton
          type="button"
          color="textClay"
          disabled={disabled}
          onClick={handleSubmit}
        >
          {disabled ? <DoneAllIcon /> : <SaveIcon titleAccess="Save node" />}
        </ClayButton>
      </Row>
      {node?.descendants?.map((descendant, index) => (
        <Container
          key={`${level}-${index}`}
          level={level + 1}
          index={index}
          ancestor={node}
          node={descendant}
          onChange={(state) => onNestedChange(index, state)}
        />
      ))}
    </TreeItem>
  );
};

NodeForm.propTypes = {
  typeFieldId: PropTypes.string.isRequired,
  valueFieldId: PropTypes.string.isRequired,
  level: Container.propTypes.level.isRequired,
  index: Container.propTypes.index.isRequired,
  node: Container.propTypes.node,
  ancestor: Container.propTypes.ancestor,
  onChange: PropTypes.func.isRequired,
};
