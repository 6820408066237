// TODO: missing design.
// When implemented, refactor file system structure to match new convention.
// When implemented, refactor to make use of styled system.
import React from 'react';

import NavigationTabs from '@core/components/navigation-tabs';

import { subPages } from '../routes';
import AdminPageFrame from '../components/page-frame';

import CasePage from './case-page';

const routes = [
  {
    title: 'Cases',
    path: '/admin/cases',
    exact: true,
    component: <CasePage />,
  },
  {
    skipTopNavigation: true,
    title: 'Cases',
    path: '/admin/cases/child/:childId',
    exact: true,
    component: <CasePage />,
  },
];

// TODO: https://reactrouter.com/en/main/start/concepts#index-routes
// instead of multiple routes
const AdminPage = () => (
  <AdminPageFrame>
    <NavigationTabs routes={routes} defaultRoute={subPages.caseList()} />
  </AdminPageFrame>
);

export default AdminPage;
