import React from 'react';

import { number, func, arrayOf, shape, string } from 'prop-types';

import colors from '@core/theme/colors';
import radii from '@core/theme/radii';
import spacing from '@core/theme/space';
import fontSizes from '@core/theme/typography/font-sizes';

import { Select } from '../basic';

const PaginationSizeSelector = ({
  size,
  onChange,
  options,
  ...remainingProps
}) => {
  return (
    <Select
      value={size}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      borderRadius={radii.large}
      bg={colors.backgroundGrey}
      minWidth={spacing.extraLarge}
      minHeight={spacing.medium}
      textAlign="center"
      alignItems="center"
      textColor={colors.textClay}
      fontSize={fontSizes.body}
      maxHeight="fit-content"
      {...remainingProps}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label} per page
        </option>
      ))}
    </Select>
  );
};

PaginationSizeSelector.propTypes = {
  size: number.isRequired,
  onChange: func.isRequired,
  options: arrayOf(
    shape({
      value: number.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export const sizeOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
];

export default PaginationSizeSelector;
