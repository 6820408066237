import FlowTypes from 'clay-commons/enum/flow-types';

const BASE = '/flows';

function build({ type } = {}) {
  if (!Object.values(FlowTypes).includes(type)) {
    return BASE;
  }

  return `${BASE}/${type}`;
}

export default build;
