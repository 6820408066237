import React from 'react';

import { node } from 'prop-types';
import styled from 'styled-components';
import { compose, flexbox, layout, space } from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

import { ClayText } from '@core/components/basic';

export const KeyText = ({ children }) => (
  <CapitalizedText
    $fontSize="caption"
    $color="tintBlack"
    flexShrink="0"
    width={97}
  >
    {children}
  </CapitalizedText>
);

KeyText.propTypes = {
  children: node,
};

export const ValueText = ({ children }) => (
  <ClayText
    $fontSize="body"
    $color="textBlack"
    $minHeight="smallMedium"
    pl="smallMedium"
  >
    {children}
  </ClayText>
);

ValueText.propTypes = {
  children: node,
};

export const CapitalizedText = styled(ClayText)`
  ${compose(space, flexbox, layout)}
  text-transform: capitalize;
`;

CapitalizedText.propTypes = {
  ...ClayText.propTypes,
  ...createPropTypes(space.propNames, flexbox.propNames, layout.propNames),
};

CapitalizedText.defaultProps = {
  $fontFamily: 'Inter',
  pb: 'smallMedium',
};
