import { createPropTypes } from '@styled-system/prop-types';
import styled from 'styled-components';
import {
  compose,
  flexbox,
  border,
  space,
  variant,
  color,
  shadow,
} from 'styled-system';

import { $color, $layout } from '@core/theme/system';
import radii from '@core/theme/radii';
import spacing from '@core/theme/space';
import colors from '@core/theme/colors';

export const ClayButton = styled.button`
  all: unset;
  display: flex;
  ${compose($color, border, space, $layout, flexbox, color, shadow)}
  box-sizing: border-box;
  cursor: pointer;

  ${variant({
    variants: {
      linkButton: {
        bg: 'textClay',
        color: 'white',
        borderRadius: radii.extraLarge,
        p: spacing.small,
      },
    },
  })}

  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
`;

ClayButton.propTypes = createPropTypes(
  $color.propNames,
  border.propNames,
  space.propNames,
  $layout.propNames,
  shadow.propNames
);

export const SubmitButton = styled(ClayButton)`
  ${variant({
    variants: {
      enabled: {
        bg: 'textClay',
        color: 'white',
      },
      disabled: {
        bg: 'backgroundGrey',
        color: 'lightGrey',
      },
    },
  })};
`;

SubmitButton.propTypes = ClayButton.propTypes;

SubmitButton.defaultProps = {
  variant: 'enabled',
  p: 10,
  $height: 'fit-content',
  alignSelf: 'center',
  borderRadius: 20,
};
