import { stringifyUrl } from 'query-string';

import ResourceTags from '@core/enums/resource-tags';
import { apiService } from '@core/store';

export const SCHEDULE_BASE_PATH = 'scheduling/v1';

const service = apiService.injectEndpoints({
  endpoints: (builder) => ({
    listScheduledChildTasks: builder.query({
      query: ({ childId, ...query }) => ({
        url: stringifyUrl({
          url: `${SCHEDULE_BASE_PATH}/task/child/${childId}`,
          query,
        }),
        method: 'GET',
      }),
      providesTags: (result, error, { childId }) => [
        {
          type: ResourceTags.CHILD_TASKS,
          id: childId,
        },
      ],
    }),
  }),
});

const { useListScheduledChildTasksQuery } = service;

export { useListScheduledChildTasksQuery, service };
