import { stringifyUrl } from 'query-string';

import { paginateResponse } from '@core/api-service';
import { apiService } from '@core/store';
import ResourceTags from '@core/enums/resource-tags';

const { FLOW, RULE } = ResourceTags;

export const DEFAULT_LIST_SORT = 'priority.asc';

const FLOW_LIST_TAG = { type: FLOW, id: 'LIST' };

const apiRequests = apiService.injectEndpoints({
  endpoints(builder) {
    return {
      listFlows: builder.query({
        query(query) {
          return {
            url: stringifyUrl(
              {
                url: '/admin/v1/flow',
                query,
              },
              { skipEmptyString: true }
            ),
          };
        },
        providesTags({ content = [] } = {}) {
          return [
            ...content.map(({ id }) => ({ type: FLOW, id })),
            FLOW_LIST_TAG,
          ];
        },
        transformResponse: paginateResponse,
      }),
      createFlow: builder.mutation({
        query({ type, concurrentAssetLimit, priority, name, description }) {
          return {
            method: 'POST',
            url: '/admin/v1/flow',
            body: {
              type,
              concurrentAssetLimit: Number(concurrentAssetLimit),
              priority: Number(priority),
              name: name.length === 0 ? undefined : name,
              description: description.length === 0 ? undefined : description,
            },
          };
        },
        invalidatesTags: [FLOW_LIST_TAG],
      }),
      getFlowDetails: builder.query({
        query({ flowId }) {
          return {
            url: `/admin/v1/flow/${flowId}`,
          };
        },
        providesTags({ guards = [] } = {}, error, { flowId }) {
          return [
            { type: FLOW, id: flowId },
            ...guards.map(({ id }) => ({ type: RULE, id })),
          ];
        },
      }),
      patchFlow: builder.mutation({
        query({ flowId, name, description, priority, concurrentAssetLimit }) {
          return {
            method: 'PATCH',
            url: `/admin/v1/flow/${flowId}`,
            body: {
              concurrentAssetLimit: Number(concurrentAssetLimit),
              priority: Number(priority),
              name: name.length === 0 ? null : name,
              description: description.length === 0 ? null : description,
            },
          };
        },
        invalidatesTags(result, error, { flowId }) {
          return [{ type: FLOW, id: flowId }];
        },
      }),
      deleteFlow: builder.mutation({
        query({ flowId }) {
          return {
            method: 'DELETE',
            url: `/admin/v1/flow/${flowId}`,
          };
        },
        invalidatesTags(result, error, { flowId }) {
          return [{ type: FLOW, id: flowId }, FLOW_LIST_TAG];
        },
      }),
      addGuardToFlow: builder.mutation({
        query({ flowId, ruleId }) {
          return {
            method: 'POST',
            url: `/admin/v1/flow/${flowId}/rule/${ruleId}`,
          };
        },
        invalidatesTags(result, error, { flowId }) {
          return [{ type: FLOW, id: flowId }];
        },
      }),
      removeGuardFromFlow: builder.mutation({
        query({ flowId, ruleId }) {
          return {
            method: 'DELETE',
            url: `/admin/v1/flow/${flowId}/rule/${ruleId}`,
          };
        },
        invalidatesTags(result, error, { flowId }) {
          return [{ type: FLOW, id: flowId }];
        },
      }),
      addAssetToFlow: builder.mutation({
        query({
          flowId,
          assetId,
          priority,
          channel,
          guardId,
          startRuleId,
          interval,
          endRuleId,
        }) {
          return {
            method: 'POST',
            url: `/admin/v1/flow/${flowId}/asset/${assetId}`,
            body: {
              priority: Number(priority),
              interval: Number.isNaN(interval) ? undefined : interval,
              channel,
              guardId,
              startRuleId,
              endRuleId,
            },
          };
        },
        invalidatesTags(result, error, { flowId }) {
          return [{ type: FLOW, id: flowId }];
        },
      }),
      removeAssetFromFlow: builder.mutation({
        query({ flowId, assetId }) {
          return {
            method: 'DELETE',
            url: `/admin/v1/flow/${flowId}/asset/${assetId}`,
          };
        },
        invalidatesTags(result, error, { flowId }) {
          return [{ type: FLOW, id: flowId }];
        },
      }),
      patchAssetInFlow: builder.mutation({
        query({
          flowId,
          assetId,
          priority,
          channel,
          guardId,
          startRuleId,
          interval,
          endRuleId,
        }) {
          return {
            method: 'PATCH',
            url: `/admin/v1/flow/${flowId}/asset/${assetId}`,
            body: {
              priority: Number(priority),
              interval,
              channel,
              guardId,
              startRuleId,
              endRuleId,
            },
          };
        },
        invalidatesTags(result, error, { flowId }) {
          return [{ type: FLOW, id: flowId }];
        },
      }),
    };
  },
});

export const {
  useListFlowsQuery,
  useCreateFlowMutation,
  useGetFlowDetailsQuery,
  usePatchFlowMutation,
  useDeleteFlowMutation,
  useAddGuardToFlowMutation,
  useRemoveGuardFromFlowMutation,
  useAddAssetToFlowMutation,
  useRemoveAssetFromFlowMutation,
  usePatchAssetInFlowMutation,
} = apiRequests;
