import React from 'react';

import FlowTypes from 'clay-commons/enum/flow-types';

import NavigationTabs from '@core/components/navigation-tabs/navigation-tabs';

import AdminPageFrame from '@features/admin/components/page-frame';
import buildPath from '@features/flows/build-path';
import FlowListPage from '@features/flows/pages/list';

const { CHILD } = FlowTypes;

const RootFlowPage = () => (
  <AdminPageFrame>
    <NavigationTabs routes={routes} defaultRoute={buildPath({ type: CHILD })} />
  </AdminPageFrame>
);

export default RootFlowPage;

// TODO: PARENT flows
// https://github.com/Care-By-Clay/clay/issues/2054
const routes = [
  {
    title: 'Child Flows',
    path: buildPath({
      type: CHILD,
    }),
    exact: true,
    component: <FlowListPage type={CHILD} />,
  },
];
