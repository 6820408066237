import React, { useState } from 'react';

import { compose, flexbox } from 'styled-system';
import { node, bool, string } from 'prop-types';
import styled, { css } from 'styled-components';

import { $flexbox } from '@core/theme/system';
import {
  ClayButton,
  ClayText,
  Column,
  Flex,
  Row,
} from '@core/components/basic';
import theme from '@core/theme';

import { ReactComponent as CollapseIcon } from './circled-chevron.svg';

const CollapsibleSection = ({
  children,
  title,
  preview,
  expandedPreview,
  faded = false,
  startOpen = false,
  backgroundColor = 'white',
}) => {
  const [collapsed, setCollapsed] = useState(!startOpen);

  return (
    <StyledSection borderRadius="medium" bg={backgroundColor}>
      <Row
        alignItems="center"
        padding="medium"
        $color={faded ? theme.colors.tintBlack : theme.colors.textBlack}
        $gap={15}
      >
        <Toggle alignItems="center" onClick={() => setCollapsed(!collapsed)}>
          <StyledIcon $expanded={!collapsed} />
          <Title $fontFamily="Inter" $fontSize="body" $fontWeight="heavy">
            {title}
          </Title>
        </Toggle>
        {collapsed && (
          <Flex flex={1} justifyContent="flex-end" alignItems="center">
            {preview}
          </Flex>
        )}
        {!collapsed && (
          <Flex flex={1} justifyContent="flex-end" alignItems="center">
            {expandedPreview}
          </Flex>
        )}
      </Row>
      {!collapsed && <Content padding="smallMedium">{children}</Content>}
    </StyledSection>
  );
};

CollapsibleSection.propTypes = {
  children: node.isRequired,
  title: node,
  preview: node,
  expandedPreview: node,
  faded: bool,
  startOpen: bool,
  backgroundColor: string,
};

const StyledIcon = styled(CollapseIcon)`
  transition: ${theme.transitions.easeOut};
  flex-shrink: 0;

  ${({ $expanded = false }) =>
    $expanded &&
    css`
      transform: rotate(90deg);
    `}
`;

const StyledSection = styled(Column)`
  box-shadow: ${theme.shadows.card};
  transition: ${theme.transitions.easeIn};
`;

StyledSection.propTypes = {
  ...Column.propTypes,
};

const Toggle = styled(ClayButton)`
  ${compose(flexbox, $flexbox)}
  display: flex;
`;

Toggle.propTypes = {
  ...ClayButton.propTypes,
};

const Title = styled(ClayText)`
  display: flex;
  align-items: center;
  padding-left: ${theme.space.smallMedium};
  text-transform: capitalize;
`;

Title.propTypes = {
  ...ClayText.propTypes,
};

const Content = styled(Column)`
  transition: all 100ms ease-out;
  opacity: 1;
  width: 100%;
`;

Content.propTypes = {
  ...Column.propTypes,
};

export default CollapsibleSection;
