/**
 * Lesson Duration
 *
 * @enum {string}
 */
module.exports = {
    FIFTEEN_MINUTES: '15 minutes',
    THIRTY_MINUTES: '30 minutes',
    FORTY_FIVE_MINUTES: '45 minutes',
    SIXTY_MINUTES: '60 minutes',
    TWO_HOURS: '2 hours',
    HALF_DAY: 'Half-day',
    FULL_DAY: 'Full-day'
};
