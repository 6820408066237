import UserRoles from 'clay-commons/enum/user-roles';

import RootFlowPage from '@features/flows/pages/root';
import buildPath from '@features/flows/build-path';

export const root = {
  path: buildPath(),
  component: RootFlowPage,
  roles: [UserRoles.ADMIN],
};

const routes = [root];

export default routes;
