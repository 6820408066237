import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  Column,
  Row,
  Heading2,
  HiddenScrollColumn,
  SubmitButton,
} from '@core/components/basic';
import InputField from '@core/components/form/input-field';
import Form from '@core/components/form';
import Error from '@core/components/async-state/error';
import useForm from '@core/hooks/use-form';

import NodeForm from '@features/admin-rules/components/node-form/container';

const RuleForm = ({ onSubmit, onSuccess, rule }) => {
  const form = useForm({
    schema: formSchema,
    fields: {
      [fields.name]: {
        type: 'text',
        initialValue: rule?.name,
      },
      [fields.rootNode]: {
        initialValue: rule?.rootNode ?? {},
      },
    },
    submit: onSubmit,
    onSuccess,
  });

  const onRootNodeChange = useCallback(
    (value) => {
      form.inputs[fields.rootNode].onChange({
        target: {
          name: [fields.rootNode],
          value,
        },
      });
    },
    [form.inputs]
  );

  return (
    <HiddenScrollColumn flexGrow={1} overflowY="scroll" $gap="small">
      <Form onSubmit={form.handleSubmit}>
        <Column $gap="small">
          <Row $gap="small" justifyContent="space-between">
            <Heading2>
              {rule ? `"Update ${rule.name}"` : 'Create rule'}
            </Heading2>
            <SubmitButton
              type="submit"
              disabled={form.submitDisabled}
              variant={form.submitDisabled ? 'disabled' : 'enabled'}
            >
              {rule ? 'Update' : 'Create'}
            </SubmitButton>
          </Row>
          <InputField
            key={fields.name}
            label={form.labels[fields.name]}
            input={form.inputs[fields.name]}
            error={form.errors[fields.name]}
            p="extraSmall"
          />
          <TreeView
            aria-label="rule tree"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <NodeForm
              node={form.inputs[fields.rootNode].value}
              onChange={onRootNodeChange}
            />
          </TreeView>
          {form.errors.formSubmissionError && (
            <Error message={form.errors.formSubmissionError} />
          )}
        </Column>
      </Form>
    </HiddenScrollColumn>
  );
};

export default RuleForm;

RuleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  rule: PropTypes.shape({
    rootNode: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      descendants: PropTypes.array.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
  }),
};

const fields = {
  name: 'name',
  rootNode: 'rootNode',
};

const nodeSchema = Yup.object({
  type: Yup.string().required(),
  value: Yup.string().required(),
  descendants: Yup.array().of(Yup.lazy(() => nodeSchema)),
});

const formSchema = Yup.object({
  [fields.name]: Yup.string().required(),
  [fields.rootNode]: nodeSchema.required(),
});
