import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import Modal from '@core/components/modal/modal';
import { ClayText, Column } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';

import {
  useGetFlowDetailsQuery,
  useRemoveAssetFromFlowMutation,
} from '@features/flows/service';

const RemoveAssetModal = ({ onClose, flowId, assetId }) => {
  const { data: flow = { assets: [] }, ...flowDetailsAsync } =
    useGetFlowDetailsQuery({ flowId });

  const assetName = useMemo(
    () => flow.assets.find(({ asset: { id } }) => id === assetId)?.asset.name,
    [flow.assets, assetId]
  );

  const [remove, removeAssetAsync] = useRemoveAssetFromFlowMutation();

  const onConfirm = useCallback(async () => {
    await remove({ flowId, assetId });
    await onClose();
  }, [remove, flowId, assetId, onClose]);

  const isLoading = flowDetailsAsync.isLoading || removeAssetAsync.isLoading;
  const error = flowDetailsAsync.error ?? removeAssetAsync.error;

  return (
    <Modal onConfirm={onConfirm} onCancel={onClose} loading={isLoading}>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <Column alignItems="center" $gap="small">
          <ClayText $fontSize="bodyLarge" textAlign="center">
            This action is <strong>not</strong> retroactively applied.
            <br />
            Are you <strong>sure</strong> you want to remove &quot;
            <strong>{assetName ?? assetId}</strong>
            &quot; asset from &quot;
            <strong>{flow.name ?? flowId}</strong>&quot; flow?
            <br />
          </ClayText>
        </Column>
      </AsyncStateWrapper>
    </Modal>
  );
};

export default RemoveAssetModal;

RemoveAssetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired,
};
