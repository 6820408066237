import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import AssetTypes from 'clay-commons/enum/asset-types';

import Modal from '@core/components/modal/modal';
import { ClayText, Column } from '@core/components/basic';

import { useRemoveAssetOfTypeMutation } from '@features/assets/service';

const RemoveAssetModal = ({ type, externalId, onClose, onSuccess }) => {
  const [removeAsset] = useRemoveAssetOfTypeMutation();

  const onConfirm = useCallback(async () => {
    await removeAsset({ type, externalId });
    await onSuccess();
  }, [removeAsset, type, externalId, onSuccess]);

  return (
    <Modal onConfirm={onConfirm} onCancel={onClose}>
      <Column alignItems="center" $gap="small">
        <ClayText $fontSize="bodyLarge" textAlign="center">
          Are you <strong>sure</strong> you want to unregister{' '}
          <strong>{type}</strong> asset <strong>{externalId}</strong>?
        </ClayText>
      </Column>
    </Modal>
  );
};

export default RemoveAssetModal;

RemoveAssetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(AssetTypes)).isRequired,
  externalId: PropTypes.number.isRequired,
};
