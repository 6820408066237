import colors from './colors';
import space from './space';
import radii from './radii';
import transitions from './transitions';
import fontSizes from './typography/font-sizes';
import fonts from './typography/fonts';
import fontWeights from './typography/font-weights';
import borderWidths from './border-widths';
import shadows from './shadows';

// Reference: https://system-ui.com/theme/
const theme = {
  colors,
  space,
  radii,
  transitions,
  fontSizes,
  fonts,
  fontWeights,
  borderWidths,
  shadows,
};

export default theme;
