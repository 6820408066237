import { stringifyUrl } from 'query-string';

import ResourceTags from '@core/enums/resource-tags';
import { paginateResponse } from '@core/api-service';
import { PROFILE_BASE_PATH } from '@core/api/profile';
import { apiService } from '@core/store';

const service = apiService.injectEndpoints({
  endpoints: (builder) => ({
    listCases: builder.query({
      // TODO: after the pagination of the conversation assignment list this can be replaced as the other paginated queries
      query: (query) => ({
        url: stringifyUrl(
          { url: `/${PROFILE_BASE_PATH}/case`, query },
          { skipEmptyString: true }
        ),
      }),
      providesTags: [ResourceTags.CASE],
      // TODO: after the pagination of the conversation assingment list this can be removed
      transformResponse: paginateResponse,
    }),
    case: builder.query({
      query: ({ caseId }) => ({
        url: `/${PROFILE_BASE_PATH}/case/${caseId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: ResourceTags.CASE, id: arg.caseId },
      ],
    }),
    caseReports: builder.query({
      query: ({ caseId }) => ({
        url: `/${PROFILE_BASE_PATH}/case/${caseId}/report`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: ResourceTags.REPORT, caseId: arg.caseId },
      ],
    }),
    caseAnswers: builder.query({
      query: ({ caseId }) => ({
        url: `/${PROFILE_BASE_PATH}/case/${caseId}/answer`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: ResourceTags.CASE_ANSWERS, caseId: arg.caseId },
      ],
    }),
    report: builder.query({
      query: (reportId) => ({
        url: `/${PROFILE_BASE_PATH}/report/${reportId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        {
          type: ResourceTags.REPORT,
          id: arg.reportId,
        },
      ],
    }),
  }),
});

const {
  useListCasesQuery,
  useAssignExpertMutation,
  useUnassignExpertMutation,
  useListAssignableCareNavigatorsQuery,
  useCaseQuery,
  useCaseReportsQuery,
  useCaseAnswersQuery,
  useLazyReportQuery,
} = service;

export {
  service,
  useListCasesQuery,
  useAssignExpertMutation,
  useUnassignExpertMutation,
  useListAssignableCareNavigatorsQuery,
  useCaseQuery,
  useCaseReportsQuery,
  useCaseAnswersQuery,
  useLazyReportQuery,
};
