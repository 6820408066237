import { createPropTypes } from '@styled-system/prop-types';
import styled from 'styled-components';
import {
  position,
  flexbox,
  typography,
  color,
  border,
  layout,
  space,
  compose,
} from 'styled-system';

import { $flexbox, $typography, $layout } from '@core/theme/system';

export const Select = styled.select`
  ${compose(
    $typography,
    typography,
    border,
    color,
    space,
    flexbox,
    $flexbox,
    layout,
    $layout,
    position
  )}
  box-sizing: border-box;
  outline: none;
`;

Select.propTypes = createPropTypes(
  $typography.propNames,
  border.propNames,
  color.propNames,
  space.propNames,
  flexbox.propNames,
  $flexbox.propNames,
  layout.propNames,
  $layout.propNames,
  position.propNames
);
