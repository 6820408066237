import React from 'react';

import { Route, Switch } from 'react-router-dom';

import AuthorizedContent from '@core/components/authorized-content';

import homeRoutes from '@features/home/routes';
import adminRoutes from '@features/admin/routes';
import adminUsersRoutes from '@features/admin-users/routes';
import rulesRoutes from '@features/admin-rules/routes';
import assetsRoutes from '@features/assets/routes';
import flowsRoutes from '@features/flows/routes';
import assistantRoutes from '@features/assistant/routes';
import settingsRoutes from '@features/settings/routes';
import themesRoutes from '@features/themes/routes';

const routes = [
  ...homeRoutes,
  ...adminRoutes,
  ...adminUsersRoutes,
  ...rulesRoutes,
  ...assetsRoutes,
  ...flowsRoutes,
  ...assistantRoutes,
  ...settingsRoutes,
  ...themesRoutes,
];

const AppRouter = () => (
  <Switch>
    {routes.map(({ path, component: Component, roles = [], exact = false }) => (
      <Route
        key={`route-${exact ? `exact-${path}` : path}`}
        path={path}
        component={() => (
          <AuthorizedContent requiredRoles={roles}>
            <Component />
          </AuthorizedContent>
        )}
        exact={exact}
      />
    ))}
  </Switch>
);

export default AppRouter;
