module.exports = {
    OR: 'or',
    AND: 'and',
    NOT: 'not',
    EQUALS: 'equals',
    GREATER_THAN: 'greaterThan',
    LESSER_THAN: 'lesserThan',
    GREATER_OR_EQUALS: 'greaterOrEquals',
    LESSER_OR_EQUALS: 'lesserOrEquals',
    TIME: 'time',
    ADD_TIME: 'addTime',
    SUBTRACT_TIME: 'subtractTime'
};
