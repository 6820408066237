/**
 * Question types.
 *
 * @enum {string}
 */

module.exports = {
    TEXT: 'question-types.text-options',
    NUMBER: 'question-types.number-options',
    MULTIPLE_CHOICE: 'question-types.multiple-choice-options',
    DATE: 'question-types.date-options',
    BOOLEAN: 'question-types.boolean-options'
};
