import React from 'react';

import { node, func, oneOf } from 'prop-types';

import { ClayButton, ClayText, Flex } from '../basic';
import { Cross } from '../icons';

const modeStyles = {
  container: {
    clay: {
      backgroundColor: 'backgroundPeach',
    },
    grey: {
      backgroundColor: 'whiteSmoke',
    },
  },
  text: {
    clay: {
      $color: 'spotBrick',
    },
    grey: {
      $color: 'black',
    },
  },
};

const InteractableBadge = ({
  children,
  prefix,
  onCrossClick,
  mode = 'clay',
}) => {
  return (
    <Flex
      px="small"
      py="extraSmall"
      borderRadius="medium"
      alignSelf="center"
      alignItems="center"
      {...modeStyles.container[mode]}
    >
      {prefix && (
        <Flex pr="tiny" alignItems="center">
          {prefix}
        </Flex>
      )}
      <ClayText
        $fontSize="body"
        $fontWeight="medium"
        $fontFamily="inter"
        {...modeStyles.text[mode]}
      >
        {children}
      </ClayText>
      {onCrossClick && (
        <ClayButton onClick={onCrossClick} pl="small">
          <Cross />
        </ClayButton>
      )}
    </Flex>
  );
};

InteractableBadge.propTypes = {
  children: node.isRequired,
  prefix: node,
  onCrossClick: func,
  mode: oneOf(Object.keys(modeStyles.container)),
};

export default InteractableBadge;
