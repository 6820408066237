import React from 'react';

import { FronteggProvider } from '@frontegg/react';
import { colord } from 'colord';
import { node } from 'prop-types';

import colors from '@core/theme/colors';

import Header from './header';

const { REACT_APP_FRONTEGG_DOMAIN: FRONTEGG_DOMAIN } = process.env;

if (!FRONTEGG_DOMAIN) {
  throw Error('REACT_APP_FRONTEGG_DOMAIN environment variable is missing.');
}

const FronteggContextProvider = ({ children }) => (
  <FronteggProvider
    contextOptions={{ baseUrl: FRONTEGG_DOMAIN }}
    withCompanyName={false}
    themeOptions={{
      loginBox: {
        themeName: 'classic',
        rootStyle: { background: colors.textClay },
        login: {
          logo: { image: Header },
          title: null,
          palette: {
            primary: {
              main: colors.textClay,
              hover: colord(colors.textClay).lighten(0.05).toHex(),
              active: colord(colors.textClay).lighten(0.1).toHex(),
              dark: colord(colors.textClay).darken(0.2).toHex(),
              light: colord(colors.textClay).lighten(0.2).toHex(),
            },
          },
        },
      },
    }}
  >
    {children}
  </FronteggProvider>
);

FronteggContextProvider.propTypes = {
  children: node.isRequired,
};

export default FronteggContextProvider;
