import React from 'react';

import styled from 'styled-components';
import AgeRange from 'clay-commons/enum/age-range';
import LessonDuration from 'clay-commons/enum/lesson-duration';

import AsyncStateWrapper from '@core/components/async-state';
import {
  BackdropRow,
  Box,
  ClayTable,
  Row,
  HiddenScrollColumn,
  ScrollableColumn,
  TableData,
  TableHeaderItem,
  TableRow,
  SortableHeader,
} from '@core/components/basic';
import { Smile, Frown } from '@core/components/icons';
import Pagination from '@core/components/pagination';
import PaginationSizeSelector, {
  sizeOptions,
} from '@core/components/pagination/pagination-size-selector';
import useRequestWithUrlPagination from '@core/hooks/pagination/use-request-with-url-pagination';
import { useListAssistantLessonsQuery } from '@core/api/school';
import ExportCsvButton from '@core/components/csv/export-button';
import Search from '@core/components/search';

import Filter from '@features/assistant/components/filter';

const csvHeaders = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Author',
    key: 'author',
  },
  {
    label: 'Date',
    key: 'createdAt',
  },
  {
    label: 'Theme',
    key: 'theme',
  },
  {
    label: 'Duration',
    key: 'duration',
  },
  {
    label: 'Useful?',
    key: 'useful',
  },
  {
    label: 'Plan',
    key: 'plan',
  },
  {
    label: 'Ages',
    key: 'ageRange',
  },
];

const ListAssistantLessons = () => {
  const {
    data: lessons = [],
    pagination,
    ...lessonsListAsyncState
  } = useRequestWithUrlPagination({
    request: useListAssistantLessonsQuery,
    initialSort: 'createdAt.desc',
  });

  return (
    <ScrollableColumn flex="1 1 auto" $width="100%" alignItems="center">
      <Box $width="100%" p="smallMedium">
        <AsyncStateWrapper
          isLoading={lessonsListAsyncState.isLoading}
          error={lessonsListAsyncState.error?.data?.message}
        >
          <HiddenScrollColumn
            width="100%"
            alignItems="center"
            flex={1}
            overflowY="scroll"
          >
            <BackdropRow
              justifyContent="space-around"
              px="medium"
              width="100%"
              py="smallMedium"
            >
              <Search
                initialValue={pagination.search}
                onSearch={pagination.changeSearch}
              />
              <Row flex={1} justifyContent="flex-end" $gap="small">
                {lessons && (
                  <ExportCsvButton
                    file="assistant_lessons"
                    data={lessons}
                    headers={csvHeaders}
                  />
                )}
                <PaginationSizeSelector
                  onChange={pagination.changeSize}
                  size={pagination.size}
                  options={sizeOptions}
                />
              </Row>
            </BackdropRow>
            <Filter
              selectedFilters={pagination.filters}
              onSelectFilter={pagination.changeFilters}
              onDeselectFilter={pagination.changeFilters}
              categories={categories}
            />

            <ScrollableColumn $width="100%" p="smallMedium">
              <ClayTable borderRadius="large" $height="fit-content">
                <thead>
                  <TableRow variant="header" $width="100%" position="sticky">
                    <TableHeaderItem>
                      <SortableHeader
                        label="Author"
                        field="author"
                        onSort={pagination.changeSort}
                        currentSort={pagination.sort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        label="Theme"
                        field="theme"
                        onSort={pagination.changeSort}
                        currentSort={pagination.sort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        label="Plan"
                        field="plan"
                        onSort={pagination.changeSort}
                        currentSort={pagination.sort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        label="Ages"
                        field="ageRange"
                        onSort={pagination.changeSort}
                        currentSort={pagination.sort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        label="Duration"
                        field="duration"
                        onSort={pagination.changeSort}
                        currentSort={pagination.sort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        label="Feedback"
                        field="feedback"
                        onSort={pagination.changeSort}
                        currentSort={pagination.sort}
                      />
                    </TableHeaderItem>
                    <TableHeaderItem>Useful</TableHeaderItem>
                    <TableHeaderItem>
                      <SortableHeader
                        label="Asked On"
                        field="createdAt"
                        onSort={pagination.changeSort}
                        currentSort={pagination.sort}
                      />
                    </TableHeaderItem>
                  </TableRow>
                </thead>

                <tbody>
                  {lessons?.map(
                    (
                      {
                        id,
                        author,
                        theme,
                        plan,
                        useful,
                        ageRange,
                        duration,
                        createdAt,
                        feedback,
                      },
                      index
                    ) => (
                      <TableRow
                        key={id}
                        variant={index % 2 === 0 ? 'dark' : 'light'}
                      >
                        <TableData verticalAlign="baseline">{author}</TableData>
                        <TableData verticalAlign="baseline">{theme}</TableData>
                        <PlanTableData
                          verticalAlign="baseline"
                          whiteSpace="pre-line"
                        >
                          {plan}
                        </PlanTableData>
                        <TableData verticalAlign="baseline">
                          {ageRange}
                        </TableData>
                        <TableData verticalAlign="baseline">
                          {duration}
                        </TableData>
                        <TableData verticalAlign="baseline">
                          {feedback}
                        </TableData>
                        <TableData verticalAlign="baseline">
                          {useful === true && <Smile />}
                          {useful === false && <Frown />}
                        </TableData>
                        <TableData verticalAlign="baseline">
                          {createdAt}
                        </TableData>
                      </TableRow>
                    )
                  )}
                </tbody>
              </ClayTable>
            </ScrollableColumn>
            <Pagination pagination={pagination} />
          </HiddenScrollColumn>
        </AsyncStateWrapper>
      </Box>
    </ScrollableColumn>
  );
};

const PlanTableData = styled(TableData)`
  white-space: pre-line;
`;

const categories = [
  {
    name: 'Duration',
    field: 'duration',
    filters: Object.values(LessonDuration),
  },
  {
    name: 'Ages',
    field: 'ageRange',
    filters: Object.values(AgeRange),
  },
];

export default ListAssistantLessons;
