import styled from 'styled-components';
import {
  border,
  color,
  compose,
  flexbox,
  layout,
  space,
  position,
} from 'styled-system';
import { createPropTypes } from '@styled-system/prop-types';

import { $color, $flexbox, $layout } from '@core/theme/system';
import { hideScrollbar } from '@core/theme/utils';

export const Box = styled.div`
  ${compose(space, color, border, $layout, layout, position)}
`;

Box.propTypes = createPropTypes(
  space.propNames,
  color.propNames,
  border.propNames,
  $layout.propNames,
  layout.propNames,
  position.propNames
);

export const Flex = styled.div`
  ${compose(flexbox, space, color, $color, $layout, layout, position, border)}
  display: flex;
`;

Flex.propTypes = createPropTypes(
  flexbox.propNames,
  space.propNames,
  color.propNames,
  $color.propNames,
  $layout.propNames,
  layout.propNames,
  position.propNames,
  border.propNames
);

export const Row = styled(Flex)`
  ${$flexbox}
  flex-direction: row;
`;

Row.propTypes = {
  ...Flex.propTypes,
  ...createPropTypes($flexbox.propNames),
};

export const Column = styled(Flex)`
  ${$flexbox}
  flex-direction: column;
`;

Column.propTypes = {
  ...Flex.propTypes,
  ...createPropTypes($flexbox.propNames),
};

export const ScrollableColumn = styled(Column)`
  overflow-y: auto;
`;

ScrollableColumn.propTypes = {
  ...Column.propTypes,
};

export const Page = styled.main`
  ${compose(space, flexbox, $flexbox, layout)}
  display: flex;
  flex: 1;
`;

Page.propTypes = createPropTypes(
  space.propNames,
  flexbox.propNames,
  $flexbox.propNames,
  layout.propNames
);

export const HiddenScrollColumn = styled(ScrollableColumn)`
  ${hideScrollbar}
`;

HiddenScrollColumn.propTypes = Column.propTypes;

export const LayoutRow = styled(Row)`
  ${layout};
`;

LayoutRow.propTypes = {
  ...Row.propTypes,
  ...createPropTypes(layout.propNames),
};

export const BackdropRow = styled(Row)`
  backdrop-filter: blur(5px);
`;

BackdropRow.propTypes = {
  ...Row.propTypes,
};
