import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Modal from '@core/components/modal/modal';
import { ClayText, Column } from '@core/components/basic';
import AsyncStateWrapper from '@core/components/async-state/async-state-wrapper';

import {
  useDeleteFlowMutation,
  useGetFlowDetailsQuery,
} from '@features/flows/service';

const RemoveFlowModal = ({ onClose, onSuccess, flowId }) => {
  const { data: { name, type } = {}, ...flowDetailsAsync } =
    useGetFlowDetailsQuery({
      flowId,
    });

  const [remove, removeFlowAsync] = useDeleteFlowMutation();

  const onConfirm = useCallback(async () => {
    await remove({ flowId });
    await onSuccess();
  }, [flowId, remove, onSuccess]);

  const isLoading = flowDetailsAsync.isLoading || removeFlowAsync.isLoading;
  const error = flowDetailsAsync.error ?? removeFlowAsync.error;

  return (
    <Modal onConfirm={onConfirm} onCancel={onClose} loading={isLoading}>
      <AsyncStateWrapper isLoading={isLoading} error={error?.data?.message}>
        <Column alignItems="center" $gap="small">
          <ClayText $fontSize="bodyLarge" textAlign="center">
            This action is <strong>not</strong> retroactively applied.
            <br />
            Are you <strong>sure</strong> you want to delete &quot;
            <strong>{type}</strong>&quot; flow &quot;
            <strong>{name ?? flowId}</strong>&quot;?
            <br />
          </ClayText>
        </Column>
      </AsyncStateWrapper>
    </Modal>
  );
};

export default RemoveFlowModal;

RemoveFlowModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
};
